import { MouseEvent, ReactNode } from "react";
import { Row } from "../components/EnhancedTable";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TableCell, TableRow } from "@mui/material";
import { dateFormat } from "../common/utils/text";
import React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { gql, useMutation } from "@apollo/client";
import ConfirmDialog from "../common/dialogs/ConfirmDialog";
import Rename from "../common/dialogs/Rename";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Field from "./field";
import { useTranslation } from "react-i18next";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import LinkFlightplanDroneDialog from "../components/dialogs/LinkFlightplanDroneDialog";

export default class FlightPlan implements Row {
  id?: number;
  uuid: string;
  name: string;
  private created_at: string;
  private updated_at: string;
  private field?: Field;
  private _dronesUUIDs: string[] = [];

  constructor(uuid: string, name: string, created_at: string, updated_at: string, field?: Field) {
    this.uuid = uuid;
    this.name = name;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.field = field;
  }

  get field_id(): number | undefined {
    return this.field?.id;
  }

  set dronesUUIDs(dronesUUIDs: string[]) {
    this._dronesUUIDs = dronesUUIDs;
  }
  get dronesUUIDs(): string[] {
    return this._dronesUUIDs;
  }

  navigate?: NavigateFunction;

  render = (
    labelID: string,
    isItemSelected: boolean,
    handleClick: (event: MouseEvent<unknown>, id: string) => void,
  ): ReactNode => {
    return (
      <TableRow
        hover
        onClick={(event) => {
          handleClick(event, this.uuid);
          this.navigate && this.navigate(this.uuid);
        }}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={this.uuid}
        selected={isItemSelected}
        sx={{ cursor: 'pointer' }}
      >
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelID,
            }}
          />
        </TableCell> */}
        {/* <TableCell
          component="th"
          id={labelID}
          scope="row"
        >
          {this.uuid}
        </TableCell> */}
        <TableCell>{this.name}</TableCell>
        <TableCell>{dateFormat(this.created_at, true)}</TableCell>
        <TableCell>{dateFormat(this.updated_at, true)}</TableCell>
        <TableCell onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}>
          <FlightPlanRowMenu flightPlan={this} />
        </TableCell>
      </TableRow>
    );
  }
}

interface FlightPlanRowMenuProps {
  flightPlan: FlightPlan;
}

const FlightPlanRowMenu = (props: FlightPlanRowMenuProps) => {
  const [ renameDialogOpen, setRenameDialogOpen ] = React.useState(false);
  const [ confirmDialogOpen, setConfirmDialogOpen ] = React.useState(false);
  const [ linkDroneDialogOpen, setLinkDroneDialogOpen ] = React.useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const [ renameFlightPlan ] = useMutation(gql(`
    mutation RenameFlightPlan($uuid: uuid!, $name: String!) {
      update_flight_plans(where: {uuid: {_eq: $uuid}}, _set: {name: $name}) {
        returning {
          uuid
        }
      }
    }
  `), {
    refetchQueries: ['FlightPlans', 'FlightPlan', 'FlightPlanNames'],
  });

  const [ deleteFlightPlan ] = useMutation(gql(`
    mutation DeleteFlightPlan($uuid: uuid!) {
      update_flight_plans(where: {uuid: {_eq: $uuid}}, _set: {deleted_at: "now()"}) {
        returning {
          uuid
        }
      }
    }
  `), {
    refetchQueries: ['FlightPlans', 'FlightPlan', 'FlightPlanNames'],
  });

  const [ linkFlightPlanDroneMutation ] = useMutation(gql(`
    mutation UpdateDrone($drone_id: uuid!, $flightplan_id: bigint) {
      update_drones(
        where: {uuid: {_eq: $drone_id}},
        _set: {flight_plan_id: $flightplan_id}
      ) {
        affected_rows
      }
    }
  `), {
    refetchQueries: ['Fleet', 'Flights', 'FlightPlans']
  });

  return (
    <React.Fragment>
      <IconButton onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.flightPlan.dronesUUIDs.length > 0) {
          navigate(`../../pilot/fleet`);
          return;
        }
        setLinkDroneDialogOpen(true);
      }}>
        <PlayCircleIcon />
      </IconButton>
      <IconButton
        aria-label="more"
        id="button"
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem onClick={(e) => {
          setRenameDialogOpen(true);
          handleClose(e);
        }}>
          <ListItemIcon>
            <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Rename')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => {
          setConfirmDialogOpen(true);
          handleClose(e);
        }}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Delete')}</ListItemText>
        </MenuItem>
      </Menu>

      <Rename
        open={renameDialogOpen}
        title={t("Rename FlightPlan")}
        text={
          <React.Fragment>
            {t('Rename the flight plan')} <strong>{props.flightPlan.name}</strong>.
          </React.Fragment>
        }
        onClose={async (name) => {
          if (name) {
            await renameFlightPlan({ variables: { uuid: props.flightPlan.uuid, name } });
          }
          setRenameDialogOpen(false);
        }}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        confirmColor="error"
        title={t("Delete FlightPlan")}
        body={
          <React.Fragment>
            {t('Are you sure you want to delete the flight plan')} <strong>{props.flightPlan.name}</strong>?
          </React.Fragment>
        }
        onClose={async (confirm) => {
          if (confirm) {
            await deleteFlightPlan({ variables: { uuid: props.flightPlan.uuid } });
          }
          setConfirmDialogOpen(false);
        }}
      />
      <LinkFlightplanDroneDialog
        open={linkDroneDialogOpen}
        onClose={async (drone) => {
          if (drone) {
            await linkFlightPlanDroneMutation({
              variables: {
                drone_id: drone.uuid,
                flightplan_id: props.flightPlan.id
              }
            });
          }
          setLinkDroneDialogOpen(false);
          navigate(`../../pilot/fleet`);
        }}
        fp={props.flightPlan}
      />
    </React.Fragment>
  );
};
