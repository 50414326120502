import { Chip, TableCell, TableRow } from "@mui/material";

export default class Member {
  email: string;
  isOwner: boolean = false;

  constructor(email: string, isOwner?: boolean) {
    this.email = email;
    this.isOwner = isOwner ?? false;
  }

  get uuid(): string {
    return this.email;
  }

  render = (
    labelID: string,
    isItemSelected: boolean,
    handleClick: (event: React.MouseEvent<unknown>, id: string) => void,
  ): React.ReactNode => {
    return (
      <TableRow
        hover
        onClick={(event) => handleClick(event, this.uuid)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={this.uuid}
        selected={isItemSelected}
        sx={{ cursor: 'pointer' }}
      >
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelID,
            }}
          />
        </TableCell> */}
        {/* <TableCell
          component="th"
          id={labelID}
          scope="row"
        >
          {this.uuid}
        </TableCell> */}
        <TableCell>{this.email}</TableCell>
        <TableCell>
          { 
          this.isOwner
          ?
          <Chip label="Owner" color="primary" />
          :
          <Chip label="Member" />
          }
        </TableCell>
      </TableRow>
    );
  }
};
