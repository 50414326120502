export class Line {
    // ax + by + c = 0
    private a: number;
    private b: number;
    private c: number;

    constructor(pos1: google.maps.LatLngLiteral, pos2: google.maps.LatLngLiteral) {
        this.a = pos1.lat - pos2.lat;
        this.b = pos2.lng - pos1.lng;
        this.c = pos1.lng * pos2.lat - pos2.lng * pos1.lat;
    }

    getA(): number {
        return this.a;
    }
    getB(): number {
        return this.b;
    }
    getC(): number {
        return this.c;
    }

    generatePoints(start: google.maps.LatLngLiteral, end: google.maps.LatLngLiteral): google.maps.LatLngLiteral[] {
        const points: google.maps.LatLngLiteral[] = [];
        const line = new Line(start, end);
        const a = line.getA();
        const b = line.getB();
        const c = line.getC();
        const step = 0.0001;
        let x = start.lng;
        let y = start.lat;
        while (x < end.lng) {
            x += step;
            y = (-c - a * x) / b;
            points.push({
                lat: y,
                lng: x,
            });
        }
        return points;
    }

    intersect(line: Line | Segment): google.maps.LatLngLiteral | null {
        // ax + by + c = 0
        // dx + ey + f = 0

        // y = -(ax + c) / b
        // dx - e(ax + c)/b + f = 0

        // dx - eax/b = -f + ec/b
        // x(bd - ea) = -fb + ec
        // x = (-fb + ec)/(bd - ea)

        const a = this.getA();
        const b = this.getB();
        const c = this.getC();
        const d = line.getA();
        const e = line.getB();
        const f = line.getC();

        let x: number;
        let y: number;
        if (b === 0 && e !== 0) {
            x = -c / a;
            y = (-d*x - f) / e;
        } else if (b !== 0 && e === 0) {
            x = -f / d;
            y = (-a*x - c) / b;
        } else {
            x = (-f*b + e*c)/(b*d - e*a);
            y = -(a*x + c)/b;
        }

        if (isNaN(x) || isNaN(y)) {
            return null;
        }

        const point = {
            lat: y,
            lng: x,
        };

        if (line instanceof Segment && !line.isInside(point)) {
            return null;
        }

        return point;
    }
}

export class SegmentGroup {
    private segments: Segment[];
    private generatedSegments: MarkedSegment[] = [];

    constructor(roundingBox: Rectangle, distanceMeters: number) {
        this.segments = [];

        const distance = metersToCoordinates(distanceMeters);
        const start = roundingBox.southwest;
        const end = roundingBox.northeast;

        let pointStart = {
            lat: start.lat,
            lng: start.lng,
        };
        let pointEnd = {
            lat: end.lat,
            lng: start.lng,
        };
        while(pointStart.lng < end.lng) {
            this.segments.push(new Segment(pointStart, pointEnd));
            pointStart.lng += distance;
            pointEnd.lng += distance;
        }
    }

    getSegments(): Segment[] {
        return this.segments;
    }

    getPath(polygon: Polygon): google.maps.LatLngLiteral[] {
        let positions: google.maps.LatLngLiteral[] = [];
        this.generatedSegments = this.generate(polygon).map(s => new MarkedSegment(s));
        if (this.generatedSegments.length === 0) {
            return [];
        }

        let currentPosition = this.generatedSegments[0].endpoints[0];
        positions.push(currentPosition);
        this.generatedSegments[0].mark();
        currentPosition = this.generatedSegments[0].getOtherEndpoint(currentPosition);
        positions.push(currentPosition);

        while (!this.allTaken()) {
            const closerUntaken = this.getCloserUntaken(currentPosition);
            let s: Segment;
            let i = 0;
            do {
                s = new Segment(closerUntaken[i++].position, currentPosition);
            } while(!polygon.isInside(s.med) && i < closerUntaken.length);
            i--;
            if (!polygon.isInside(s.med)) {
                // generate an alternative path
                const intermediate = this.generateAlternativePath(polygon, currentPosition, closerUntaken[i].position);
                if (intermediate) {
                    positions.push(intermediate);
                }
            }
            currentPosition = closerUntaken[i].position;
            positions.push(currentPosition);
            this.generatedSegments[closerUntaken[i].index].mark();
            currentPosition = this.generatedSegments[closerUntaken[i].index].getOtherEndpoint(currentPosition);
            positions.push(currentPosition);
        }
        return positions;
    }

    private generateAlternativePath(polygon: Polygon, cur: google.maps.LatLngLiteral, dst: google.maps.LatLngLiteral): google.maps.LatLngLiteral | null {
        let i = 0;
        do {
            const index = Math.floor(Math.random() * this.generatedSegments.length);
            const segment = this.generatedSegments[index];
            const endpoints = segment.endpoints;
            let s = new Segment(endpoints[0], cur);
            if (polygon.isInside(s.med)) {
                s = new Segment(endpoints[0], dst);
                if (polygon.isInside(s.med)) {
                    return endpoints[0];
                }
            }
            s = new Segment(endpoints[1], cur);
            if (polygon.isInside(s.med)) {
                s = new Segment(endpoints[1], dst);
                if (polygon.isInside(s.med)) {
                    return endpoints[1];
                }
            }
            i++;
        } while(i < 1000);
        return null;
    }

    private allTaken(): boolean {
        return this.generatedSegments.filter(s => !s.isCompleted()).length === 0;
    }

    private getCloserUntaken(currentPosition: google.maps.LatLngLiteral) {
        return this.generatedSegments.map((item, index) => {
            return {
                segment: item,
                index: index,
            }
        }).filter(s => !s.segment.isCompleted()).map(s => {
            const endpoints = s.segment.endpoints;
            const d1 = distance(endpoints[0], currentPosition);
            const d2 = distance(endpoints[1], currentPosition);

            let d: number;
            let position: google.maps.LatLngLiteral;
            if (d1 < d2) {
                d = d1;
                position = endpoints[0];
            } else {
                d = d2;
                position = endpoints[1];
            }
            return {
                position: position,
                distance: d,
                index: s.index,
            };
        }).sort((i1, i2) => {
            return i1.distance - i2.distance;
        });
    }

    private generate(polygon: Polygon): Segment[] {
        let generated = [];
        for (let segment of this.segments) {
            const intersections = polygon.intersect(segment);
            //intersections.push(...segment.endpoints);
            const points = intersections.sort((p1, p2) => {
                return p1.lat - p2.lat;
            });
            for (let i=0; i<points.length-1; i++) {
                const start = points[i];
                const end = points[i+1];
                if (start.lat === end.lat && start.lng === end.lng) {
                    continue;
                }
                let s = new Segment(start, end);
                // if the median point is inside the polygon, add it to the generated list
                if (polygon.isInside(s.med)) {
                    generated.push(s);
                }
            }
        }
        return generated;
    }
}

export class Segment extends Line {
    protected start: google.maps.LatLngLiteral;
    protected end: google.maps.LatLngLiteral;

    constructor(start: google.maps.LatLngLiteral, end: google.maps.LatLngLiteral) {
        super(start, end);
        this.start = {
            lat: start.lat,
            lng: start.lng,
        };
        this.end = {
            lat: end.lat,
            lng: end.lng,
        };
    }

    getRotation(): number {
        return Math.atan2(this.end.lat - this.start.lat, this.end.lng - this.start.lng) * 180 / Math.PI;
    }

    lengthMeters(): number {
        return google.maps.geometry.spherical.computeDistanceBetween(this.start, this.end);
    }

    middle(): google.maps.LatLngLiteral {
        return {
            lat: (this.start.lat + this.end.lat) / 2,
            lng: (this.start.lng + this.end.lng) / 2,
        };
    }

    intersect(line: Line): google.maps.LatLngLiteral | null {
        const intersection = super.intersect(line);
        if (intersection && this.isInside(intersection!)) {
            return intersection;
        }
        return null;
    }

    isInside(point: google.maps.LatLngLiteral): boolean {
        const a = this.getA();
        const b = this.getB();
        const c = this.getC();

        const x = point.lng;
        const y = point.lat;

        if (!(Math.abs(a*x + b*y + c) <= 0.00000001)) {
            return false;
        }

        const maxLng = (this.start.lng > this.end.lng ? this.start.lng : this.end.lng) + 0.00001;
        const minLng = (this.start.lng < this.end.lng ? this.start.lng : this.end.lng) - 0.00001;
        const maxLat = (this.start.lat > this.end.lat ? this.start.lat : this.end.lat) + 0.00001;
        const minLat = (this.start.lat < this.end.lat ? this.start.lat : this.end.lat) - 0.00001;

        return x >= minLng && x <= maxLng && y >= minLat && y <= maxLat;
    }

    getOtherEndpoint(endpoint: google.maps.LatLngLiteral): google.maps.LatLngLiteral {
        const dStart = distance(this.start, endpoint);
        const dEnd = distance(this.end, endpoint);
        if (dStart < dEnd) {
            return {
                lat: this.end.lat,
                lng: this.end.lng,
            };
        }
        return {
            lat: this.start.lat,
            lng: this.start.lng,
        };
    }

    get endpoints(): google.maps.LatLngLiteral[] {
        return [this.start, this.end];
    }

    get med(): google.maps.LatLngLiteral {
        return {
            lat: (this.start.lat + this.end.lat) / 2,
            lng: (this.start.lng + this.end.lng) / 2,
        };
    }
}

class MarkedSegment extends Segment {
    private taken: boolean = false;

    constructor(segment: Segment) {
        const endpoints = segment.endpoints;
        super(endpoints[0], endpoints[1]);
    }

    isCompleted(): boolean {
        return this.taken;
    }

    mark() {
        this.taken = true;
    }
}

export class Circle {
    // x^2 + x^2 + ax + by + c = 0
    private a: number;
    private b: number;
    private c: number;

    constructor(center: google.maps.LatLngLiteral, radius: number) {
        // Cx = - a / 2                         -> a = -2 * Cx
        // Cy = - b / 2                         -> b = -2 * Cy
        // r^2 = (a / 2)^2 + (b / 2)^2 - c      -> c = (a / 2)^2 + (b / 2)^2 - r^2

        const r = metersToCoordinates(radius);

        this.a = -2 * center.lng;
        this.b = -2 * center.lat;
        this.c = Math.pow(this.a / 2, 2) + Math.pow(this.b / 2, 2) - Math.pow(r, 2);
    }

    generatePoints(): google.maps.LatLngLiteral[] {
        const points: google.maps.LatLngLiteral[] = [];
        const center = {
            lat: - this.b / 2,
            lng: - this.a / 2,
        };
        const radius = this.radius;
        const step = 0.1;
        for (let i = 0; i < 2 * Math.PI; i += step) {
            points.push({
                lat: center.lat + radius * Math.cos(i),
                lng: center.lng + radius * Math.sin(i),
            });
        }
        return points;
    }

    get radius(): number {
        return Math.sqrt(Math.pow(this.a / 2, 2) + Math.pow(this.b / 2, 2) - this.c);
    }

    intersect(line: Line): google.maps.LatLngLiteral[] {
        const a = line.getA();
        const b = line.getB();
        const c = line.getC();
        const d = this.a;
        const e = this.b;
        const f = this.c;
        const g = a*a + b*b;
        const h = 2*a*c + b*b*d - a*b*e;
        const i = f*b*b - b*c*e + c*c;
        const x1 = (-h + Math.sqrt(h*h - 4*g*i)) / (2*g);
        const x2 = (-h - Math.sqrt(h*h - 4*g*i)) / (2*g);
        const y1 = -(a*x1 + c) / b;
        const y2 = -(a*x2 + c) / b;
        return [{
            lat: y1,
            lng: x1,
        }, {
            lat: y2,
            lng: x2,
        }];
    }
}

class CoordinatesCounter {
    private sum: google.maps.LatLngLiteral;
    private counter: number;

    constructor(pos: google.maps.LatLngLiteral) {
        this.sum = pos;
        this.counter = 1;
    }

    add(value: CoordinatesCounter): CoordinatesCounter {
        this.sum.lat += value.sum.lat;
        this.sum.lng += value.sum.lng;
        this.counter += value.counter;
        return this;
    }

    get avg(): google.maps.LatLngLiteral {
        return {
            lat: this.sum.lat / this.counter,
            lng: this.sum.lng / this.counter,
        };
    }
}

export class Polygon {
    protected points: google.maps.LatLngLiteral[];

    constructor(...points: google.maps.LatLngLiteral[]) {
        this.points = points;
    }

    get center(): google.maps.LatLngLiteral {
        const sum = this.points.reduce((prev, cur) => {
            return {
                lat: prev.lat + cur.lat,
                lng: prev.lng + cur.lng,
            }
        });

        return {
            lat: sum.lat / this.points.length,
            lng: sum.lng / this.points.length,
        };
    }

    get segments(): Segment[] {
        let segments = [];
        for (let i = 0; i < this.points.length; i++) {
            const p1 = this.points[i];
            const p2 = this.points[(i + 1) % this.points.length];
            segments.push(new Segment(p1, p2));
        }
        return segments;
    }

    areaMeters(): number {
        return google.maps.geometry.spherical.computeArea(this.points);
    }

    intersect(line: Line): google.maps.LatLngLiteral[] {
        const intersections: google.maps.LatLngLiteral[] = [];
        this.segments.forEach(s => {
            const intersection = line.intersect(s);
            if (intersection) {
                intersections.push(intersection);
            }
        });
        return intersections;
    }

    getRoundingBox(): Rectangle {
        const southwest = this.points.reduce((prev, cur) => {
            return {
                lat: prev.lat < cur.lat ? prev.lat : cur.lat,
                lng: prev.lng < cur.lng ? prev.lng : cur.lng,
            }
        });
        const northeast = this.points.reduce((prev, cur) => {
            return {
                lat: prev.lat > cur.lat ? prev.lat : cur.lat,
                lng: prev.lng > cur.lng ? prev.lng : cur.lng,
            }
        });
        return new Rectangle(southwest, northeast);
    }

    isInside(point: google.maps.LatLngLiteral): boolean {
        const segments = this.segments;
        for (let s of segments) {
            if (s.isInside(point)) {
                return true;
            }
        }

        const ray = new Line(point, {
            lat: point.lat + 1,
            lng: point.lng,
        })
        const intersections = this.intersect(ray).filter(p => p.lat > point.lat - 0.00001);
        // it is inside with the polygon if it intersects with an odd number of segments
        return intersections.length % 2 === 1;
    }

    getPoints(): google.maps.LatLngLiteral[] {
        return this.points;
    }

    getLongestSegment(): Segment | null {
        const s = this.segments;
        if (s.length === 0) {
            return null;
        }
        return s.sort((s1, s2) => s2.lengthMeters() - s1.lengthMeters())[0];
    }

    rotate(angleDegrees: number): Polygon {
        const points = this.segments.map(s => rotatePoint(s.endpoints[0], angleDegrees));
        return new Polygon(...points);
    }
}

export class Rectangle extends Polygon {
    constructor(southwest: google.maps.LatLngLiteral, northeast: google.maps.LatLngLiteral) {
        super(
            southwest,
            {
                lat: southwest.lat,
                lng: northeast.lng,
            },
            northeast,
            {
                lat: northeast.lat,
                lng: southwest.lng,
            },
        );
    }

    get southwest(): google.maps.LatLngLiteral {
        return this.points[0];
    }

    get northeast(): google.maps.LatLngLiteral {
        return this.points[2];
    }
}

export function getNearest(from: google.maps.LatLngLiteral, list: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral {
    return list.sort((a, b) => distance(from, a) - distance(from, b))[0];
}

export function getFurthest(from: google.maps.LatLngLiteral, list: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral {
    return list.sort((a, b) => distance(from, b) - distance(from, a))[0];
}

export function rotatePoint(point: google.maps.LatLngLiteral, angleDegrees: number): google.maps.LatLngLiteral {
    const alpha = angleDegrees * Math.PI / 180;
    const x = point.lng;
    const y = point.lat;
    return {
        lng: x * Math.cos(alpha) - y * Math.sin(alpha),
        lat: x * Math.sin(alpha) + y * Math.cos(alpha),
    }
}

export function distance(pos1: google.maps.LatLngLiteral, pos2: google.maps.LatLngLiteral): number {
    const dx = pos1.lng - pos2.lng;
    const dy = pos1.lat - pos2.lat;
    return Math.sqrt(dx * dx + dy * dy);
}

export function coordinatesToMeters(coordinates: number): number {
    //Earth’s radius, sphere
    const R=6378137;

    const dLat = coordinates * Math.PI/180;
    return dLat * R;
}

export function metersToCoordinates(meters: number): number {
    //Earth’s radius, sphere
    const R=6378137;

    const dLat = meters/R;
    return dLat * 180/ Math.PI;
}
