import React from "react";
import Flight from "../../classes/flight";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface AnalysisType {
  name: string;
  value: string;
}

export const analysisTypes: AnalysisType[] = [
  { name: 'Extensive', value: 'extensive' },
  { name: 'Vineyard', value: 'vineyard' },
  { name: 'Photogrammetry', value: 'photogrammetry' },
];

export interface AnalyzeDialogProps {
  flight: Flight;
  open: boolean;
  onClose: (analysisType?: AnalysisType) => void;
}

const AnalyzeDialog = (props: AnalyzeDialogProps) => {
  const [ value, setValue ] = React.useState<string>('');

  const { open, onClose } = props;

  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle>{t('Analyze Flight')}</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel id="analysis-radio-buttons-group">
            {t('Select which kind of analysis you want to perform on the flight.')}
          </FormLabel>
          <RadioGroup
            aria-labelledby="analysis-radio-buttons-group"
            name="analysis-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            { analysisTypes.map((analysisType: AnalysisType) => (
              <FormControlLabel key={analysisType.value} value={analysisType.value} control={<Radio />} label={t(analysisType.name)} />
            )) }
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <Button onClick={() => {
          if (!value) {
            return;
          }
          onClose(analysisTypes.find((analysisType: AnalysisType) => analysisType.value === value));
          setValue('');
        }} autoFocus disabled={!value}>
          {t('Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnalyzeDialog;
