import { Paper, Theme, ThemeProvider, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

export interface FrameProps {
  theme: Theme;
  fullHeight?: boolean;
}

const FrameLayout = (props: FrameProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return <ThemeProvider theme={props.theme}>
    <Paper style={{
      width: '100%',
      //height: props.fullHeight ? 'calc(100vh - 120px)' : 'auto',
      height: props.fullHeight ? 'calc(100% - 56px)' : 'auto',
      borderRadius: isMobile ? 0 : '15px',
      overflow: 'hidden',
    }}>
      <Outlet />
    </Paper>
  </ThemeProvider>;
};

export default FrameLayout;
