import { MouseEvent, ReactNode } from "react";
import { Row } from "../components/EnhancedTable";
import { TableCell, TableRow } from "@mui/material";

export default class Organization implements Row {
  uuid: string;
  name: string;

  constructor(uuid: string, name: string) {
    this.uuid = uuid;
    this.name = name;
  }

  render = (
    labelID: string,
    isItemSelected: boolean,
    handleClick: (event: MouseEvent<unknown>, id: string) => void,
  ): ReactNode => {
    return (
      <TableRow
        hover
        onClick={(event) => handleClick(event, this.uuid)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={this.uuid}
        selected={isItemSelected}
        sx={{ cursor: 'pointer' }}
      >
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelID,
            }}
          />
        </TableCell> */}
        <TableCell
          component="th"
          id={labelID}
          scope="row"
        >
          {this.uuid}
        </TableCell>
        <TableCell>{this.name}</TableCell>
      </TableRow>
    );
  }
}
