import React from "react";
import { Row } from "../components/EnhancedTable";
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TableCell, TableRow } from "@mui/material";
import { dateFormat } from "../common/utils/text";
import { gql, useMutation } from "@apollo/client";
import Rename from "../common/dialogs/Rename";
import ConfirmDialog from "../common/dialogs/ConfirmDialog";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DrawIcon from '@mui/icons-material/Draw';
import { Link, NavigateFunction } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default class Field implements Row {
  id: number;
  uuid: string;
  name: string;
  private created_at: string;
  private updated_at: string;

  constructor(id: number, uuid: string, name: string, created_at: string, updated_at: string) {
    this.id = id;
    this.uuid = uuid;
    this.name = name;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }

  navigate?: NavigateFunction;

  render = (
    labelID: string,
    isItemSelected: boolean,
    handleClick: (event: React.MouseEvent<unknown>, id: string) => void,
  ): React.ReactNode => {
    return (
      <TableRow
        hover
        onClick={(event) => {
          handleClick(event, this.uuid);
          this.navigate && this.navigate(this.uuid);
        }}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={this.uuid}
        selected={isItemSelected}
        sx={{ cursor: 'pointer' }}
      >
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelID,
            }}
          />
        </TableCell> */}
        <TableCell
          component="th"
          id={labelID}
          scope="row"
        >
          {this.uuid}
        </TableCell>
        <TableCell>{this.name}</TableCell>
        <TableCell>{dateFormat(this.created_at, true)}</TableCell>
        <TableCell>{dateFormat(this.updated_at, true)}</TableCell>
        <TableCell>
          <AreaRowMenu field={this} />
        </TableCell>
      </TableRow>
    );
  }
}

interface AreaRowMenuProps {
  field: Field;
}

const AreaRowMenu = (props: AreaRowMenuProps) => {
  const [ renameDialogOpen, setRenameDialogOpen ] = React.useState(false);
  const [ confirmDialogOpen, setConfirmDialogOpen ] = React.useState(false);

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const [ renameField ] = useMutation(gql(`
    mutation RenameField($uuid: uuid!, $name: String!) {
      update_fields(where: {uuid: {_eq: $uuid}}, _set: {name: $name}) {
        returning {
          uuid
        }
      }
    }
  `), {
    refetchQueries: ['Fields', 'Field', 'FieldNames'],
  });

  const [ deleteField ] = useMutation(gql(`
    mutation DeleteField($uuid: uuid!) {
      update_fields(where: {uuid: {_eq: $uuid}}, _set: {deleted_at: "now()"}) {
        returning {
          uuid
        }
      }
    }
  `), {
    refetchQueries: ['Fields', 'Field', 'FieldNames'],
  });

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="button"
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <Link to={props.field.uuid}><MenuItem>
          <ListItemIcon>
            <DrawIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Edit')}</ListItemText>
        </MenuItem></Link>
        <Divider />
        <MenuItem onClick={(e) => {
          setRenameDialogOpen(true);
          handleClose(e);
        }}>
          <ListItemIcon>
            <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Rename')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => {
          setConfirmDialogOpen(true);
          handleClose(e);
        }}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Delete')}</ListItemText>
        </MenuItem>
      </Menu>

      <Rename
        open={renameDialogOpen}
        title={t("Rename Area")}
        text={
          <React.Fragment>
            {t('Rename the area')} <strong>{props.field.name}</strong>.
          </React.Fragment>
        }
        onClose={async (name) => {
          if (name) {
            await renameField({ variables: { uuid: props.field.uuid, name } });
          }
          setRenameDialogOpen(false);
        }}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        confirmColor="error"
        title={t("Delete Area")}
        body={
          <React.Fragment>
            {t('Are you sure you want to delete the area')} <strong>{props.field.name}</strong>?
          </React.Fragment>
        }
        onClose={async (confirm) => {
          if (confirm) {
            await deleteField({ variables: { uuid: props.field.uuid } });
          }
          setConfirmDialogOpen(false);
        }}
      />
    </React.Fragment>
  );
};
