import React from "react";
import { OrganizationContext } from "../common/contexts/organization";
import { gql, useQuery } from "@apollo/client";
import { and, whereNotDeleted, whereOrganization } from "../common/utils/graphql";
import Loading from "../common/components/Loading";
import EnhancedTable from "../components/EnhancedTable";
import { Backdrop, Button, SpeedDial, Typography } from "@mui/material";
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DrawIcon from '@mui/icons-material/Draw';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import FlightPlan from "../classes/flightPlan";
import { Link, useNavigate } from "react-router-dom";
import { darkGray } from "../theme";
import SelectAreaDialog from "../components/dialogs/SelectAreaDialog";
import { useTranslation } from "react-i18next";

const headCells = [
  // { id: 'uuid', numeric: false, disablePadding: false, label: 'UUID' }, 
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Created At' },
  { id: 'updated_at', numeric: false, disablePadding: false, label: 'Updated At' },
  { id: 'menu', numeric: false, disablePadding: false, label: '', unsortable: true },
];

const Plans = () => {
  const [selectAreaDialogOpen, setSelectAreaDialogOpen] = React.useState(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const [fps, setFps] = React.useState<FlightPlan[]>([]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const organizationCtx = React.useContext(OrganizationContext);

  const { loading, error, data } = useQuery(gql(`
    query FlightPlans {
      flight_plans(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {name: asc}) {
        id
        uuid
        name
        created_at
        updated_at
        drones {
          uuid
        }
      }
    }
  `));

  const handleAddOpen = () => setAddOpen(true);
  const handleAddClose = () => setAddOpen(false);

  React.useEffect(() => {
    if (data) {
      setFps(data.flight_plans.map((fp: any) => {
        const f = new FlightPlan(fp.uuid, fp.name, fp.created_at, fp.updated_at);
        f.id = fp.id;
        f.dronesUUIDs = fp.drones.map((d: any) => d.uuid);
        f.navigate = navigate;
        return f;
      }));
    }
  }, [data]);

  if (loading && !data) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      { fps.length > 0
      ?
      <EnhancedTable
        title="Missions"
        headCells={headCells}
        rows={fps} />
      :
      <React.Fragment>
        <Typography variant="h5" style={{
          textAlign: 'center',
          marginTop: '32px',
          textTransform: 'uppercase',
          color: darkGray,
        }}>
          {t('No flight plans found')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddOpen}
          style={{
            margin: '32px auto',
            display: 'block',
          }}>
          {t('Add FlightPlan')}
        </Button>
        <Typography variant="body2" style={{
          textAlign: 'center',
          color: darkGray,
        }}>
          {t('or')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => window.location.reload()}
          style={{
            display: 'block',
            margin: '16px auto',
          }}>
          {t('Reload')}
        </Button>
      </React.Fragment>
      }

      <Backdrop open={addOpen} style={{
        zIndex: 2,
      }} />
      <SpeedDial
        ariaLabel={t("Add FlightPlan")}
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleAddClose}
        onOpen={handleAddOpen}
        open={addOpen}
      >
        <SpeedDialAction
          icon={<Grid3x3Icon />}
          tooltipTitle={t('Existing Area')}
          tooltipOpen
          onClick={() => {
            handleAddClose();
            setSelectAreaDialogOpen(true);
          }}
        />
        <SpeedDialAction
          icon={<Link to={"new/area"}><ArchitectureIcon /></Link>}
          tooltipTitle={t('New Area')}
          tooltipOpen
          onClick={handleAddClose}
        />
        <SpeedDialAction
          icon={<DrawIcon />}
          tooltipTitle={t('Linear')}
          tooltipOpen
          onClick={handleAddClose}
          FabProps={{
            disabled: true,
          }}
        />
      </SpeedDial>

      <SelectAreaDialog
        open={selectAreaDialogOpen}
        onClose={(field) => {
          setSelectAreaDialogOpen(false);
          if (field) {
            navigate('new/area/'+field.uuid);
          }
        }}
      />
    </React.Fragment>
  );
};

export default Plans;
