import { Button, Card, CardActions, CardContent, Fab, Typography } from "@mui/material";
import React from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from "react-i18next";

export interface InfoWindowProps {
  title?: string;
  text: string;
  open?: boolean;
  width?: number;
}

const InfoWindow = (props: InfoWindowProps) => {
  const [ open, setOpen ] = React.useState<boolean>(props.open || false);

  const { t } = useTranslation();

  const style: React.CSSProperties = {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 1000,
  };

  if (!open) {
    return (
      <div style={style}>
        <Fab size="medium" onClick={() => {
          setOpen(true);
        }}>
          <InfoOutlinedIcon />
        </Fab>
      </div>
    );
  }

  return (
    <div style={{
      width: props.width ?? 150,
      ...style
    }}>
      <Card variant="outlined">
        <CardContent>
          { props.title && <Typography color="text.secondary" gutterBottom>
            { t(props.title) }
          </Typography> }
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            { t(props.text) }
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => {
            setOpen(false);
          }}>{t('OK')}</Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default InfoWindow;
