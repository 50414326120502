import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();

  const style = {
    borderRadius: 15,
    overflow: 'hidden',
  };

  return (
    <div style={{
      justifyContent: 'center',
      textAlign: 'center',
      height: '100%',
      width: '100%',
      overflow: 'scroll',
      padding: '20px',
    }}>
      <Typography variant="h4">
        {t('This section will be available soon.')}
      </Typography>
      <img src="imgs/homer.gif" style={{
        width: '100%',
        maxWidth: '500px',
        objectFit: 'contain',
        marginTop: '20px',
        marginBottom: '20px',
        borderRadius: '15px',
      }} />
      <Typography variant="h4">
        {t('We are working hard on it.')}
      </Typography>
    </div>
    // <Grid container spacing={2}>
    //   <Grid item xs={3}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    //   <Grid item xs={3}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    //   <Grid item xs={3}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    //   <Grid item xs={3}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    //   <Grid item xs={6}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    //   <Grid item xs={6}>
    //     <Skeleton variant="rectangular" animation="wave" height={300} style={style} />
    //   </Grid>
    // </Grid>
  );
};

export default Dashboard;
