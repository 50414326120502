import { Card, CardActions, IconButton, Typography } from "@mui/material";
import React from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export interface CardButtonProps {
  icon?: React.ReactNode;
  text: string;
  secondaryText?: string;
  secondaryTextPosition?: 'left' | 'right';
  showArrow?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
}

function CardButton(props: CardButtonProps) {
  const cardStyle: React.CSSProperties = {
    marginBottom: 10,
    ...props.style,
  };

  let secondaryTextStyle: React.CSSProperties = {};
  switch (props.secondaryTextPosition) {
    case 'left':
      secondaryTextStyle = {
        marginLeft: 10,
      };
      break;
    case 'right':
      secondaryTextStyle = {
        marginRight: 10,
        marginLeft: 'auto',
      };
      break;
  }

  return (
    <Card style={cardStyle}>
      <CardActions disableSpacing onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}>
        { props.icon && <IconButton>
          { props.icon }
        </IconButton> }
        { props.secondaryText && props.secondaryTextPosition === 'left' && <Typography variant="body2" style={secondaryTextStyle}>
          { props.secondaryText }
        </Typography> }
        <Typography variant="body1" style={{
          paddingLeft: 10,
        }}>
          { props.text }
        </Typography>
        { props.secondaryText && props.secondaryTextPosition === 'right' && <Typography variant="body2" style={secondaryTextStyle}>
          { props.secondaryText }
        </Typography> }
        { props.showArrow &&
        <IconButton style={{
          marginLeft: 'auto',
        }}>
          <ArrowForwardIosIcon />
        </IconButton> }
      </CardActions>
    </Card>
  );
}

export default CardButton;
