import { Chip, CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export interface DroneStatusChipProps {
  status: string;
  loading?: boolean;
};

const DroneStatusChip = (props: DroneStatusChipProps) => {
  const [ color, setColor ] = React.useState<string>("#e0e0e0");

  const { t } = useTranslation();

  React.useEffect(() => {
    switch (props.status) {
      case "UNKNOWN":
        setColor("#e0e0e0");
        break;
      case "OTHER":
        setColor("#e0e0e0");
        break;
      case "LANDED":
        setColor("#fbc02d");
        break;
      case "HOVERING":
        setColor("#f57c00");
        break;
      case "FLYING":
        setColor("#689f38");
        break;
      case "FLIGHT_PLAN":
        setColor("#00796b");
        break;
      case "DOWNLOADING":
        setColor("#90a4ae");
        break;
    };
  }, [props.status]);

  return (
    <Chip
      label={
        props.loading
        ?
        <CircularProgress style={{
          width: "18px",
          height: "18px",
        }} />
        :
        t(props.status)
      }
      style={{
        backgroundColor: color,
      }}
    />
  );
};

export default DroneStatusChip;
