import React from "react";
import DrawArea from "../../components/flightplans/DrawArea";
import { useNavigate, useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import Loading from "../../common/components/Loading";
import { whereNotDeleted } from "../../common/utils/graphql";

const EditArea = () => {
  const [ fieldID, setFieldID ] = React.useState<number | undefined>(undefined);
  const [ path, setPath ] = React.useState<google.maps.LatLngLiteral[] | undefined>([]);

  const { fieldUUID } = useParams();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(gql(`
    query Field($uuid: uuid!) {
      fields(where: {uuid: {_eq: $uuid}}) {
        id
        lat_lngs(where: `+whereNotDeleted()+`) {
          lat
          lng
        }
      }
    }
  `), {
    variables: {
      uuid: fieldUUID,
    }
  });

  const [ deleteOldLatLngs ] = useMutation(gql(`
    mutation DeleteOldLatLngs($field_id: bigint!) {
      update_lat_lngs(where: {field_id: {_eq: $field_id}}, _set: {deleted_at: "now()"}) {
        affected_rows
      }
    }
  `), {
    refetchQueries: ['Fields', 'Field'],
  });

  const [ insertLatLng ] = useMutation(gql(`
    mutation InsertLatLng($field_id: bigint!, $lat: numeric, $lng: numeric) {
      insert_lat_lngs(objects: {
        field_id: $field_id,
        lat: $lat,
        lng: $lng,
      }) {
        returning {
          id
        }
      }
    }
  `), {
    refetchQueries: ['Fields', 'Field'],
  });

  React.useEffect(() => {
    if (data) {
      setFieldID(data.fields[0].id);
      setPath(data.fields[0].lat_lngs.map((ll: any) => (new google.maps.LatLng(ll.lat, ll.lng).toJSON())));
    }
  }, [data]);

  if (!fieldUUID) {
    return null;
  }

  if (loading || !path || path.length === 0) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      <DrawArea
        path={path}
        centerPath
        onPathChange={async (p) => {
          await deleteOldLatLngs({ variables: {
            field_id: fieldID,
          } });
          p.forEach(async (latLng) => {
            await insertLatLng({ variables: {
              field_id: fieldID,
              lat: latLng.lat,
              lng: latLng.lng,
            } });
          });
          navigate('..');
        }}
      />
    </React.Fragment>
  );
}

export default EditArea;
