import { Autocomplete, Box, Dialog, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import React from "react";
import { useTranslation } from "react-i18next";

export interface SearchLocationDialogProps {
  open: boolean;
  onClose: (location?: google.maps.LatLngLiteral) => void;
}

const SearchLocationDialog = (props: SearchLocationDialogProps) => {
  const { open, onClose } = props;

  const [ inputValue, setInputValue ] = React.useState<string>('');

  const { t } = useTranslation();

  const map = useMap();
  const places = useMapsLibrary('places');

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
    React.useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
    React.useState<google.maps.places.AutocompleteService | null>(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] =
    React.useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = React.useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);

  React.useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);

  const fetchPredictions = React.useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }

      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value;

      setInputValue(value);
      fetchPredictions(value);
    },
    [fetchPredictions]
  );

  const handleSuggestionClick = React.useCallback(
    (placeId: string) => {
      if (!places) return;

      const detailRequestOptions = {
        placeId,
        fields: ['geometry', 'name', 'formatted_address'],
        sessionToken
      };

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null
      ) => {
        setPredictionResults([]);
        setInputValue(placeDetails?.formatted_address ?? '');
        setSessionToken(new places.AutocompleteSessionToken());

        onClose(placeDetails?.geometry?.location?.toJSON());
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [places, placesService, sessionToken]
  );

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => onClose()}
      >
        <DialogTitle>{t('Search Location')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Insert here your location where to center the map to.')}
          </DialogContentText>
          <Autocomplete
            fullWidth
            options={predictionResults}
            autoHighlight
            getOptionLabel={(option) => option.description}
            renderOption={(props, option) => {
              const { key, ...other } = props as any;

              return (
                <Box
                  key={option.place_id}
                  component="li"
                  {...other}
                  onClick={() => handleSuggestionClick(option.place_id)}
                >
                  {option.description}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                required
                margin="dense"
                id="location"
                name="location"
                label={t("Location")}
                type="text"
                fullWidth
                variant="standard"
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onInput={onInputChange}
              />
            )}
            />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SearchLocationDialog;
