import React from "react";
import { OrganizationContext } from "../common/contexts/organization";
import { gql, useQuery } from "@apollo/client";
import { whereNotDeleted } from "../common/utils/graphql";
import Loading from "../common/components/Loading";
import { Backdrop, FormControl, InputLabel, MenuItem, Select, SpeedDial, SpeedDialAction } from "@mui/material";
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Organization from "../classes/organization";
import Members from "../components/organizations/Members";
import NewMemberDialog from "../components/organizations/NewMemberDialog";
import { useTranslation } from "react-i18next";

const Organizations = () => {
  const [addMemberDialogOpen, setAddMemberDialogOpen] = React.useState(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const [organizations, setOrganizations] = React.useState<Organization[]>([]);

  const organizationCtx = React.useContext(OrganizationContext);

  const { t } = useTranslation();

  const { loading, error, data } = useQuery(gql(`
    query Organizations {
      organizations(where: `+whereNotDeleted()+`, order_by: {name: asc}) {
        uuid
        name
      }
    }
  `));

  const handleAddOpen = () => setAddOpen(true);
  const handleAddClose = () => setAddOpen(false);

  React.useEffect(() => {
    if (data) {
      setOrganizations(data.organizations.map((org: any) => new Organization(org.uuid, org.name)));
    }
  }, [data]);

  if (loading && !data) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      <FormControl style={{
        margin: '16px',
        width: 200,
      }}>
        <InputLabel id="organization-select-label">{t('Organization')}</InputLabel>
        <Select
          labelId="organization-select-label"
          value={organizationCtx?.organizationID ?? "None"}
          label="Organization"
          onChange={(e) => {
            if (e.target.value === "None") {
              organizationCtx?.setOrganizationID(null);
              return;
            }
            organizationCtx?.setOrganizationID(e.target.value as string ?? null)
          }}
        >
          <MenuItem value={"None"}>
            <em>None</em>
          </MenuItem>
          {organizations.map((org) => (
            <MenuItem key={org.uuid} value={org.uuid}>{org.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      { organizationCtx?.organizationID && <Members organizationID={organizationCtx.organizationID} /> }

      <Backdrop open={addOpen} style={{
        zIndex: 2,
      }} />
      <SpeedDial
        ariaLabel="Add Organization"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleAddClose}
        onOpen={handleAddOpen}
        open={addOpen}
      >
        <SpeedDialAction
          icon={<GroupAddIcon />}
          tooltipTitle={'Organization'}
          tooltipOpen
          onClick={() => {
            handleAddClose();
          }}
          FabProps={{
            disabled: true,
          }}
        />
        <SpeedDialAction
          icon={<PersonAddIcon />}
          tooltipTitle={'Member'}
          tooltipOpen
          onClick={() => {
            setAddMemberDialogOpen(true);
            handleAddClose();
          }}
          FabProps={{
            disabled: true,
          }}
        />
      </SpeedDial>

      <NewMemberDialog
        organization={organizations.find((org) => org.uuid === organizationCtx?.organizationID)}
        open={addMemberDialogOpen}
        onClose={() => setAddMemberDialogOpen(false)}
      />
    </React.Fragment>
  );
};

export default Organizations;
