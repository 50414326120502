import React from "react";
import { useNavigate } from "react-router-dom";
import useEmbed from "../hooks/useEmbed";

export interface RedirectProps {
  to: string;
};

const Redirect = (props: RedirectProps) => {
  const navigate = useNavigate();
  const { appendParams } = useEmbed();

  React.useEffect(() => {
    navigate(appendParams(props.to));
  }, [navigate]);

  return null;
};

export default Redirect;
