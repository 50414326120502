import React from "react";
import { useLocation } from "react-router-dom";
import theme from "../theme";
import Menu, { MenuItem } from "./Menu";

const items: MenuItem[] = [
  { title: 'Pilot' },
  { title: 'Fleet', url: '/pilot/fleet' },
  { title: 'Learn', url: '/pilot/learn' },
  { title: 'Download App', url: '/pilot/download' },
];

export interface PilotMenuProps {
  open?: boolean;
  url?: string;
};

const PilotMenu = (props: PilotMenuProps) => {
  const location = useLocation();

  React.useEffect(() => {
    items.forEach(item => {
      item.active = !!item.url && location.pathname.startsWith(item.url);
    });
  }, [location]);

  return (
    <Menu
      theme={theme}
      open={props.open ?? false}
      title="DromtPILOT"
      items={items}
      url={props.url} />
  );
};

export default PilotMenu;
