import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export interface ConfirmDialogProps {
  open: boolean;
  onClose: (confirm?: boolean) => void;
  title: string;
  body: string | React.ReactNode;
  confirmColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
};

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { open, onClose, title, body, confirmColor } = props;

  const { t } = useTranslation();

  const bodyEl = typeof body === 'string' ? (
    <Typography
      variant="body1"
      style={{
        whiteSpace: 'pre-line',
      }}
    >
      {body}
    </Typography>
  ) : body;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {onClose()}}
      onClick={(e) => e.stopPropagation()}
      PaperProps={{
        component: 'form',
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onClose(true);
        },
      }}
    >
      <DialogTitle>{t(title)}</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          component="div"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          { bodyEl }
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}>{t('Cancel')}</Button>
        <Button
          type="submit"
          color={confirmColor ?? 'primary'}
        >
            {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
