import { Autocomplete, Box, Dialog, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React from "react";
import Drone from "../../classes/drone";
import { gql, useQuery } from "@apollo/client";
import Loading from "../../common/components/Loading";
import { useTranslation } from "react-i18next";

interface DroneModel {
  id: number;
  name: string;
}

export interface DroneModelDialogProps {
  drone: Drone;
  open: boolean;
  onClose: (droneModelID?: number) => void;
}

const DroneModelDialog = (props: DroneModelDialogProps) => {
  const { open, onClose } = props;

  const [ droneModels, setDroneModels ] = React.useState<DroneModel[]>([]);
  const [ selectedDroneModelInput, setSelectedDroneModelInput ] = React.useState<string>('');
  const [ selectedDroneModel, setSelectedDroneModel ] = React.useState<DroneModel | null>(null);

  const { t } = useTranslation();

  const { loading, error, data } = useQuery(gql(`
    query DroneModels {
      drone_models {
        id
        name
      }
    }
  `));

  const handleSuggestionClick = React.useCallback((droneModel: DroneModel) => {
    setSelectedDroneModel(droneModel);
    setSelectedDroneModelInput(droneModel.name);
    props.onClose(droneModel.id);
  }, []);

  const onInputChange = React.useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setSelectedDroneModelInput((event.target as HTMLInputElement).value);
  }, []);

  React.useEffect(() => {
    if (!data) return;
    setDroneModels(data.drone_models);
  }, [data]);

  React.useEffect(() => {
    const selectedModel = droneModels.find((model) => model.name === selectedDroneModelInput);
    if (selectedModel) {
      setSelectedDroneModel(selectedModel);
    }
  }, [selectedDroneModelInput, droneModels]);

  if (loading && !data) {
    return <Loading open />;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle>{t('Select Drone Model')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Select a Model for drone')} <strong>{props.drone.name}</strong>
        </DialogContentText>
        <Autocomplete
          fullWidth
          options={droneModels}
          autoHighlight
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => {
            const { key, ...other } = props as any;

            return (
              <Box
                key={option.id}
                component="li"
                {...other}
                onClick={() => handleSuggestionClick(option)}
              >
                {option.name}
              </Box>
            );
          }}
          inputValue={selectedDroneModelInput}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              id="droneModel"
              name="droneModel"
              label={t("Drone Model")}
              type="text"
              fullWidth
              variant="standard"
              value={selectedDroneModelInput}
              onChange={(e) => {
                setSelectedDroneModelInput(e.target.value);
              }}
              onInput={onInputChange}
            />
          )}
          />
      </DialogContent>
    </Dialog>
  );
};

export default DroneModelDialog;
