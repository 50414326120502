import { red } from '@mui/material/colors';
import { createTheme, getContrastRatio } from '@mui/material/styles';

export const primary = '#1771F1';
export const secondary = '#FFAA00';
export const gray = '#F0F0F0';
export const midGray = '#AAAAAA';
export const darkGray = '#6B6B6B';
export const black = '#000000';
export const agri = '#20BF55';
export const photogrammetry = '#000000';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: getContrastRatio(primary, '#fff') >= 1 ? '#fff' : '#000',
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: red['500'],
    }
  },
});

const themeAgri = createTheme({
  palette: {
    primary: {
      main: agri,
      contrastText: getContrastRatio(agri, '#fff') >= 1 ? '#fff' : '#000',
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: red['500'],
    }
  },
});

const themePhotogrammetry = createTheme({
  palette: {
    primary: {
      main: photogrammetry,
      contrastText: getContrastRatio(photogrammetry, '#fff') >= 1 ? '#fff' : '#000',
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: red['500'],
    }
  },
});

export default theme;
export { themeAgri, themePhotogrammetry };
