import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React from "react";
import Organization from "../../classes/organization";
import { useTranslation } from "react-i18next";

export interface NewMemberDialogProps {
  organization?: Organization;
  open: boolean;
  onClose: () => void;
}

const NewMemberDialog = (props: NewMemberDialogProps) => {
  const [ email, setEmail ] = React.useState<string>('');

  const { t } = useTranslation();

  if (!props.organization) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={() => props.onClose()}
    >
      <DialogTitle>{t('Add New Member')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Add a new member to Organization')} <strong>{props.organization.name}</strong>
        </DialogContentText>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label={t("Email")}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {props.onClose()}}>{t('Cancel')}</Button>
        <Button
          type="submit"
          disabled={email === '' || true}
        >{t('Add')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewMemberDialog;
