// source: common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.common.Ack', null, global);
goog.exportSymbol('proto.common.Ack.AckCase', null, global);
goog.exportSymbol('proto.common.Attitude', null, global);
goog.exportSymbol('proto.common.CameraSettings', null, global);
goog.exportSymbol('proto.common.CameraSettings.CameraType', null, global);
goog.exportSymbol('proto.common.CameraSettings.MediaResolution', null, global);
goog.exportSymbol('proto.common.CameraSettings.PhotoFormat', null, global);
goog.exportSymbol('proto.common.CameraSettings.PhotoMode', null, global);
goog.exportSymbol('proto.common.CameraSettings.StreamQuality', null, global);
goog.exportSymbol('proto.common.CameraSettings.VideoFormat', null, global);
goog.exportSymbol('proto.common.CameraSettings.VideoFrameRate', null, global);
goog.exportSymbol('proto.common.CameraSettings.VideoMode', null, global);
goog.exportSymbol('proto.common.Circle', null, global);
goog.exportSymbol('proto.common.Circle.CenterCase', null, global);
goog.exportSymbol('proto.common.Circle.QuantityCase', null, global);
goog.exportSymbol('proto.common.ClientData', null, global);
goog.exportSymbol('proto.common.ClientData.DataCase', null, global);
goog.exportSymbol('proto.common.ClientDataList', null, global);
goog.exportSymbol('proto.common.ClientSession', null, global);
goog.exportSymbol('proto.common.Command', null, global);
goog.exportSymbol('proto.common.Command.CommandCode', null, global);
goog.exportSymbol('proto.common.Command.DataCase', null, global);
goog.exportSymbol('proto.common.CommandList', null, global);
goog.exportSymbol('proto.common.Coordinates', null, global);
goog.exportSymbol('proto.common.DownloadMedia', null, global);
goog.exportSymbol('proto.common.DownloadMedia.CameraType', null, global);
goog.exportSymbol('proto.common.DownloadMedia.MediaType', null, global);
goog.exportSymbol('proto.common.DroneData', null, global);
goog.exportSymbol('proto.common.DroneData.DataCase', null, global);
goog.exportSymbol('proto.common.DroneSession', null, global);
goog.exportSymbol('proto.common.Empty', null, global);
goog.exportSymbol('proto.common.Error', null, global);
goog.exportSymbol('proto.common.Error.Code', null, global);
goog.exportSymbol('proto.common.FlightPlan', null, global);
goog.exportSymbol('proto.common.FlightPlan.DataCase', null, global);
goog.exportSymbol('proto.common.FlightPlanStatus', null, global);
goog.exportSymbol('proto.common.FlightPlanStatus.Status', null, global);
goog.exportSymbol('proto.common.Hover', null, global);
goog.exportSymbol('proto.common.Land', null, global);
goog.exportSymbol('proto.common.Log', null, global);
goog.exportSymbol('proto.common.Log.Level', null, global);
goog.exportSymbol('proto.common.ManualControl', null, global);
goog.exportSymbol('proto.common.Media', null, global);
goog.exportSymbol('proto.common.Media.ContentType', null, global);
goog.exportSymbol('proto.common.MediaUpload', null, global);
goog.exportSymbol('proto.common.MediaUpload.DataCase', null, global);
goog.exportSymbol('proto.common.MediaUpload.Request', null, global);
goog.exportSymbol('proto.common.MediaUpload.Request.ContentTypeCase', null, global);
goog.exportSymbol('proto.common.MediaUpload.Result', null, global);
goog.exportSymbol('proto.common.MediaUpload.Result.ResultCode', null, global);
goog.exportSymbol('proto.common.MediaUploadResponse', null, global);
goog.exportSymbol('proto.common.Metrics', null, global);
goog.exportSymbol('proto.common.Metrics.CameraState', null, global);
goog.exportSymbol('proto.common.Metrics.Capability', null, global);
goog.exportSymbol('proto.common.Metrics.GPSLevel', null, global);
goog.exportSymbol('proto.common.Metrics.LiveStreamState', null, global);
goog.exportSymbol('proto.common.Metrics.SignalLevel', null, global);
goog.exportSymbol('proto.common.Metrics.State', null, global);
goog.exportSymbol('proto.common.MoveAltitude', null, global);
goog.exportSymbol('proto.common.MoveGPS', null, global);
goog.exportSymbol('proto.common.MoveSpeed', null, global);
goog.exportSymbol('proto.common.Movement', null, global);
goog.exportSymbol('proto.common.Movement.Direction', null, global);
goog.exportSymbol('proto.common.Movement.Speed', null, global);
goog.exportSymbol('proto.common.ReturnHome', null, global);
goog.exportSymbol('proto.common.Rotate', null, global);
goog.exportSymbol('proto.common.StartRecording', null, global);
goog.exportSymbol('proto.common.StartStream', null, global);
goog.exportSymbol('proto.common.Stop', null, global);
goog.exportSymbol('proto.common.StopCamera', null, global);
goog.exportSymbol('proto.common.StopRecording', null, global);
goog.exportSymbol('proto.common.StopStream', null, global);
goog.exportSymbol('proto.common.TakePhoto', null, global);
goog.exportSymbol('proto.common.Takeoff', null, global);
goog.exportSymbol('proto.common.TiltCamera', null, global);
goog.exportSymbol('proto.common.Velocity', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Empty.displayName = 'proto.common.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.DroneSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.DroneSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.DroneSession.displayName = 'proto.common.DroneSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ClientSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.ClientSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ClientSession.displayName = 'proto.common.ClientSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.DroneData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.DroneData.oneofGroups_);
};
goog.inherits(proto.common.DroneData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.DroneData.displayName = 'proto.common.DroneData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ClientDataList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.ClientDataList.repeatedFields_, null);
};
goog.inherits(proto.common.ClientDataList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ClientDataList.displayName = 'proto.common.ClientDataList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ClientData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.ClientData.oneofGroups_);
};
goog.inherits(proto.common.ClientData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ClientData.displayName = 'proto.common.ClientData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Error.displayName = 'proto.common.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MediaUpload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.MediaUpload.oneofGroups_);
};
goog.inherits(proto.common.MediaUpload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.MediaUpload.displayName = 'proto.common.MediaUpload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MediaUpload.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.MediaUpload.Request.oneofGroups_);
};
goog.inherits(proto.common.MediaUpload.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.MediaUpload.Request.displayName = 'proto.common.MediaUpload.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MediaUpload.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.MediaUpload.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.MediaUpload.Result.displayName = 'proto.common.MediaUpload.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MediaUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.MediaUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.MediaUploadResponse.displayName = 'proto.common.MediaUploadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Media = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Media, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Media.displayName = 'proto.common.Media';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Metrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Metrics.repeatedFields_, null);
};
goog.inherits(proto.common.Metrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Metrics.displayName = 'proto.common.Metrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Command = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.Command.oneofGroups_);
};
goog.inherits(proto.common.Command, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Command.displayName = 'proto.common.Command';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.CommandList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.CommandList.repeatedFields_, null);
};
goog.inherits(proto.common.CommandList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.CommandList.displayName = 'proto.common.CommandList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Log = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Log, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Log.displayName = 'proto.common.Log';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FlightPlanStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.FlightPlanStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FlightPlanStatus.displayName = 'proto.common.FlightPlanStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Ack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.Ack.oneofGroups_);
};
goog.inherits(proto.common.Ack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Ack.displayName = 'proto.common.Ack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FlightPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.FlightPlan.oneofGroups_);
};
goog.inherits(proto.common.FlightPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FlightPlan.displayName = 'proto.common.FlightPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Attitude = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Attitude, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Attitude.displayName = 'proto.common.Attitude';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Coordinates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Coordinates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Coordinates.displayName = 'proto.common.Coordinates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Velocity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Velocity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Velocity.displayName = 'proto.common.Velocity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Movement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Movement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Movement.displayName = 'proto.common.Movement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.CameraSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.CameraSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.CameraSettings.displayName = 'proto.common.CameraSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Takeoff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Takeoff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Takeoff.displayName = 'proto.common.Takeoff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Land = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Land, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Land.displayName = 'proto.common.Land';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ReturnHome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.ReturnHome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ReturnHome.displayName = 'proto.common.ReturnHome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Hover = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Hover, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Hover.displayName = 'proto.common.Hover';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MoveSpeed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.MoveSpeed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.MoveSpeed.displayName = 'proto.common.MoveSpeed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MoveGPS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.MoveGPS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.MoveGPS.displayName = 'proto.common.MoveGPS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Circle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.Circle.oneofGroups_);
};
goog.inherits(proto.common.Circle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Circle.displayName = 'proto.common.Circle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Rotate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Rotate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Rotate.displayName = 'proto.common.Rotate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MoveAltitude = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.MoveAltitude, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.MoveAltitude.displayName = 'proto.common.MoveAltitude';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Stop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Stop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Stop.displayName = 'proto.common.Stop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StartStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StartStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StartStream.displayName = 'proto.common.StartStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StopStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StopStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StopStream.displayName = 'proto.common.StopStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.TakePhoto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.TakePhoto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.TakePhoto.displayName = 'proto.common.TakePhoto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StartRecording = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StartRecording, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StartRecording.displayName = 'proto.common.StartRecording';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StopRecording = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StopRecording, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StopRecording.displayName = 'proto.common.StopRecording';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.TiltCamera = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.TiltCamera, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.TiltCamera.displayName = 'proto.common.TiltCamera';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StopCamera = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StopCamera, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StopCamera.displayName = 'proto.common.StopCamera';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ManualControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.ManualControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ManualControl.displayName = 'proto.common.ManualControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.DownloadMedia = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.DownloadMedia, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.DownloadMedia.displayName = 'proto.common.DownloadMedia';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Empty}
 */
proto.common.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Empty;
  return proto.common.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Empty}
 */
proto.common.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.DroneSession.prototype.toObject = function(opt_includeInstance) {
  return proto.common.DroneSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.DroneSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.DroneSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    droneid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.DroneSession}
 */
proto.common.DroneSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.DroneSession;
  return proto.common.DroneSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.DroneSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.DroneSession}
 */
proto.common.DroneSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDroneid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.DroneSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.DroneSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.DroneSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.DroneSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDroneid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.DroneSession.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.DroneSession} returns this
 */
proto.common.DroneSession.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string droneId = 2;
 * @return {string}
 */
proto.common.DroneSession.prototype.getDroneid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.DroneSession} returns this
 */
proto.common.DroneSession.prototype.setDroneid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ClientSession.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ClientSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ClientSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ClientSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    droneid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ClientSession}
 */
proto.common.ClientSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ClientSession;
  return proto.common.ClientSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ClientSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ClientSession}
 */
proto.common.ClientSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDroneid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ClientSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ClientSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ClientSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ClientSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDroneid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.ClientSession.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ClientSession} returns this
 */
proto.common.ClientSession.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string droneId = 2;
 * @return {string}
 */
proto.common.ClientSession.prototype.getDroneid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.ClientSession} returns this
 */
proto.common.ClientSession.prototype.setDroneid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.DroneData.oneofGroups_ = [[2,3,4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.common.DroneData.DataCase = {
  DATA_NOT_SET: 0,
  ERROR: 2,
  MEDIA: 3,
  METRICS: 4,
  COMMAND: 5,
  HEARTBEAT: 6,
  LOG: 7,
  FLIGHT_PLAN_STATUS: 8,
  MEDIA_UPLOAD: 9
};

/**
 * @return {proto.common.DroneData.DataCase}
 */
proto.common.DroneData.prototype.getDataCase = function() {
  return /** @type {proto.common.DroneData.DataCase} */(jspb.Message.computeOneofCase(this, proto.common.DroneData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.DroneData.prototype.toObject = function(opt_includeInstance) {
  return proto.common.DroneData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.DroneData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.DroneData.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.common.DroneSession.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.common.Error.toObject(includeInstance, f),
    media: (f = msg.getMedia()) && proto.common.Media.toObject(includeInstance, f),
    metrics: (f = msg.getMetrics()) && proto.common.Metrics.toObject(includeInstance, f),
    command: (f = msg.getCommand()) && proto.common.Command.toObject(includeInstance, f),
    heartbeat: (f = msg.getHeartbeat()) && proto.common.Empty.toObject(includeInstance, f),
    log: (f = msg.getLog()) && proto.common.Log.toObject(includeInstance, f),
    flightPlanStatus: (f = msg.getFlightPlanStatus()) && proto.common.FlightPlanStatus.toObject(includeInstance, f),
    mediaUpload: (f = msg.getMediaUpload()) && proto.common.MediaUpload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.DroneData}
 */
proto.common.DroneData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.DroneData;
  return proto.common.DroneData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.DroneData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.DroneData}
 */
proto.common.DroneData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.DroneSession;
      reader.readMessage(value,proto.common.DroneSession.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 2:
      var value = new proto.common.Error;
      reader.readMessage(value,proto.common.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.common.Media;
      reader.readMessage(value,proto.common.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    case 4:
      var value = new proto.common.Metrics;
      reader.readMessage(value,proto.common.Metrics.deserializeBinaryFromReader);
      msg.setMetrics(value);
      break;
    case 5:
      var value = new proto.common.Command;
      reader.readMessage(value,proto.common.Command.deserializeBinaryFromReader);
      msg.setCommand(value);
      break;
    case 6:
      var value = new proto.common.Empty;
      reader.readMessage(value,proto.common.Empty.deserializeBinaryFromReader);
      msg.setHeartbeat(value);
      break;
    case 7:
      var value = new proto.common.Log;
      reader.readMessage(value,proto.common.Log.deserializeBinaryFromReader);
      msg.setLog(value);
      break;
    case 8:
      var value = new proto.common.FlightPlanStatus;
      reader.readMessage(value,proto.common.FlightPlanStatus.deserializeBinaryFromReader);
      msg.setFlightPlanStatus(value);
      break;
    case 9:
      var value = new proto.common.MediaUpload;
      reader.readMessage(value,proto.common.MediaUpload.deserializeBinaryFromReader);
      msg.setMediaUpload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.DroneData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.DroneData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.DroneData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.DroneData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.DroneSession.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Error.serializeBinaryToWriter
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Media.serializeBinaryToWriter
    );
  }
  f = message.getMetrics();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Metrics.serializeBinaryToWriter
    );
  }
  f = message.getCommand();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.Command.serializeBinaryToWriter
    );
  }
  f = message.getHeartbeat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.Empty.serializeBinaryToWriter
    );
  }
  f = message.getLog();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.common.Log.serializeBinaryToWriter
    );
  }
  f = message.getFlightPlanStatus();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.common.FlightPlanStatus.serializeBinaryToWriter
    );
  }
  f = message.getMediaUpload();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.common.MediaUpload.serializeBinaryToWriter
    );
  }
};


/**
 * optional DroneSession session = 1;
 * @return {?proto.common.DroneSession}
 */
proto.common.DroneData.prototype.getSession = function() {
  return /** @type{?proto.common.DroneSession} */ (
    jspb.Message.getWrapperField(this, proto.common.DroneSession, 1));
};


/**
 * @param {?proto.common.DroneSession|undefined} value
 * @return {!proto.common.DroneData} returns this
*/
proto.common.DroneData.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.DroneData} returns this
 */
proto.common.DroneData.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.DroneData.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Error error = 2;
 * @return {?proto.common.Error}
 */
proto.common.DroneData.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto.common.Error, 2));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.common.DroneData} returns this
*/
proto.common.DroneData.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.common.DroneData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.DroneData} returns this
 */
proto.common.DroneData.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.DroneData.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Media media = 3;
 * @return {?proto.common.Media}
 */
proto.common.DroneData.prototype.getMedia = function() {
  return /** @type{?proto.common.Media} */ (
    jspb.Message.getWrapperField(this, proto.common.Media, 3));
};


/**
 * @param {?proto.common.Media|undefined} value
 * @return {!proto.common.DroneData} returns this
*/
proto.common.DroneData.prototype.setMedia = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.common.DroneData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.DroneData} returns this
 */
proto.common.DroneData.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.DroneData.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Metrics metrics = 4;
 * @return {?proto.common.Metrics}
 */
proto.common.DroneData.prototype.getMetrics = function() {
  return /** @type{?proto.common.Metrics} */ (
    jspb.Message.getWrapperField(this, proto.common.Metrics, 4));
};


/**
 * @param {?proto.common.Metrics|undefined} value
 * @return {!proto.common.DroneData} returns this
*/
proto.common.DroneData.prototype.setMetrics = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.common.DroneData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.DroneData} returns this
 */
proto.common.DroneData.prototype.clearMetrics = function() {
  return this.setMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.DroneData.prototype.hasMetrics = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Command command = 5;
 * @return {?proto.common.Command}
 */
proto.common.DroneData.prototype.getCommand = function() {
  return /** @type{?proto.common.Command} */ (
    jspb.Message.getWrapperField(this, proto.common.Command, 5));
};


/**
 * @param {?proto.common.Command|undefined} value
 * @return {!proto.common.DroneData} returns this
*/
proto.common.DroneData.prototype.setCommand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.common.DroneData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.DroneData} returns this
 */
proto.common.DroneData.prototype.clearCommand = function() {
  return this.setCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.DroneData.prototype.hasCommand = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Empty heartbeat = 6;
 * @return {?proto.common.Empty}
 */
proto.common.DroneData.prototype.getHeartbeat = function() {
  return /** @type{?proto.common.Empty} */ (
    jspb.Message.getWrapperField(this, proto.common.Empty, 6));
};


/**
 * @param {?proto.common.Empty|undefined} value
 * @return {!proto.common.DroneData} returns this
*/
proto.common.DroneData.prototype.setHeartbeat = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.common.DroneData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.DroneData} returns this
 */
proto.common.DroneData.prototype.clearHeartbeat = function() {
  return this.setHeartbeat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.DroneData.prototype.hasHeartbeat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Log log = 7;
 * @return {?proto.common.Log}
 */
proto.common.DroneData.prototype.getLog = function() {
  return /** @type{?proto.common.Log} */ (
    jspb.Message.getWrapperField(this, proto.common.Log, 7));
};


/**
 * @param {?proto.common.Log|undefined} value
 * @return {!proto.common.DroneData} returns this
*/
proto.common.DroneData.prototype.setLog = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.common.DroneData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.DroneData} returns this
 */
proto.common.DroneData.prototype.clearLog = function() {
  return this.setLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.DroneData.prototype.hasLog = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FlightPlanStatus flight_plan_status = 8;
 * @return {?proto.common.FlightPlanStatus}
 */
proto.common.DroneData.prototype.getFlightPlanStatus = function() {
  return /** @type{?proto.common.FlightPlanStatus} */ (
    jspb.Message.getWrapperField(this, proto.common.FlightPlanStatus, 8));
};


/**
 * @param {?proto.common.FlightPlanStatus|undefined} value
 * @return {!proto.common.DroneData} returns this
*/
proto.common.DroneData.prototype.setFlightPlanStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.common.DroneData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.DroneData} returns this
 */
proto.common.DroneData.prototype.clearFlightPlanStatus = function() {
  return this.setFlightPlanStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.DroneData.prototype.hasFlightPlanStatus = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional MediaUpload media_upload = 9;
 * @return {?proto.common.MediaUpload}
 */
proto.common.DroneData.prototype.getMediaUpload = function() {
  return /** @type{?proto.common.MediaUpload} */ (
    jspb.Message.getWrapperField(this, proto.common.MediaUpload, 9));
};


/**
 * @param {?proto.common.MediaUpload|undefined} value
 * @return {!proto.common.DroneData} returns this
*/
proto.common.DroneData.prototype.setMediaUpload = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.common.DroneData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.DroneData} returns this
 */
proto.common.DroneData.prototype.clearMediaUpload = function() {
  return this.setMediaUpload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.DroneData.prototype.hasMediaUpload = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.ClientDataList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ClientDataList.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ClientDataList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ClientDataList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ClientDataList.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.common.ClientData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ClientDataList}
 */
proto.common.ClientDataList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ClientDataList;
  return proto.common.ClientDataList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ClientDataList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ClientDataList}
 */
proto.common.ClientDataList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.ClientData;
      reader.readMessage(value,proto.common.ClientData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ClientDataList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ClientDataList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ClientDataList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ClientDataList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.ClientData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ClientData data = 1;
 * @return {!Array<!proto.common.ClientData>}
 */
proto.common.ClientDataList.prototype.getDataList = function() {
  return /** @type{!Array<!proto.common.ClientData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.ClientData, 1));
};


/**
 * @param {!Array<!proto.common.ClientData>} value
 * @return {!proto.common.ClientDataList} returns this
*/
proto.common.ClientDataList.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.ClientData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ClientData}
 */
proto.common.ClientDataList.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.ClientData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.ClientDataList} returns this
 */
proto.common.ClientDataList.prototype.clearDataList = function() {
  return this.setDataList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.ClientData.oneofGroups_ = [[2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.common.ClientData.DataCase = {
  DATA_NOT_SET: 0,
  ERROR: 2,
  COMMAND: 3,
  FLIGHT_PLAN: 4,
  ACK: 5,
  HEARTBEAT: 6,
  MEDIA_UPLOAD_RESPONSE: 7
};

/**
 * @return {proto.common.ClientData.DataCase}
 */
proto.common.ClientData.prototype.getDataCase = function() {
  return /** @type {proto.common.ClientData.DataCase} */(jspb.Message.computeOneofCase(this, proto.common.ClientData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ClientData.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ClientData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ClientData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ClientData.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.common.ClientSession.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.common.Error.toObject(includeInstance, f),
    command: (f = msg.getCommand()) && proto.common.Command.toObject(includeInstance, f),
    flightPlan: (f = msg.getFlightPlan()) && proto.common.FlightPlan.toObject(includeInstance, f),
    ack: (f = msg.getAck()) && proto.common.Ack.toObject(includeInstance, f),
    heartbeat: (f = msg.getHeartbeat()) && proto.common.Empty.toObject(includeInstance, f),
    mediaUploadResponse: (f = msg.getMediaUploadResponse()) && proto.common.MediaUploadResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ClientData}
 */
proto.common.ClientData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ClientData;
  return proto.common.ClientData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ClientData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ClientData}
 */
proto.common.ClientData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.ClientSession;
      reader.readMessage(value,proto.common.ClientSession.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 2:
      var value = new proto.common.Error;
      reader.readMessage(value,proto.common.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.common.Command;
      reader.readMessage(value,proto.common.Command.deserializeBinaryFromReader);
      msg.setCommand(value);
      break;
    case 4:
      var value = new proto.common.FlightPlan;
      reader.readMessage(value,proto.common.FlightPlan.deserializeBinaryFromReader);
      msg.setFlightPlan(value);
      break;
    case 5:
      var value = new proto.common.Ack;
      reader.readMessage(value,proto.common.Ack.deserializeBinaryFromReader);
      msg.setAck(value);
      break;
    case 6:
      var value = new proto.common.Empty;
      reader.readMessage(value,proto.common.Empty.deserializeBinaryFromReader);
      msg.setHeartbeat(value);
      break;
    case 7:
      var value = new proto.common.MediaUploadResponse;
      reader.readMessage(value,proto.common.MediaUploadResponse.deserializeBinaryFromReader);
      msg.setMediaUploadResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ClientData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ClientData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ClientData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ClientData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.ClientSession.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Error.serializeBinaryToWriter
    );
  }
  f = message.getCommand();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Command.serializeBinaryToWriter
    );
  }
  f = message.getFlightPlan();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.FlightPlan.serializeBinaryToWriter
    );
  }
  f = message.getAck();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.Ack.serializeBinaryToWriter
    );
  }
  f = message.getHeartbeat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.Empty.serializeBinaryToWriter
    );
  }
  f = message.getMediaUploadResponse();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.common.MediaUploadResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ClientSession session = 1;
 * @return {?proto.common.ClientSession}
 */
proto.common.ClientData.prototype.getSession = function() {
  return /** @type{?proto.common.ClientSession} */ (
    jspb.Message.getWrapperField(this, proto.common.ClientSession, 1));
};


/**
 * @param {?proto.common.ClientSession|undefined} value
 * @return {!proto.common.ClientData} returns this
*/
proto.common.ClientData.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ClientData} returns this
 */
proto.common.ClientData.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ClientData.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Error error = 2;
 * @return {?proto.common.Error}
 */
proto.common.ClientData.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto.common.Error, 2));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.common.ClientData} returns this
*/
proto.common.ClientData.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.common.ClientData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ClientData} returns this
 */
proto.common.ClientData.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ClientData.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Command command = 3;
 * @return {?proto.common.Command}
 */
proto.common.ClientData.prototype.getCommand = function() {
  return /** @type{?proto.common.Command} */ (
    jspb.Message.getWrapperField(this, proto.common.Command, 3));
};


/**
 * @param {?proto.common.Command|undefined} value
 * @return {!proto.common.ClientData} returns this
*/
proto.common.ClientData.prototype.setCommand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.common.ClientData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ClientData} returns this
 */
proto.common.ClientData.prototype.clearCommand = function() {
  return this.setCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ClientData.prototype.hasCommand = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FlightPlan flight_plan = 4;
 * @return {?proto.common.FlightPlan}
 */
proto.common.ClientData.prototype.getFlightPlan = function() {
  return /** @type{?proto.common.FlightPlan} */ (
    jspb.Message.getWrapperField(this, proto.common.FlightPlan, 4));
};


/**
 * @param {?proto.common.FlightPlan|undefined} value
 * @return {!proto.common.ClientData} returns this
*/
proto.common.ClientData.prototype.setFlightPlan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.common.ClientData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ClientData} returns this
 */
proto.common.ClientData.prototype.clearFlightPlan = function() {
  return this.setFlightPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ClientData.prototype.hasFlightPlan = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Ack ack = 5;
 * @return {?proto.common.Ack}
 */
proto.common.ClientData.prototype.getAck = function() {
  return /** @type{?proto.common.Ack} */ (
    jspb.Message.getWrapperField(this, proto.common.Ack, 5));
};


/**
 * @param {?proto.common.Ack|undefined} value
 * @return {!proto.common.ClientData} returns this
*/
proto.common.ClientData.prototype.setAck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.common.ClientData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ClientData} returns this
 */
proto.common.ClientData.prototype.clearAck = function() {
  return this.setAck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ClientData.prototype.hasAck = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Empty heartbeat = 6;
 * @return {?proto.common.Empty}
 */
proto.common.ClientData.prototype.getHeartbeat = function() {
  return /** @type{?proto.common.Empty} */ (
    jspb.Message.getWrapperField(this, proto.common.Empty, 6));
};


/**
 * @param {?proto.common.Empty|undefined} value
 * @return {!proto.common.ClientData} returns this
*/
proto.common.ClientData.prototype.setHeartbeat = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.common.ClientData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ClientData} returns this
 */
proto.common.ClientData.prototype.clearHeartbeat = function() {
  return this.setHeartbeat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ClientData.prototype.hasHeartbeat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MediaUploadResponse media_upload_response = 7;
 * @return {?proto.common.MediaUploadResponse}
 */
proto.common.ClientData.prototype.getMediaUploadResponse = function() {
  return /** @type{?proto.common.MediaUploadResponse} */ (
    jspb.Message.getWrapperField(this, proto.common.MediaUploadResponse, 7));
};


/**
 * @param {?proto.common.MediaUploadResponse|undefined} value
 * @return {!proto.common.ClientData} returns this
*/
proto.common.ClientData.prototype.setMediaUploadResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.common.ClientData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ClientData} returns this
 */
proto.common.ClientData.prototype.clearMediaUploadResponse = function() {
  return this.setMediaUploadResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ClientData.prototype.hasMediaUploadResponse = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Error}
 */
proto.common.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Error;
  return proto.common.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Error}
 */
proto.common.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Error.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.Error.Code = {
  UNKNOWN: 0,
  DRONE: 100,
  DRONE_NOT_CONNECTED: 101,
  DRONE_NOT_READY: 102,
  DRONE_NOT_CALIBRATED: 103,
  DRONE_STATE_NOT_LANDED: 110,
  DRONE_STATE_NOT_HOVERING: 111,
  DRONE_STATE_NOT_FLYING: 112,
  DRONE_STATE_NOT_FLIGHT_PLAN: 113,
  DRONE_STATE_NOT_DOWNLOADING: 114,
  DRONE_STATE_NOT_IDLE: 115,
  DRONE_STATE_NOT_IN_AIR: 116,
  DRONE_NO_SENSOR_INFO: 117,
  DRONE_LOW_BATTERY: 118,
  DRONE_BAD_SIGNAL: 119,
  DRONE_BAD_GPS: 120,
  DRONE_COMMAND_BAD_PARAMETERS: 140,
  DRONE_COMMAND_FAILED_TO_EXECUTE: 141,
  DRONE_LANDING_NEED_CONFIRMATION: 142,
  DRONE_DISTANCE_TOO_FAR: 143,
  DRONE_CAMERA_NOT_FOUND: 160,
  DRONE_CAMERA_NOT_IDLE: 161,
  DRONE_CAMERA_NOT_RECORDING: 162,
  DRONE_STREAM_START_FAILED: 163,
  DRONE_FLIGHT_PLAN_PARSING_ERROR: 170,
  DRONE_FLIGHT_PLAN_IN_PROGRESS: 171,
  DRONE_FLIGHT_PLAN_FAILED: 172,
  DRONE_DOWNLOAD_ERROR: 180,
  DRONE_MEDIA_DELETE_FAILED: 181,
  DRONE_MEDIA_NOT_FOUND: 182,
  DRONE_FEATURE_NOT_IMPLEMENTED: 190,
  DRONE_FEATURE_NOT_SUPPORTED: 191,
  DRONE_UNEXPECTED_ERROR: 192,
  CLIENT: 200,
  BAD_REQUEST: 201,
  UNKNOWN_REQUEST: 202
};

/**
 * optional Code code = 1;
 * @return {!proto.common.Error.Code}
 */
proto.common.Error.prototype.getCode = function() {
  return /** @type {!proto.common.Error.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Error.Code} value
 * @return {!proto.common.Error} returns this
 */
proto.common.Error.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.common.Error.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Error} returns this
 */
proto.common.Error.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.MediaUpload.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.common.MediaUpload.DataCase = {
  DATA_NOT_SET: 0,
  REQUEST: 1,
  RESULT: 2
};

/**
 * @return {proto.common.MediaUpload.DataCase}
 */
proto.common.MediaUpload.prototype.getDataCase = function() {
  return /** @type {proto.common.MediaUpload.DataCase} */(jspb.Message.computeOneofCase(this, proto.common.MediaUpload.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MediaUpload.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MediaUpload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MediaUpload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MediaUpload.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.common.MediaUpload.Request.toObject(includeInstance, f),
    result: (f = msg.getResult()) && proto.common.MediaUpload.Result.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MediaUpload}
 */
proto.common.MediaUpload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MediaUpload;
  return proto.common.MediaUpload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MediaUpload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MediaUpload}
 */
proto.common.MediaUpload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.MediaUpload.Request;
      reader.readMessage(value,proto.common.MediaUpload.Request.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = new proto.common.MediaUpload.Result;
      reader.readMessage(value,proto.common.MediaUpload.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MediaUpload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MediaUpload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MediaUpload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MediaUpload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.MediaUpload.Request.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.MediaUpload.Result.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.MediaUpload.Request.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.common.MediaUpload.Request.ContentTypeCase = {
  CONTENT_TYPE_NOT_SET: 0,
  PHOTO_FORMAT: 2,
  VIDEO_FORMAT: 3
};

/**
 * @return {proto.common.MediaUpload.Request.ContentTypeCase}
 */
proto.common.MediaUpload.Request.prototype.getContentTypeCase = function() {
  return /** @type {proto.common.MediaUpload.Request.ContentTypeCase} */(jspb.Message.computeOneofCase(this, proto.common.MediaUpload.Request.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MediaUpload.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MediaUpload.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MediaUpload.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MediaUpload.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    size: jspb.Message.getFieldWithDefault(msg, 1, 0),
    photoFormat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    videoFormat: jspb.Message.getFieldWithDefault(msg, 3, 0),
    uploadToClient: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    groupId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    downloadIndex: jspb.Message.getFieldWithDefault(msg, 6, 0),
    downloadCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    flightId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    flightName: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MediaUpload.Request}
 */
proto.common.MediaUpload.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MediaUpload.Request;
  return proto.common.MediaUpload.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MediaUpload.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MediaUpload.Request}
 */
proto.common.MediaUpload.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CameraSettings.PhotoFormat} */ (reader.readEnum());
      msg.setPhotoFormat(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CameraSettings.VideoFormat} */ (reader.readEnum());
      msg.setVideoFormat(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUploadToClient(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDownloadIndex(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDownloadCount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MediaUpload.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MediaUpload.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MediaUpload.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MediaUpload.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {!proto.common.CameraSettings.PhotoFormat} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.common.CameraSettings.VideoFormat} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUploadToClient();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDownloadIndex();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getDownloadCount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFlightName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 size = 1;
 * @return {number}
 */
proto.common.MediaUpload.Request.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CameraSettings.PhotoFormat photo_format = 2;
 * @return {!proto.common.CameraSettings.PhotoFormat}
 */
proto.common.MediaUpload.Request.prototype.getPhotoFormat = function() {
  return /** @type {!proto.common.CameraSettings.PhotoFormat} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CameraSettings.PhotoFormat} value
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.setPhotoFormat = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.common.MediaUpload.Request.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.clearPhotoFormat = function() {
  return jspb.Message.setOneofField(this, 2, proto.common.MediaUpload.Request.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MediaUpload.Request.prototype.hasPhotoFormat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CameraSettings.VideoFormat video_format = 3;
 * @return {!proto.common.CameraSettings.VideoFormat}
 */
proto.common.MediaUpload.Request.prototype.getVideoFormat = function() {
  return /** @type {!proto.common.CameraSettings.VideoFormat} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CameraSettings.VideoFormat} value
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.setVideoFormat = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.common.MediaUpload.Request.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.clearVideoFormat = function() {
  return jspb.Message.setOneofField(this, 3, proto.common.MediaUpload.Request.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MediaUpload.Request.prototype.hasVideoFormat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool upload_to_client = 4;
 * @return {boolean}
 */
proto.common.MediaUpload.Request.prototype.getUploadToClient = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.setUploadToClient = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string group_id = 5;
 * @return {string}
 */
proto.common.MediaUpload.Request.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 download_index = 6;
 * @return {number}
 */
proto.common.MediaUpload.Request.prototype.getDownloadIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.setDownloadIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 download_count = 7;
 * @return {number}
 */
proto.common.MediaUpload.Request.prototype.getDownloadCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.setDownloadCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string flight_id = 8;
 * @return {string}
 */
proto.common.MediaUpload.Request.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string flight_name = 9;
 * @return {string}
 */
proto.common.MediaUpload.Request.prototype.getFlightName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.MediaUpload.Request} returns this
 */
proto.common.MediaUpload.Request.prototype.setFlightName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MediaUpload.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MediaUpload.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MediaUpload.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MediaUpload.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resultCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MediaUpload.Result}
 */
proto.common.MediaUpload.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MediaUpload.Result;
  return proto.common.MediaUpload.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MediaUpload.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MediaUpload.Result}
 */
proto.common.MediaUpload.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.MediaUpload.Result.ResultCode} */ (reader.readEnum());
      msg.setResultCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MediaUpload.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MediaUpload.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MediaUpload.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MediaUpload.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResultCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.MediaUpload.Result.ResultCode = {
  SUCCESS: 0,
  ERROR_GENERIC: 1,
  ERROR_DOWNLOAD: 2,
  ERROR_UPLOAD: 3
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.MediaUpload.Result.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.MediaUpload.Result} returns this
 */
proto.common.MediaUpload.Result.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ResultCode result_code = 2;
 * @return {!proto.common.MediaUpload.Result.ResultCode}
 */
proto.common.MediaUpload.Result.prototype.getResultCode = function() {
  return /** @type {!proto.common.MediaUpload.Result.ResultCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.MediaUpload.Result.ResultCode} value
 * @return {!proto.common.MediaUpload.Result} returns this
 */
proto.common.MediaUpload.Result.prototype.setResultCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.common.MediaUpload.Result.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.MediaUpload.Result} returns this
 */
proto.common.MediaUpload.Result.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Request request = 1;
 * @return {?proto.common.MediaUpload.Request}
 */
proto.common.MediaUpload.prototype.getRequest = function() {
  return /** @type{?proto.common.MediaUpload.Request} */ (
    jspb.Message.getWrapperField(this, proto.common.MediaUpload.Request, 1));
};


/**
 * @param {?proto.common.MediaUpload.Request|undefined} value
 * @return {!proto.common.MediaUpload} returns this
*/
proto.common.MediaUpload.prototype.setRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.common.MediaUpload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MediaUpload} returns this
 */
proto.common.MediaUpload.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MediaUpload.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Result result = 2;
 * @return {?proto.common.MediaUpload.Result}
 */
proto.common.MediaUpload.prototype.getResult = function() {
  return /** @type{?proto.common.MediaUpload.Result} */ (
    jspb.Message.getWrapperField(this, proto.common.MediaUpload.Result, 2));
};


/**
 * @param {?proto.common.MediaUpload.Result|undefined} value
 * @return {!proto.common.MediaUpload} returns this
*/
proto.common.MediaUpload.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.common.MediaUpload.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MediaUpload} returns this
 */
proto.common.MediaUpload.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MediaUpload.prototype.hasResult = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MediaUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MediaUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MediaUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MediaUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MediaUploadResponse}
 */
proto.common.MediaUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MediaUploadResponse;
  return proto.common.MediaUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MediaUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MediaUploadResponse}
 */
proto.common.MediaUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MediaUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MediaUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MediaUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MediaUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.MediaUploadResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.MediaUploadResponse} returns this
 */
proto.common.MediaUploadResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.common.MediaUploadResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.MediaUploadResponse} returns this
 */
proto.common.MediaUploadResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Media.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Media.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Media} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Media.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64(),
    format: jspb.Message.getFieldWithDefault(msg, 2, ""),
    persist: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    size: jspb.Message.getFieldWithDefault(msg, 4, 0),
    partNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalParts: jspb.Message.getFieldWithDefault(msg, 6, 0),
    contentType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    id: jspb.Message.getFieldWithDefault(msg, 8, 0),
    abort: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Media}
 */
proto.common.Media.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Media;
  return proto.common.Media.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Media} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Media}
 */
proto.common.Media.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersist(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPartNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalParts(value);
      break;
    case 7:
      var value = /** @type {!proto.common.Media.ContentType} */ (reader.readEnum());
      msg.setContentType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAbort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Media.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Media.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Media} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Media.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersist();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPartNumber();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTotalParts();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getContentType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAbort();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.Media.ContentType = {
  UNKNOWN: 0,
  VIDEO_MP4: 10,
  VIDEO_RAW: 11,
  PHOTO_JPEG: 20,
  PHOTO_PNG: 21,
  PHOTO_RAW: 22
};

/**
 * optional bytes data = 1;
 * @return {!(string|Uint8Array)}
 */
proto.common.Media.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes data = 1;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.common.Media.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.common.Media.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.common.Media} returns this
 */
proto.common.Media.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string format = 2;
 * @return {string}
 */
proto.common.Media.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Media} returns this
 */
proto.common.Media.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool persist = 3;
 * @return {boolean}
 */
proto.common.Media.prototype.getPersist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Media} returns this
 */
proto.common.Media.prototype.setPersist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int64 size = 4;
 * @return {number}
 */
proto.common.Media.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Media} returns this
 */
proto.common.Media.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 part_number = 5;
 * @return {number}
 */
proto.common.Media.prototype.getPartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Media} returns this
 */
proto.common.Media.prototype.setPartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 total_parts = 6;
 * @return {number}
 */
proto.common.Media.prototype.getTotalParts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Media} returns this
 */
proto.common.Media.prototype.setTotalParts = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional ContentType content_type = 7;
 * @return {!proto.common.Media.ContentType}
 */
proto.common.Media.prototype.getContentType = function() {
  return /** @type {!proto.common.Media.ContentType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.Media.ContentType} value
 * @return {!proto.common.Media} returns this
 */
proto.common.Media.prototype.setContentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 id = 8;
 * @return {number}
 */
proto.common.Media.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Media} returns this
 */
proto.common.Media.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool abort = 9;
 * @return {boolean}
 */
proto.common.Media.prototype.getAbort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Media} returns this
 */
proto.common.Media.prototype.setAbort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Metrics.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Metrics.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Metrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Metrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Metrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    battery: jspb.Message.getFieldWithDefault(msg, 2, 0),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    position: (f = msg.getPosition()) && proto.common.Coordinates.toObject(includeInstance, f),
    gpsLevel: jspb.Message.getFieldWithDefault(msg, 5, 0),
    uplinkLevel: jspb.Message.getFieldWithDefault(msg, 6, 0),
    downlinkLevel: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gimbalAttitude: (f = msg.getGimbalAttitude()) && proto.common.Attitude.toObject(includeInstance, f),
    flightPlanId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    thermalGimbalAttitude: (f = msg.getThermalGimbalAttitude()) && proto.common.Attitude.toObject(includeInstance, f),
    cameraState: jspb.Message.getFieldWithDefault(msg, 11, 0),
    thermalCameraState: jspb.Message.getFieldWithDefault(msg, 12, 0),
    homePosition: (f = msg.getHomePosition()) && proto.common.Coordinates.toObject(includeInstance, f),
    droneType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    clientType: jspb.Message.getFieldWithDefault(msg, 15, ""),
    capabilitiesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    velocity: (f = msg.getVelocity()) && proto.common.Velocity.toObject(includeInstance, f),
    controllerPosition: (f = msg.getControllerPosition()) && proto.common.Coordinates.toObject(includeInstance, f),
    controllerBattery: jspb.Message.getFieldWithDefault(msg, 19, 0),
    liveStreamState: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Metrics}
 */
proto.common.Metrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Metrics;
  return proto.common.Metrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Metrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Metrics}
 */
proto.common.Metrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBattery(value);
      break;
    case 3:
      var value = /** @type {!proto.common.Metrics.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = new proto.common.Coordinates;
      reader.readMessage(value,proto.common.Coordinates.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 5:
      var value = /** @type {!proto.common.Metrics.GPSLevel} */ (reader.readEnum());
      msg.setGpsLevel(value);
      break;
    case 6:
      var value = /** @type {!proto.common.Metrics.SignalLevel} */ (reader.readEnum());
      msg.setUplinkLevel(value);
      break;
    case 7:
      var value = /** @type {!proto.common.Metrics.SignalLevel} */ (reader.readEnum());
      msg.setDownlinkLevel(value);
      break;
    case 8:
      var value = new proto.common.Attitude;
      reader.readMessage(value,proto.common.Attitude.deserializeBinaryFromReader);
      msg.setGimbalAttitude(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightPlanId(value);
      break;
    case 10:
      var value = new proto.common.Attitude;
      reader.readMessage(value,proto.common.Attitude.deserializeBinaryFromReader);
      msg.setThermalGimbalAttitude(value);
      break;
    case 11:
      var value = /** @type {!proto.common.Metrics.CameraState} */ (reader.readEnum());
      msg.setCameraState(value);
      break;
    case 12:
      var value = /** @type {!proto.common.Metrics.CameraState} */ (reader.readEnum());
      msg.setThermalCameraState(value);
      break;
    case 13:
      var value = new proto.common.Coordinates;
      reader.readMessage(value,proto.common.Coordinates.deserializeBinaryFromReader);
      msg.setHomePosition(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDroneType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientType(value);
      break;
    case 16:
      var values = /** @type {!Array<!proto.common.Metrics.Capability>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCapabilities(values[i]);
      }
      break;
    case 17:
      var value = new proto.common.Velocity;
      reader.readMessage(value,proto.common.Velocity.deserializeBinaryFromReader);
      msg.setVelocity(value);
      break;
    case 18:
      var value = new proto.common.Coordinates;
      reader.readMessage(value,proto.common.Coordinates.deserializeBinaryFromReader);
      msg.setControllerPosition(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerBattery(value);
      break;
    case 20:
      var value = /** @type {!proto.common.Metrics.LiveStreamState} */ (reader.readEnum());
      msg.setLiveStreamState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Metrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Metrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Metrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Metrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBattery();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getGpsLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getUplinkLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDownlinkLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getGimbalAttitude();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.common.Attitude.serializeBinaryToWriter
    );
  }
  f = message.getFlightPlanId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getThermalGimbalAttitude();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.common.Attitude.serializeBinaryToWriter
    );
  }
  f = message.getCameraState();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getThermalCameraState();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getHomePosition();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.common.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getDroneType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getClientType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCapabilitiesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      16,
      f
    );
  }
  f = message.getVelocity();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.common.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getControllerPosition();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.common.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getControllerBattery();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getLiveStreamState();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.Metrics.State = {
  UNKNOWN: 0,
  LANDED: 1,
  HOVERING: 2,
  FLYING: 3,
  FLIGHT_PLAN: 4,
  DOWNLOADING: 5,
  OTHER: 6
};

/**
 * @enum {number}
 */
proto.common.Metrics.GPSLevel = {
  GPS_UNKNOWN: 0,
  BAD: 1,
  OK: 2,
  GOOD: 3
};

/**
 * @enum {number}
 */
proto.common.Metrics.SignalLevel = {
  SIGNAL_UNKNOWN: 0,
  SIGNAL_BAD: 1,
  SIGNAL_OK: 2,
  SIGNAL_GOOD: 3
};

/**
 * @enum {number}
 */
proto.common.Metrics.CameraState = {
  CAMERA_UNKNOWN: 0,
  CAMERA_IDLE: 1,
  CAMERA_PHOTO: 2,
  CAMERA_VIDEO: 3,
  CAMERA_DOWNLOAD: 4
};

/**
 * @enum {number}
 */
proto.common.Metrics.Capability = {
  THERMAL_CAMERA: 0,
  RESOLUTION_1440P: 1,
  RESOLUTION_2160P: 2
};

/**
 * @enum {number}
 */
proto.common.Metrics.LiveStreamState = {
  STREAM_UNKNOWN: 0,
  STREAM_NOT_CONNECTED: 1,
  STREAM_INITIALIZING: 2,
  STREAM_INITIALIZED: 3,
  STREAM_CONNECTION_STARTED: 4,
  STREAM_AUTHENTICATED: 5,
  STREAM_CONNECTED: 6,
  STREAM_DISCONNECTED: 7,
  STREAM_AUTHENTICATION_ERROR: 8,
  STREAM_CONNECTION_ERROR: 9,
  STREAM_START_ERROR: 10,
  STREAM_INITIALIZATION_ERROR: 11,
  STREAM_RESTARTING: 12
};

/**
 * optional uint64 timestamp = 1;
 * @return {number}
 */
proto.common.Metrics.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 battery = 2;
 * @return {number}
 */
proto.common.Metrics.prototype.getBattery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setBattery = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional State state = 3;
 * @return {!proto.common.Metrics.State}
 */
proto.common.Metrics.prototype.getState = function() {
  return /** @type {!proto.common.Metrics.State} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.Metrics.State} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Coordinates position = 4;
 * @return {?proto.common.Coordinates}
 */
proto.common.Metrics.prototype.getPosition = function() {
  return /** @type{?proto.common.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.common.Coordinates, 4));
};


/**
 * @param {?proto.common.Coordinates|undefined} value
 * @return {!proto.common.Metrics} returns this
*/
proto.common.Metrics.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Metrics.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GPSLevel gps_level = 5;
 * @return {!proto.common.Metrics.GPSLevel}
 */
proto.common.Metrics.prototype.getGpsLevel = function() {
  return /** @type {!proto.common.Metrics.GPSLevel} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.Metrics.GPSLevel} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setGpsLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional SignalLevel uplink_level = 6;
 * @return {!proto.common.Metrics.SignalLevel}
 */
proto.common.Metrics.prototype.getUplinkLevel = function() {
  return /** @type {!proto.common.Metrics.SignalLevel} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.Metrics.SignalLevel} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setUplinkLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional SignalLevel downlink_level = 7;
 * @return {!proto.common.Metrics.SignalLevel}
 */
proto.common.Metrics.prototype.getDownlinkLevel = function() {
  return /** @type {!proto.common.Metrics.SignalLevel} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.Metrics.SignalLevel} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setDownlinkLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Attitude gimbal_attitude = 8;
 * @return {?proto.common.Attitude}
 */
proto.common.Metrics.prototype.getGimbalAttitude = function() {
  return /** @type{?proto.common.Attitude} */ (
    jspb.Message.getWrapperField(this, proto.common.Attitude, 8));
};


/**
 * @param {?proto.common.Attitude|undefined} value
 * @return {!proto.common.Metrics} returns this
*/
proto.common.Metrics.prototype.setGimbalAttitude = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.clearGimbalAttitude = function() {
  return this.setGimbalAttitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Metrics.prototype.hasGimbalAttitude = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string flight_plan_id = 9;
 * @return {string}
 */
proto.common.Metrics.prototype.getFlightPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setFlightPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional Attitude thermal_gimbal_attitude = 10;
 * @return {?proto.common.Attitude}
 */
proto.common.Metrics.prototype.getThermalGimbalAttitude = function() {
  return /** @type{?proto.common.Attitude} */ (
    jspb.Message.getWrapperField(this, proto.common.Attitude, 10));
};


/**
 * @param {?proto.common.Attitude|undefined} value
 * @return {!proto.common.Metrics} returns this
*/
proto.common.Metrics.prototype.setThermalGimbalAttitude = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.clearThermalGimbalAttitude = function() {
  return this.setThermalGimbalAttitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Metrics.prototype.hasThermalGimbalAttitude = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional CameraState camera_state = 11;
 * @return {!proto.common.Metrics.CameraState}
 */
proto.common.Metrics.prototype.getCameraState = function() {
  return /** @type {!proto.common.Metrics.CameraState} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.common.Metrics.CameraState} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setCameraState = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional CameraState thermal_camera_state = 12;
 * @return {!proto.common.Metrics.CameraState}
 */
proto.common.Metrics.prototype.getThermalCameraState = function() {
  return /** @type {!proto.common.Metrics.CameraState} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.common.Metrics.CameraState} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setThermalCameraState = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional Coordinates home_position = 13;
 * @return {?proto.common.Coordinates}
 */
proto.common.Metrics.prototype.getHomePosition = function() {
  return /** @type{?proto.common.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.common.Coordinates, 13));
};


/**
 * @param {?proto.common.Coordinates|undefined} value
 * @return {!proto.common.Metrics} returns this
*/
proto.common.Metrics.prototype.setHomePosition = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.clearHomePosition = function() {
  return this.setHomePosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Metrics.prototype.hasHomePosition = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string drone_type = 14;
 * @return {string}
 */
proto.common.Metrics.prototype.getDroneType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setDroneType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string client_type = 15;
 * @return {string}
 */
proto.common.Metrics.prototype.getClientType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setClientType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated Capability capabilities = 16;
 * @return {!Array<!proto.common.Metrics.Capability>}
 */
proto.common.Metrics.prototype.getCapabilitiesList = function() {
  return /** @type {!Array<!proto.common.Metrics.Capability>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<!proto.common.Metrics.Capability>} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setCapabilitiesList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {!proto.common.Metrics.Capability} value
 * @param {number=} opt_index
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.addCapabilities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.clearCapabilitiesList = function() {
  return this.setCapabilitiesList([]);
};


/**
 * optional Velocity velocity = 17;
 * @return {?proto.common.Velocity}
 */
proto.common.Metrics.prototype.getVelocity = function() {
  return /** @type{?proto.common.Velocity} */ (
    jspb.Message.getWrapperField(this, proto.common.Velocity, 17));
};


/**
 * @param {?proto.common.Velocity|undefined} value
 * @return {!proto.common.Metrics} returns this
*/
proto.common.Metrics.prototype.setVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.clearVelocity = function() {
  return this.setVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Metrics.prototype.hasVelocity = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Coordinates controller_position = 18;
 * @return {?proto.common.Coordinates}
 */
proto.common.Metrics.prototype.getControllerPosition = function() {
  return /** @type{?proto.common.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.common.Coordinates, 18));
};


/**
 * @param {?proto.common.Coordinates|undefined} value
 * @return {!proto.common.Metrics} returns this
*/
proto.common.Metrics.prototype.setControllerPosition = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.clearControllerPosition = function() {
  return this.setControllerPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Metrics.prototype.hasControllerPosition = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional uint32 controller_battery = 19;
 * @return {number}
 */
proto.common.Metrics.prototype.getControllerBattery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setControllerBattery = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional LiveStreamState live_stream_state = 20;
 * @return {!proto.common.Metrics.LiveStreamState}
 */
proto.common.Metrics.prototype.getLiveStreamState = function() {
  return /** @type {!proto.common.Metrics.LiveStreamState} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.common.Metrics.LiveStreamState} value
 * @return {!proto.common.Metrics} returns this
 */
proto.common.Metrics.prototype.setLiveStreamState = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.Command.oneofGroups_ = [[3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22]];

/**
 * @enum {number}
 */
proto.common.Command.DataCase = {
  DATA_NOT_SET: 0,
  EMPTY: 3,
  RETURN_HOME: 4,
  HOVER: 5,
  MOVE_SPEED: 6,
  MOVE_GPS: 7,
  CIRCLE: 8,
  ROTATE: 9,
  TILT_CAMERA: 10,
  LAND: 11,
  STOP: 12,
  START_STREAM: 13,
  STOP_STREAM: 14,
  TAKE_PHOTO: 15,
  START_RECORDING: 16,
  STOP_RECORDING: 17,
  MANUAL_CONTROL: 18,
  MOVE_ALTITUDE: 19,
  DOWNLOAD_MEDIA: 20,
  STOP_CAMERA: 21,
  TAKEOFF: 22
};

/**
 * @return {proto.common.Command.DataCase}
 */
proto.common.Command.prototype.getDataCase = function() {
  return /** @type {proto.common.Command.DataCase} */(jspb.Message.computeOneofCase(this, proto.common.Command.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Command.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Command.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Command} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Command.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    blocking: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    empty: (f = msg.getEmpty()) && proto.common.Empty.toObject(includeInstance, f),
    returnHome: (f = msg.getReturnHome()) && proto.common.ReturnHome.toObject(includeInstance, f),
    hover: (f = msg.getHover()) && proto.common.Hover.toObject(includeInstance, f),
    moveSpeed: (f = msg.getMoveSpeed()) && proto.common.MoveSpeed.toObject(includeInstance, f),
    moveGps: (f = msg.getMoveGps()) && proto.common.MoveGPS.toObject(includeInstance, f),
    circle: (f = msg.getCircle()) && proto.common.Circle.toObject(includeInstance, f),
    rotate: (f = msg.getRotate()) && proto.common.Rotate.toObject(includeInstance, f),
    tiltCamera: (f = msg.getTiltCamera()) && proto.common.TiltCamera.toObject(includeInstance, f),
    land: (f = msg.getLand()) && proto.common.Land.toObject(includeInstance, f),
    stop: (f = msg.getStop()) && proto.common.Stop.toObject(includeInstance, f),
    startStream: (f = msg.getStartStream()) && proto.common.StartStream.toObject(includeInstance, f),
    stopStream: (f = msg.getStopStream()) && proto.common.StopStream.toObject(includeInstance, f),
    takePhoto: (f = msg.getTakePhoto()) && proto.common.TakePhoto.toObject(includeInstance, f),
    startRecording: (f = msg.getStartRecording()) && proto.common.StartRecording.toObject(includeInstance, f),
    stopRecording: (f = msg.getStopRecording()) && proto.common.StopRecording.toObject(includeInstance, f),
    manualControl: (f = msg.getManualControl()) && proto.common.ManualControl.toObject(includeInstance, f),
    moveAltitude: (f = msg.getMoveAltitude()) && proto.common.MoveAltitude.toObject(includeInstance, f),
    downloadMedia: (f = msg.getDownloadMedia()) && proto.common.DownloadMedia.toObject(includeInstance, f),
    stopCamera: (f = msg.getStopCamera()) && proto.common.StopCamera.toObject(includeInstance, f),
    takeoff: (f = msg.getTakeoff()) && proto.common.Takeoff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Command}
 */
proto.common.Command.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Command;
  return proto.common.Command.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Command} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Command}
 */
proto.common.Command.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Command.CommandCode} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlocking(value);
      break;
    case 3:
      var value = new proto.common.Empty;
      reader.readMessage(value,proto.common.Empty.deserializeBinaryFromReader);
      msg.setEmpty(value);
      break;
    case 4:
      var value = new proto.common.ReturnHome;
      reader.readMessage(value,proto.common.ReturnHome.deserializeBinaryFromReader);
      msg.setReturnHome(value);
      break;
    case 5:
      var value = new proto.common.Hover;
      reader.readMessage(value,proto.common.Hover.deserializeBinaryFromReader);
      msg.setHover(value);
      break;
    case 6:
      var value = new proto.common.MoveSpeed;
      reader.readMessage(value,proto.common.MoveSpeed.deserializeBinaryFromReader);
      msg.setMoveSpeed(value);
      break;
    case 7:
      var value = new proto.common.MoveGPS;
      reader.readMessage(value,proto.common.MoveGPS.deserializeBinaryFromReader);
      msg.setMoveGps(value);
      break;
    case 8:
      var value = new proto.common.Circle;
      reader.readMessage(value,proto.common.Circle.deserializeBinaryFromReader);
      msg.setCircle(value);
      break;
    case 9:
      var value = new proto.common.Rotate;
      reader.readMessage(value,proto.common.Rotate.deserializeBinaryFromReader);
      msg.setRotate(value);
      break;
    case 10:
      var value = new proto.common.TiltCamera;
      reader.readMessage(value,proto.common.TiltCamera.deserializeBinaryFromReader);
      msg.setTiltCamera(value);
      break;
    case 11:
      var value = new proto.common.Land;
      reader.readMessage(value,proto.common.Land.deserializeBinaryFromReader);
      msg.setLand(value);
      break;
    case 12:
      var value = new proto.common.Stop;
      reader.readMessage(value,proto.common.Stop.deserializeBinaryFromReader);
      msg.setStop(value);
      break;
    case 13:
      var value = new proto.common.StartStream;
      reader.readMessage(value,proto.common.StartStream.deserializeBinaryFromReader);
      msg.setStartStream(value);
      break;
    case 14:
      var value = new proto.common.StopStream;
      reader.readMessage(value,proto.common.StopStream.deserializeBinaryFromReader);
      msg.setStopStream(value);
      break;
    case 15:
      var value = new proto.common.TakePhoto;
      reader.readMessage(value,proto.common.TakePhoto.deserializeBinaryFromReader);
      msg.setTakePhoto(value);
      break;
    case 16:
      var value = new proto.common.StartRecording;
      reader.readMessage(value,proto.common.StartRecording.deserializeBinaryFromReader);
      msg.setStartRecording(value);
      break;
    case 17:
      var value = new proto.common.StopRecording;
      reader.readMessage(value,proto.common.StopRecording.deserializeBinaryFromReader);
      msg.setStopRecording(value);
      break;
    case 18:
      var value = new proto.common.ManualControl;
      reader.readMessage(value,proto.common.ManualControl.deserializeBinaryFromReader);
      msg.setManualControl(value);
      break;
    case 19:
      var value = new proto.common.MoveAltitude;
      reader.readMessage(value,proto.common.MoveAltitude.deserializeBinaryFromReader);
      msg.setMoveAltitude(value);
      break;
    case 20:
      var value = new proto.common.DownloadMedia;
      reader.readMessage(value,proto.common.DownloadMedia.deserializeBinaryFromReader);
      msg.setDownloadMedia(value);
      break;
    case 21:
      var value = new proto.common.StopCamera;
      reader.readMessage(value,proto.common.StopCamera.deserializeBinaryFromReader);
      msg.setStopCamera(value);
      break;
    case 22:
      var value = new proto.common.Takeoff;
      reader.readMessage(value,proto.common.Takeoff.deserializeBinaryFromReader);
      msg.setTakeoff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Command.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Command.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Command} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Command.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBlocking();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getEmpty();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Empty.serializeBinaryToWriter
    );
  }
  f = message.getReturnHome();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.ReturnHome.serializeBinaryToWriter
    );
  }
  f = message.getHover();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.Hover.serializeBinaryToWriter
    );
  }
  f = message.getMoveSpeed();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.MoveSpeed.serializeBinaryToWriter
    );
  }
  f = message.getMoveGps();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.common.MoveGPS.serializeBinaryToWriter
    );
  }
  f = message.getCircle();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.common.Circle.serializeBinaryToWriter
    );
  }
  f = message.getRotate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.common.Rotate.serializeBinaryToWriter
    );
  }
  f = message.getTiltCamera();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.common.TiltCamera.serializeBinaryToWriter
    );
  }
  f = message.getLand();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.common.Land.serializeBinaryToWriter
    );
  }
  f = message.getStop();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.common.Stop.serializeBinaryToWriter
    );
  }
  f = message.getStartStream();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.common.StartStream.serializeBinaryToWriter
    );
  }
  f = message.getStopStream();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.common.StopStream.serializeBinaryToWriter
    );
  }
  f = message.getTakePhoto();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.common.TakePhoto.serializeBinaryToWriter
    );
  }
  f = message.getStartRecording();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.common.StartRecording.serializeBinaryToWriter
    );
  }
  f = message.getStopRecording();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.common.StopRecording.serializeBinaryToWriter
    );
  }
  f = message.getManualControl();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.common.ManualControl.serializeBinaryToWriter
    );
  }
  f = message.getMoveAltitude();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.common.MoveAltitude.serializeBinaryToWriter
    );
  }
  f = message.getDownloadMedia();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.common.DownloadMedia.serializeBinaryToWriter
    );
  }
  f = message.getStopCamera();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.common.StopCamera.serializeBinaryToWriter
    );
  }
  f = message.getTakeoff();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.common.Takeoff.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.common.Command.CommandCode = {
  NONE: 0,
  TAKEOFF: 1,
  LAND: 2,
  RETURN_HOME: 3,
  HOVER: 4,
  MOVE_SPEED: 20,
  MOVE_GPS: 21,
  STOP_MOVEMENT: 22,
  STOP_FLIGHT_PLAN: 23,
  CIRCLE: 24,
  ROTATE: 25,
  MOVE_ALTITUDE: 26,
  START_STREAM: 30,
  STOP_STREAM: 31,
  TAKE_PHOTO: 32,
  START_RECORDING: 33,
  STOP_RECORDING: 34,
  TILT_CAMERA: 35,
  STOP_CAMERA: 36,
  DELETE_ALL_MEDIA: 50,
  REBOOT_DRONE: 51,
  CALIBRATE_COMPASS: 52,
  MANUAL_CONTROL: 53,
  DOWNLOAD_MEDIA: 54,
  STOP_ALL: 55
};

/**
 * optional CommandCode id = 1;
 * @return {!proto.common.Command.CommandCode}
 */
proto.common.Command.prototype.getId = function() {
  return /** @type {!proto.common.Command.CommandCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Command.CommandCode} value
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool blocking = 2;
 * @return {boolean}
 */
proto.common.Command.prototype.getBlocking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.setBlocking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional Empty empty = 3;
 * @return {?proto.common.Empty}
 */
proto.common.Command.prototype.getEmpty = function() {
  return /** @type{?proto.common.Empty} */ (
    jspb.Message.getWrapperField(this, proto.common.Empty, 3));
};


/**
 * @param {?proto.common.Empty|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setEmpty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearEmpty = function() {
  return this.setEmpty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasEmpty = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ReturnHome return_home = 4;
 * @return {?proto.common.ReturnHome}
 */
proto.common.Command.prototype.getReturnHome = function() {
  return /** @type{?proto.common.ReturnHome} */ (
    jspb.Message.getWrapperField(this, proto.common.ReturnHome, 4));
};


/**
 * @param {?proto.common.ReturnHome|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setReturnHome = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearReturnHome = function() {
  return this.setReturnHome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasReturnHome = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Hover hover = 5;
 * @return {?proto.common.Hover}
 */
proto.common.Command.prototype.getHover = function() {
  return /** @type{?proto.common.Hover} */ (
    jspb.Message.getWrapperField(this, proto.common.Hover, 5));
};


/**
 * @param {?proto.common.Hover|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setHover = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearHover = function() {
  return this.setHover(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasHover = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MoveSpeed move_speed = 6;
 * @return {?proto.common.MoveSpeed}
 */
proto.common.Command.prototype.getMoveSpeed = function() {
  return /** @type{?proto.common.MoveSpeed} */ (
    jspb.Message.getWrapperField(this, proto.common.MoveSpeed, 6));
};


/**
 * @param {?proto.common.MoveSpeed|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setMoveSpeed = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearMoveSpeed = function() {
  return this.setMoveSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasMoveSpeed = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MoveGPS move_gps = 7;
 * @return {?proto.common.MoveGPS}
 */
proto.common.Command.prototype.getMoveGps = function() {
  return /** @type{?proto.common.MoveGPS} */ (
    jspb.Message.getWrapperField(this, proto.common.MoveGPS, 7));
};


/**
 * @param {?proto.common.MoveGPS|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setMoveGps = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearMoveGps = function() {
  return this.setMoveGps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasMoveGps = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Circle circle = 8;
 * @return {?proto.common.Circle}
 */
proto.common.Command.prototype.getCircle = function() {
  return /** @type{?proto.common.Circle} */ (
    jspb.Message.getWrapperField(this, proto.common.Circle, 8));
};


/**
 * @param {?proto.common.Circle|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setCircle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearCircle = function() {
  return this.setCircle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasCircle = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Rotate rotate = 9;
 * @return {?proto.common.Rotate}
 */
proto.common.Command.prototype.getRotate = function() {
  return /** @type{?proto.common.Rotate} */ (
    jspb.Message.getWrapperField(this, proto.common.Rotate, 9));
};


/**
 * @param {?proto.common.Rotate|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setRotate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearRotate = function() {
  return this.setRotate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasRotate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional TiltCamera tilt_camera = 10;
 * @return {?proto.common.TiltCamera}
 */
proto.common.Command.prototype.getTiltCamera = function() {
  return /** @type{?proto.common.TiltCamera} */ (
    jspb.Message.getWrapperField(this, proto.common.TiltCamera, 10));
};


/**
 * @param {?proto.common.TiltCamera|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setTiltCamera = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearTiltCamera = function() {
  return this.setTiltCamera(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasTiltCamera = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Land land = 11;
 * @return {?proto.common.Land}
 */
proto.common.Command.prototype.getLand = function() {
  return /** @type{?proto.common.Land} */ (
    jspb.Message.getWrapperField(this, proto.common.Land, 11));
};


/**
 * @param {?proto.common.Land|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setLand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearLand = function() {
  return this.setLand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasLand = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Stop stop = 12;
 * @return {?proto.common.Stop}
 */
proto.common.Command.prototype.getStop = function() {
  return /** @type{?proto.common.Stop} */ (
    jspb.Message.getWrapperField(this, proto.common.Stop, 12));
};


/**
 * @param {?proto.common.Stop|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setStop = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearStop = function() {
  return this.setStop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasStop = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional StartStream start_stream = 13;
 * @return {?proto.common.StartStream}
 */
proto.common.Command.prototype.getStartStream = function() {
  return /** @type{?proto.common.StartStream} */ (
    jspb.Message.getWrapperField(this, proto.common.StartStream, 13));
};


/**
 * @param {?proto.common.StartStream|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setStartStream = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearStartStream = function() {
  return this.setStartStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasStartStream = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional StopStream stop_stream = 14;
 * @return {?proto.common.StopStream}
 */
proto.common.Command.prototype.getStopStream = function() {
  return /** @type{?proto.common.StopStream} */ (
    jspb.Message.getWrapperField(this, proto.common.StopStream, 14));
};


/**
 * @param {?proto.common.StopStream|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setStopStream = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearStopStream = function() {
  return this.setStopStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasStopStream = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional TakePhoto take_photo = 15;
 * @return {?proto.common.TakePhoto}
 */
proto.common.Command.prototype.getTakePhoto = function() {
  return /** @type{?proto.common.TakePhoto} */ (
    jspb.Message.getWrapperField(this, proto.common.TakePhoto, 15));
};


/**
 * @param {?proto.common.TakePhoto|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setTakePhoto = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearTakePhoto = function() {
  return this.setTakePhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasTakePhoto = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional StartRecording start_recording = 16;
 * @return {?proto.common.StartRecording}
 */
proto.common.Command.prototype.getStartRecording = function() {
  return /** @type{?proto.common.StartRecording} */ (
    jspb.Message.getWrapperField(this, proto.common.StartRecording, 16));
};


/**
 * @param {?proto.common.StartRecording|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setStartRecording = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearStartRecording = function() {
  return this.setStartRecording(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasStartRecording = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional StopRecording stop_recording = 17;
 * @return {?proto.common.StopRecording}
 */
proto.common.Command.prototype.getStopRecording = function() {
  return /** @type{?proto.common.StopRecording} */ (
    jspb.Message.getWrapperField(this, proto.common.StopRecording, 17));
};


/**
 * @param {?proto.common.StopRecording|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setStopRecording = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearStopRecording = function() {
  return this.setStopRecording(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasStopRecording = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional ManualControl manual_control = 18;
 * @return {?proto.common.ManualControl}
 */
proto.common.Command.prototype.getManualControl = function() {
  return /** @type{?proto.common.ManualControl} */ (
    jspb.Message.getWrapperField(this, proto.common.ManualControl, 18));
};


/**
 * @param {?proto.common.ManualControl|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setManualControl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearManualControl = function() {
  return this.setManualControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasManualControl = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional MoveAltitude move_altitude = 19;
 * @return {?proto.common.MoveAltitude}
 */
proto.common.Command.prototype.getMoveAltitude = function() {
  return /** @type{?proto.common.MoveAltitude} */ (
    jspb.Message.getWrapperField(this, proto.common.MoveAltitude, 19));
};


/**
 * @param {?proto.common.MoveAltitude|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setMoveAltitude = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearMoveAltitude = function() {
  return this.setMoveAltitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasMoveAltitude = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional DownloadMedia download_media = 20;
 * @return {?proto.common.DownloadMedia}
 */
proto.common.Command.prototype.getDownloadMedia = function() {
  return /** @type{?proto.common.DownloadMedia} */ (
    jspb.Message.getWrapperField(this, proto.common.DownloadMedia, 20));
};


/**
 * @param {?proto.common.DownloadMedia|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setDownloadMedia = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearDownloadMedia = function() {
  return this.setDownloadMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasDownloadMedia = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional StopCamera stop_camera = 21;
 * @return {?proto.common.StopCamera}
 */
proto.common.Command.prototype.getStopCamera = function() {
  return /** @type{?proto.common.StopCamera} */ (
    jspb.Message.getWrapperField(this, proto.common.StopCamera, 21));
};


/**
 * @param {?proto.common.StopCamera|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setStopCamera = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearStopCamera = function() {
  return this.setStopCamera(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasStopCamera = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional Takeoff takeoff = 22;
 * @return {?proto.common.Takeoff}
 */
proto.common.Command.prototype.getTakeoff = function() {
  return /** @type{?proto.common.Takeoff} */ (
    jspb.Message.getWrapperField(this, proto.common.Takeoff, 22));
};


/**
 * @param {?proto.common.Takeoff|undefined} value
 * @return {!proto.common.Command} returns this
*/
proto.common.Command.prototype.setTakeoff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.common.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Command} returns this
 */
proto.common.Command.prototype.clearTakeoff = function() {
  return this.setTakeoff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Command.prototype.hasTakeoff = function() {
  return jspb.Message.getField(this, 22) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.CommandList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.CommandList.prototype.toObject = function(opt_includeInstance) {
  return proto.common.CommandList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.CommandList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CommandList.toObject = function(includeInstance, msg) {
  var f, obj = {
    commandsList: jspb.Message.toObjectList(msg.getCommandsList(),
    proto.common.Command.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.CommandList}
 */
proto.common.CommandList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.CommandList;
  return proto.common.CommandList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.CommandList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.CommandList}
 */
proto.common.CommandList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Command;
      reader.readMessage(value,proto.common.Command.deserializeBinaryFromReader);
      msg.addCommands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.CommandList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.CommandList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.CommandList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CommandList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.Command.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Command commands = 1;
 * @return {!Array<!proto.common.Command>}
 */
proto.common.CommandList.prototype.getCommandsList = function() {
  return /** @type{!Array<!proto.common.Command>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Command, 1));
};


/**
 * @param {!Array<!proto.common.Command>} value
 * @return {!proto.common.CommandList} returns this
*/
proto.common.CommandList.prototype.setCommandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Command=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Command}
 */
proto.common.CommandList.prototype.addCommands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Command, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.CommandList} returns this
 */
proto.common.CommandList.prototype.clearCommandsList = function() {
  return this.setCommandsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Log.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Log.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Log} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Log.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    level: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 3, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Log}
 */
proto.common.Log.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Log;
  return proto.common.Log.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Log} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Log}
 */
proto.common.Log.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {!proto.common.Log.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Log.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Log.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Log} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Log.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.Log.Level = {
  CRITICAL: 0,
  ERROR: 1,
  WARNING: 2,
  INFO: 3,
  DEBUG: 4
};

/**
 * optional uint64 timestamp = 1;
 * @return {number}
 */
proto.common.Log.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Log} returns this
 */
proto.common.Log.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Level level = 2;
 * @return {!proto.common.Log.Level}
 */
proto.common.Log.prototype.getLevel = function() {
  return /** @type {!proto.common.Log.Level} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.Log.Level} value
 * @return {!proto.common.Log} returns this
 */
proto.common.Log.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string tag = 3;
 * @return {string}
 */
proto.common.Log.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Log} returns this
 */
proto.common.Log.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.common.Log.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Log} returns this
 */
proto.common.Log.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FlightPlanStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FlightPlanStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FlightPlanStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FlightPlanStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    fpId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentCommand: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FlightPlanStatus}
 */
proto.common.FlightPlanStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FlightPlanStatus;
  return proto.common.FlightPlanStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FlightPlanStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FlightPlanStatus}
 */
proto.common.FlightPlanStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFpId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.FlightPlanStatus.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentCommand(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FlightPlanStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FlightPlanStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FlightPlanStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FlightPlanStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFpId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCurrentCommand();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.FlightPlanStatus.Status = {
  UNKNOWN: 0,
  STARTED: 1,
  COMPLETED: 2,
  FAILED: 3
};

/**
 * optional string fp_id = 1;
 * @return {string}
 */
proto.common.FlightPlanStatus.prototype.getFpId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.FlightPlanStatus} returns this
 */
proto.common.FlightPlanStatus.prototype.setFpId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Status status = 2;
 * @return {!proto.common.FlightPlanStatus.Status}
 */
proto.common.FlightPlanStatus.prototype.getStatus = function() {
  return /** @type {!proto.common.FlightPlanStatus.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.FlightPlanStatus.Status} value
 * @return {!proto.common.FlightPlanStatus} returns this
 */
proto.common.FlightPlanStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 current_command = 3;
 * @return {number}
 */
proto.common.FlightPlanStatus.prototype.getCurrentCommand = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.FlightPlanStatus} returns this
 */
proto.common.FlightPlanStatus.prototype.setCurrentCommand = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 timestamp = 4;
 * @return {number}
 */
proto.common.FlightPlanStatus.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.FlightPlanStatus} returns this
 */
proto.common.FlightPlanStatus.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.Ack.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.common.Ack.AckCase = {
  ACK_NOT_SET: 0,
  MEDIA_ID: 1
};

/**
 * @return {proto.common.Ack.AckCase}
 */
proto.common.Ack.prototype.getAckCase = function() {
  return /** @type {proto.common.Ack.AckCase} */(jspb.Message.computeOneofCase(this, proto.common.Ack.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Ack.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Ack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Ack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Ack.toObject = function(includeInstance, msg) {
  var f, obj = {
    mediaId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Ack}
 */
proto.common.Ack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Ack;
  return proto.common.Ack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Ack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Ack}
 */
proto.common.Ack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMediaId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Ack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Ack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Ack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Ack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 media_id = 1;
 * @return {number}
 */
proto.common.Ack.prototype.getMediaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Ack} returns this
 */
proto.common.Ack.prototype.setMediaId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.common.Ack.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.Ack} returns this
 */
proto.common.Ack.prototype.clearMediaId = function() {
  return jspb.Message.setOneofField(this, 1, proto.common.Ack.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Ack.prototype.hasMediaId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.FlightPlan.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.common.FlightPlan.DataCase = {
  DATA_NOT_SET: 0,
  ID: 1,
  COMMANDS: 2,
  JSON: 3,
  EMPTY: 4
};

/**
 * @return {proto.common.FlightPlan.DataCase}
 */
proto.common.FlightPlan.prototype.getDataCase = function() {
  return /** @type {proto.common.FlightPlan.DataCase} */(jspb.Message.computeOneofCase(this, proto.common.FlightPlan.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FlightPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FlightPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FlightPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FlightPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    commands: (f = msg.getCommands()) && proto.common.CommandList.toObject(includeInstance, f),
    json: msg.getJson_asB64(),
    empty: (f = msg.getEmpty()) && proto.common.Empty.toObject(includeInstance, f),
    fpId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    autoHome: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    commandIndex: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FlightPlan}
 */
proto.common.FlightPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FlightPlan;
  return proto.common.FlightPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FlightPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FlightPlan}
 */
proto.common.FlightPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.common.CommandList;
      reader.readMessage(value,proto.common.CommandList.deserializeBinaryFromReader);
      msg.setCommands(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setJson(value);
      break;
    case 4:
      var value = new proto.common.Empty;
      reader.readMessage(value,proto.common.Empty.deserializeBinaryFromReader);
      msg.setEmpty(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFpId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoHome(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommandIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FlightPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FlightPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FlightPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FlightPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommands();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.CommandList.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getEmpty();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Empty.serializeBinaryToWriter
    );
  }
  f = message.getFpId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAutoHome();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCommandIndex();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.FlightPlan.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.FlightPlan} returns this
 */
proto.common.FlightPlan.prototype.setId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.common.FlightPlan.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.FlightPlan} returns this
 */
proto.common.FlightPlan.prototype.clearId = function() {
  return jspb.Message.setOneofField(this, 1, proto.common.FlightPlan.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.FlightPlan.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommandList commands = 2;
 * @return {?proto.common.CommandList}
 */
proto.common.FlightPlan.prototype.getCommands = function() {
  return /** @type{?proto.common.CommandList} */ (
    jspb.Message.getWrapperField(this, proto.common.CommandList, 2));
};


/**
 * @param {?proto.common.CommandList|undefined} value
 * @return {!proto.common.FlightPlan} returns this
*/
proto.common.FlightPlan.prototype.setCommands = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.common.FlightPlan.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.FlightPlan} returns this
 */
proto.common.FlightPlan.prototype.clearCommands = function() {
  return this.setCommands(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.FlightPlan.prototype.hasCommands = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes json = 3;
 * @return {!(string|Uint8Array)}
 */
proto.common.FlightPlan.prototype.getJson = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes json = 3;
 * This is a type-conversion wrapper around `getJson()`
 * @return {string}
 */
proto.common.FlightPlan.prototype.getJson_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getJson()));
};


/**
 * optional bytes json = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getJson()`
 * @return {!Uint8Array}
 */
proto.common.FlightPlan.prototype.getJson_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getJson()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.common.FlightPlan} returns this
 */
proto.common.FlightPlan.prototype.setJson = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.common.FlightPlan.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.FlightPlan} returns this
 */
proto.common.FlightPlan.prototype.clearJson = function() {
  return jspb.Message.setOneofField(this, 3, proto.common.FlightPlan.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.FlightPlan.prototype.hasJson = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Empty empty = 4;
 * @return {?proto.common.Empty}
 */
proto.common.FlightPlan.prototype.getEmpty = function() {
  return /** @type{?proto.common.Empty} */ (
    jspb.Message.getWrapperField(this, proto.common.Empty, 4));
};


/**
 * @param {?proto.common.Empty|undefined} value
 * @return {!proto.common.FlightPlan} returns this
*/
proto.common.FlightPlan.prototype.setEmpty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.common.FlightPlan.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.FlightPlan} returns this
 */
proto.common.FlightPlan.prototype.clearEmpty = function() {
  return this.setEmpty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.FlightPlan.prototype.hasEmpty = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string fp_id = 5;
 * @return {string}
 */
proto.common.FlightPlan.prototype.getFpId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.FlightPlan} returns this
 */
proto.common.FlightPlan.prototype.setFpId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool auto_home = 6;
 * @return {boolean}
 */
proto.common.FlightPlan.prototype.getAutoHome = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.FlightPlan} returns this
 */
proto.common.FlightPlan.prototype.setAutoHome = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional uint32 command_index = 7;
 * @return {number}
 */
proto.common.FlightPlan.prototype.getCommandIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.FlightPlan} returns this
 */
proto.common.FlightPlan.prototype.setCommandIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Attitude.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Attitude.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Attitude} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Attitude.toObject = function(includeInstance, msg) {
  var f, obj = {
    roll: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    pitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    yaw: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Attitude}
 */
proto.common.Attitude.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Attitude;
  return proto.common.Attitude.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Attitude} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Attitude}
 */
proto.common.Attitude.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRoll(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPitch(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setYaw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Attitude.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Attitude.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Attitude} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Attitude.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoll();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getYaw();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float roll = 1;
 * @return {number}
 */
proto.common.Attitude.prototype.getRoll = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Attitude} returns this
 */
proto.common.Attitude.prototype.setRoll = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float pitch = 2;
 * @return {number}
 */
proto.common.Attitude.prototype.getPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Attitude} returns this
 */
proto.common.Attitude.prototype.setPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float yaw = 3;
 * @return {number}
 */
proto.common.Attitude.prototype.getYaw = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Attitude} returns this
 */
proto.common.Attitude.prototype.setYaw = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Coordinates.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Coordinates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Coordinates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Coordinates.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    altitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    heading: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Coordinates}
 */
proto.common.Coordinates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Coordinates;
  return proto.common.Coordinates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Coordinates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Coordinates}
 */
proto.common.Coordinates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAltitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Coordinates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Coordinates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Coordinates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Coordinates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAltitude();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float latitude = 1;
 * @return {number}
 */
proto.common.Coordinates.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Coordinates} returns this
 */
proto.common.Coordinates.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float longitude = 2;
 * @return {number}
 */
proto.common.Coordinates.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Coordinates} returns this
 */
proto.common.Coordinates.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float altitude = 3;
 * @return {number}
 */
proto.common.Coordinates.prototype.getAltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Coordinates} returns this
 */
proto.common.Coordinates.prototype.setAltitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float heading = 4;
 * @return {number}
 */
proto.common.Coordinates.prototype.getHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Coordinates} returns this
 */
proto.common.Coordinates.prototype.setHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Velocity.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Velocity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Velocity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Velocity.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    z: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Velocity}
 */
proto.common.Velocity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Velocity;
  return proto.common.Velocity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Velocity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Velocity}
 */
proto.common.Velocity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Velocity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Velocity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Velocity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Velocity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float x = 1;
 * @return {number}
 */
proto.common.Velocity.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Velocity} returns this
 */
proto.common.Velocity.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float y = 2;
 * @return {number}
 */
proto.common.Velocity.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Velocity} returns this
 */
proto.common.Velocity.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float z = 3;
 * @return {number}
 */
proto.common.Velocity.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Velocity} returns this
 */
proto.common.Velocity.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Movement.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Movement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Movement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Movement.toObject = function(includeInstance, msg) {
  var f, obj = {
    speed: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reversed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    direction: jspb.Message.getFieldWithDefault(msg, 3, 0),
    absoluteSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Movement}
 */
proto.common.Movement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Movement;
  return proto.common.Movement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Movement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Movement}
 */
proto.common.Movement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Movement.Speed} */ (reader.readEnum());
      msg.setSpeed(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReversed(value);
      break;
    case 3:
      var value = /** @type {!proto.common.Movement.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAbsoluteSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Movement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Movement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Movement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Movement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getReversed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAbsoluteSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.Movement.Speed = {
  NONE: 0,
  VERY_SLOW: 1,
  SLOW: 2,
  NORMAL: 3,
  FAST: 4,
  VERY_FAST: 5,
  MAX_SPEED: 6
};

/**
 * @enum {number}
 */
proto.common.Movement.Direction = {
  DIRECTION_AUTO: 0,
  DIRECTION_NORMAL: 1,
  DIRECTION_REVERSED: 2
};

/**
 * optional Speed speed = 1;
 * @return {!proto.common.Movement.Speed}
 */
proto.common.Movement.prototype.getSpeed = function() {
  return /** @type {!proto.common.Movement.Speed} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Movement.Speed} value
 * @return {!proto.common.Movement} returns this
 */
proto.common.Movement.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool reversed = 2;
 * @return {boolean}
 */
proto.common.Movement.prototype.getReversed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Movement} returns this
 */
proto.common.Movement.prototype.setReversed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional Direction direction = 3;
 * @return {!proto.common.Movement.Direction}
 */
proto.common.Movement.prototype.getDirection = function() {
  return /** @type {!proto.common.Movement.Direction} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.Movement.Direction} value
 * @return {!proto.common.Movement} returns this
 */
proto.common.Movement.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float absolute_speed = 4;
 * @return {number}
 */
proto.common.Movement.prototype.getAbsoluteSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Movement} returns this
 */
proto.common.Movement.prototype.setAbsoluteSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.CameraSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.common.CameraSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.CameraSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CameraSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    streamQuality: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mediaResolution: jspb.Message.getFieldWithDefault(msg, 3, 0),
    videoFrameRate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    photoFormat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    videoFormat: jspb.Message.getFieldWithDefault(msg, 6, 0),
    photoMode: jspb.Message.getFieldWithDefault(msg, 7, 0),
    videoMode: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.CameraSettings}
 */
proto.common.CameraSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.CameraSettings;
  return proto.common.CameraSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.CameraSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.CameraSettings}
 */
proto.common.CameraSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.CameraSettings.CameraType} */ (reader.readEnum());
      msg.setCameraType(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CameraSettings.StreamQuality} */ (reader.readEnum());
      msg.setStreamQuality(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CameraSettings.MediaResolution} */ (reader.readEnum());
      msg.setMediaResolution(value);
      break;
    case 4:
      var value = /** @type {!proto.common.CameraSettings.VideoFrameRate} */ (reader.readEnum());
      msg.setVideoFrameRate(value);
      break;
    case 5:
      var value = /** @type {!proto.common.CameraSettings.PhotoFormat} */ (reader.readEnum());
      msg.setPhotoFormat(value);
      break;
    case 6:
      var value = /** @type {!proto.common.CameraSettings.VideoFormat} */ (reader.readEnum());
      msg.setVideoFormat(value);
      break;
    case 7:
      var value = /** @type {!proto.common.CameraSettings.PhotoMode} */ (reader.readEnum());
      msg.setPhotoMode(value);
      break;
    case 8:
      var value = /** @type {!proto.common.CameraSettings.VideoMode} */ (reader.readEnum());
      msg.setVideoMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.CameraSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.CameraSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.CameraSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CameraSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStreamQuality();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMediaResolution();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getVideoFrameRate();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPhotoFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getVideoFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPhotoMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getVideoMode();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.CameraSettings.CameraType = {
  NORMAL: 0,
  THERMAL: 1
};

/**
 * @enum {number}
 */
proto.common.CameraSettings.StreamQuality = {
  STREAM_QUALITY_NORMAL: 0,
  STREAM_QUALITY_HIGH: 1,
  STREAM_QUALITY_LOW: 2
};

/**
 * @enum {number}
 */
proto.common.CameraSettings.MediaResolution = {
  RESOLUTION_DEFAULT: 0,
  RESOLUTION_720P: 1,
  RESOLUTION_1080P: 2,
  RESOLUTION_1440P: 3,
  RESOLUTION_2160P: 4
};

/**
 * @enum {number}
 */
proto.common.CameraSettings.VideoFrameRate = {
  FRAME_RATE_DEFAULT: 0,
  FRAME_RATE_24FPS: 1,
  FRAME_RATE_30FPS: 2,
  FRAME_RATE_48FPS: 3,
  FRAME_RATE_60FPS: 4,
  FRAME_RATE_120FPS: 5
};

/**
 * @enum {number}
 */
proto.common.CameraSettings.PhotoFormat = {
  PHOTO_FORMAT_DEFAULT: 0,
  PHOTO_FORMAT_JPEG: 1,
  PHOTO_FORMAT_RAW: 2,
  PHOTO_FORMAT_RAW_AND_JPEG: 3,
  PHOTO_FORMAT_TIFF: 4
};

/**
 * @enum {number}
 */
proto.common.CameraSettings.VideoFormat = {
  VIDEO_FORMAT_DEFAULT: 0,
  VIDEO_FORMAT_MP4: 1,
  VIDEO_FORMAT_MOV: 2,
  VIDEO_FORMAT_TIFF_SEQ: 3,
  VIDEO_FORMAT_SEQ: 4
};

/**
 * @enum {number}
 */
proto.common.CameraSettings.PhotoMode = {
  PHOTO_MODE_SINGLE: 0,
  PHOTO_MODE_INTERVAL: 1,
  PHOTO_MODE_BURST: 2,
  PHOTO_MODE_HDR: 3,
  PHOTO_MODE_EHDR: 4,
  PHOTO_MODE_HYPER_LIGHT: 5,
  PHOTO_MODE_AEB: 6,
  PHOTO_MODE_TIME_LAPSE: 7,
  PHOTO_MODE_PANORAMA: 8,
  PHOTO_MODE_HIGH_RESOLUTION: 9
};

/**
 * @enum {number}
 */
proto.common.CameraSettings.VideoMode = {
  VIDEO_MODE_NORMAL: 0,
  VIDEO_MODE_SLOW_MOTION: 1,
  VIDEO_MODE_HDR: 2
};

/**
 * optional CameraType camera_type = 1;
 * @return {!proto.common.CameraSettings.CameraType}
 */
proto.common.CameraSettings.prototype.getCameraType = function() {
  return /** @type {!proto.common.CameraSettings.CameraType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.CameraSettings.CameraType} value
 * @return {!proto.common.CameraSettings} returns this
 */
proto.common.CameraSettings.prototype.setCameraType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional StreamQuality stream_quality = 2;
 * @return {!proto.common.CameraSettings.StreamQuality}
 */
proto.common.CameraSettings.prototype.getStreamQuality = function() {
  return /** @type {!proto.common.CameraSettings.StreamQuality} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CameraSettings.StreamQuality} value
 * @return {!proto.common.CameraSettings} returns this
 */
proto.common.CameraSettings.prototype.setStreamQuality = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional MediaResolution media_resolution = 3;
 * @return {!proto.common.CameraSettings.MediaResolution}
 */
proto.common.CameraSettings.prototype.getMediaResolution = function() {
  return /** @type {!proto.common.CameraSettings.MediaResolution} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CameraSettings.MediaResolution} value
 * @return {!proto.common.CameraSettings} returns this
 */
proto.common.CameraSettings.prototype.setMediaResolution = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional VideoFrameRate video_frame_rate = 4;
 * @return {!proto.common.CameraSettings.VideoFrameRate}
 */
proto.common.CameraSettings.prototype.getVideoFrameRate = function() {
  return /** @type {!proto.common.CameraSettings.VideoFrameRate} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.CameraSettings.VideoFrameRate} value
 * @return {!proto.common.CameraSettings} returns this
 */
proto.common.CameraSettings.prototype.setVideoFrameRate = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PhotoFormat photo_format = 5;
 * @return {!proto.common.CameraSettings.PhotoFormat}
 */
proto.common.CameraSettings.prototype.getPhotoFormat = function() {
  return /** @type {!proto.common.CameraSettings.PhotoFormat} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.CameraSettings.PhotoFormat} value
 * @return {!proto.common.CameraSettings} returns this
 */
proto.common.CameraSettings.prototype.setPhotoFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional VideoFormat video_format = 6;
 * @return {!proto.common.CameraSettings.VideoFormat}
 */
proto.common.CameraSettings.prototype.getVideoFormat = function() {
  return /** @type {!proto.common.CameraSettings.VideoFormat} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.CameraSettings.VideoFormat} value
 * @return {!proto.common.CameraSettings} returns this
 */
proto.common.CameraSettings.prototype.setVideoFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional PhotoMode photo_mode = 7;
 * @return {!proto.common.CameraSettings.PhotoMode}
 */
proto.common.CameraSettings.prototype.getPhotoMode = function() {
  return /** @type {!proto.common.CameraSettings.PhotoMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.CameraSettings.PhotoMode} value
 * @return {!proto.common.CameraSettings} returns this
 */
proto.common.CameraSettings.prototype.setPhotoMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional VideoMode video_mode = 8;
 * @return {!proto.common.CameraSettings.VideoMode}
 */
proto.common.CameraSettings.prototype.getVideoMode = function() {
  return /** @type {!proto.common.CameraSettings.VideoMode} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.CameraSettings.VideoMode} value
 * @return {!proto.common.CameraSettings} returns this
 */
proto.common.CameraSettings.prototype.setVideoMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Takeoff.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Takeoff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Takeoff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Takeoff.toObject = function(includeInstance, msg) {
  var f, obj = {
    altitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Takeoff}
 */
proto.common.Takeoff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Takeoff;
  return proto.common.Takeoff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Takeoff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Takeoff}
 */
proto.common.Takeoff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAltitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Takeoff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Takeoff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Takeoff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Takeoff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAltitude();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float altitude = 1;
 * @return {number}
 */
proto.common.Takeoff.prototype.getAltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Takeoff} returns this
 */
proto.common.Takeoff.prototype.setAltitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Land.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Land.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Land} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Land.toObject = function(includeInstance, msg) {
  var f, obj = {
    force: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Land}
 */
proto.common.Land.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Land;
  return proto.common.Land.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Land} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Land}
 */
proto.common.Land.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Land.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Land.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Land} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Land.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForce();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool force = 1;
 * @return {boolean}
 */
proto.common.Land.prototype.getForce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Land} returns this
 */
proto.common.Land.prototype.setForce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ReturnHome.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ReturnHome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ReturnHome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ReturnHome.toObject = function(includeInstance, msg) {
  var f, obj = {
    altitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    forceLand: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ReturnHome}
 */
proto.common.ReturnHome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ReturnHome;
  return proto.common.ReturnHome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ReturnHome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ReturnHome}
 */
proto.common.ReturnHome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAltitude(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceLand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ReturnHome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ReturnHome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ReturnHome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ReturnHome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAltitude();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getForceLand();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional float altitude = 1;
 * @return {number}
 */
proto.common.ReturnHome.prototype.getAltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.ReturnHome} returns this
 */
proto.common.ReturnHome.prototype.setAltitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional bool force_land = 2;
 * @return {boolean}
 */
proto.common.ReturnHome.prototype.getForceLand = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.ReturnHome} returns this
 */
proto.common.ReturnHome.prototype.setForceLand = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Hover.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Hover.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Hover} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Hover.toObject = function(includeInstance, msg) {
  var f, obj = {
    duration: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Hover}
 */
proto.common.Hover.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Hover;
  return proto.common.Hover.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Hover} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Hover}
 */
proto.common.Hover.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Hover.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Hover.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Hover} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Hover.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 duration = 1;
 * @return {number}
 */
proto.common.Hover.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Hover} returns this
 */
proto.common.Hover.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MoveSpeed.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MoveSpeed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MoveSpeed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MoveSpeed.toObject = function(includeInstance, msg) {
  var f, obj = {
    forward: (f = msg.getForward()) && proto.common.Movement.toObject(includeInstance, f),
    right: (f = msg.getRight()) && proto.common.Movement.toObject(includeInstance, f),
    up: (f = msg.getUp()) && proto.common.Movement.toObject(includeInstance, f),
    rotate: (f = msg.getRotate()) && proto.common.Movement.toObject(includeInstance, f),
    duration: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MoveSpeed}
 */
proto.common.MoveSpeed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MoveSpeed;
  return proto.common.MoveSpeed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MoveSpeed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MoveSpeed}
 */
proto.common.MoveSpeed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setForward(value);
      break;
    case 2:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setRight(value);
      break;
    case 3:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setUp(value);
      break;
    case 4:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setRotate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MoveSpeed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MoveSpeed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MoveSpeed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MoveSpeed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForward();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getRight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getUp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getRotate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional Movement forward = 1;
 * @return {?proto.common.Movement}
 */
proto.common.MoveSpeed.prototype.getForward = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 1));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.MoveSpeed} returns this
*/
proto.common.MoveSpeed.prototype.setForward = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MoveSpeed} returns this
 */
proto.common.MoveSpeed.prototype.clearForward = function() {
  return this.setForward(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MoveSpeed.prototype.hasForward = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Movement right = 2;
 * @return {?proto.common.Movement}
 */
proto.common.MoveSpeed.prototype.getRight = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 2));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.MoveSpeed} returns this
*/
proto.common.MoveSpeed.prototype.setRight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MoveSpeed} returns this
 */
proto.common.MoveSpeed.prototype.clearRight = function() {
  return this.setRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MoveSpeed.prototype.hasRight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Movement up = 3;
 * @return {?proto.common.Movement}
 */
proto.common.MoveSpeed.prototype.getUp = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 3));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.MoveSpeed} returns this
*/
proto.common.MoveSpeed.prototype.setUp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MoveSpeed} returns this
 */
proto.common.MoveSpeed.prototype.clearUp = function() {
  return this.setUp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MoveSpeed.prototype.hasUp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Movement rotate = 4;
 * @return {?proto.common.Movement}
 */
proto.common.MoveSpeed.prototype.getRotate = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 4));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.MoveSpeed} returns this
*/
proto.common.MoveSpeed.prototype.setRotate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MoveSpeed} returns this
 */
proto.common.MoveSpeed.prototype.clearRotate = function() {
  return this.setRotate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MoveSpeed.prototype.hasRotate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 duration = 5;
 * @return {number}
 */
proto.common.MoveSpeed.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.MoveSpeed} returns this
 */
proto.common.MoveSpeed.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MoveGPS.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MoveGPS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MoveGPS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MoveGPS.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: (f = msg.getPosition()) && proto.common.Coordinates.toObject(includeInstance, f),
    speed: (f = msg.getSpeed()) && proto.common.Movement.toObject(includeInstance, f),
    autoHeading: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    constantSpeed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MoveGPS}
 */
proto.common.MoveGPS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MoveGPS;
  return proto.common.MoveGPS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MoveGPS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MoveGPS}
 */
proto.common.MoveGPS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Coordinates;
      reader.readMessage(value,proto.common.Coordinates.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 2:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setSpeed(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoHeading(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConstantSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MoveGPS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MoveGPS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MoveGPS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MoveGPS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getSpeed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getAutoHeading();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getConstantSpeed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional Coordinates position = 1;
 * @return {?proto.common.Coordinates}
 */
proto.common.MoveGPS.prototype.getPosition = function() {
  return /** @type{?proto.common.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.common.Coordinates, 1));
};


/**
 * @param {?proto.common.Coordinates|undefined} value
 * @return {!proto.common.MoveGPS} returns this
*/
proto.common.MoveGPS.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MoveGPS} returns this
 */
proto.common.MoveGPS.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MoveGPS.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Movement speed = 2;
 * @return {?proto.common.Movement}
 */
proto.common.MoveGPS.prototype.getSpeed = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 2));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.MoveGPS} returns this
*/
proto.common.MoveGPS.prototype.setSpeed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MoveGPS} returns this
 */
proto.common.MoveGPS.prototype.clearSpeed = function() {
  return this.setSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MoveGPS.prototype.hasSpeed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool auto_heading = 3;
 * @return {boolean}
 */
proto.common.MoveGPS.prototype.getAutoHeading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.MoveGPS} returns this
 */
proto.common.MoveGPS.prototype.setAutoHeading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool constant_speed = 4;
 * @return {boolean}
 */
proto.common.MoveGPS.prototype.getConstantSpeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.MoveGPS} returns this
 */
proto.common.MoveGPS.prototype.setConstantSpeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.Circle.oneofGroups_ = [[4,5],[1,6]];

/**
 * @enum {number}
 */
proto.common.Circle.QuantityCase = {
  QUANTITY_NOT_SET: 0,
  DURATION: 4,
  DEGREES: 5
};

/**
 * @return {proto.common.Circle.QuantityCase}
 */
proto.common.Circle.prototype.getQuantityCase = function() {
  return /** @type {proto.common.Circle.QuantityCase} */(jspb.Message.computeOneofCase(this, proto.common.Circle.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.common.Circle.CenterCase = {
  CENTER_NOT_SET: 0,
  RADIUS: 1,
  POSITION: 6
};

/**
 * @return {proto.common.Circle.CenterCase}
 */
proto.common.Circle.prototype.getCenterCase = function() {
  return /** @type {proto.common.Circle.CenterCase} */(jspb.Message.computeOneofCase(this, proto.common.Circle.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Circle.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Circle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Circle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Circle.toObject = function(includeInstance, msg) {
  var f, obj = {
    speed: (f = msg.getSpeed()) && proto.common.Movement.toObject(includeInstance, f),
    duration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    degrees: jspb.Message.getFieldWithDefault(msg, 5, 0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    position: (f = msg.getPosition()) && proto.common.Coordinates.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Circle}
 */
proto.common.Circle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Circle;
  return proto.common.Circle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Circle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Circle}
 */
proto.common.Circle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setSpeed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDuration(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDegrees(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRadius(value);
      break;
    case 6:
      var value = new proto.common.Coordinates;
      reader.readMessage(value,proto.common.Coordinates.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Circle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Circle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Circle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Circle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpeed();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.Coordinates.serializeBinaryToWriter
    );
  }
};


/**
 * optional Movement speed = 3;
 * @return {?proto.common.Movement}
 */
proto.common.Circle.prototype.getSpeed = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 3));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.Circle} returns this
*/
proto.common.Circle.prototype.setSpeed = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Circle} returns this
 */
proto.common.Circle.prototype.clearSpeed = function() {
  return this.setSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Circle.prototype.hasSpeed = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 duration = 4;
 * @return {number}
 */
proto.common.Circle.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Circle} returns this
 */
proto.common.Circle.prototype.setDuration = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.common.Circle.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.Circle} returns this
 */
proto.common.Circle.prototype.clearDuration = function() {
  return jspb.Message.setOneofField(this, 4, proto.common.Circle.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Circle.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 degrees = 5;
 * @return {number}
 */
proto.common.Circle.prototype.getDegrees = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Circle} returns this
 */
proto.common.Circle.prototype.setDegrees = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.common.Circle.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.Circle} returns this
 */
proto.common.Circle.prototype.clearDegrees = function() {
  return jspb.Message.setOneofField(this, 5, proto.common.Circle.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Circle.prototype.hasDegrees = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float radius = 1;
 * @return {number}
 */
proto.common.Circle.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Circle} returns this
 */
proto.common.Circle.prototype.setRadius = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.common.Circle.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.Circle} returns this
 */
proto.common.Circle.prototype.clearRadius = function() {
  return jspb.Message.setOneofField(this, 1, proto.common.Circle.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Circle.prototype.hasRadius = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Coordinates position = 6;
 * @return {?proto.common.Coordinates}
 */
proto.common.Circle.prototype.getPosition = function() {
  return /** @type{?proto.common.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.common.Coordinates, 6));
};


/**
 * @param {?proto.common.Coordinates|undefined} value
 * @return {!proto.common.Circle} returns this
*/
proto.common.Circle.prototype.setPosition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.common.Circle.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Circle} returns this
 */
proto.common.Circle.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Circle.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Rotate.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Rotate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Rotate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Rotate.toObject = function(includeInstance, msg) {
  var f, obj = {
    heading: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    speed: (f = msg.getSpeed()) && proto.common.Movement.toObject(includeInstance, f),
    relativeHeading: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    forward: (f = msg.getForward()) && proto.common.Movement.toObject(includeInstance, f),
    right: (f = msg.getRight()) && proto.common.Movement.toObject(includeInstance, f),
    up: (f = msg.getUp()) && proto.common.Movement.toObject(includeInstance, f),
    constantSpeed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Rotate}
 */
proto.common.Rotate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Rotate;
  return proto.common.Rotate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Rotate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Rotate}
 */
proto.common.Rotate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeading(value);
      break;
    case 2:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setSpeed(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRelativeHeading(value);
      break;
    case 4:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setForward(value);
      break;
    case 5:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setRight(value);
      break;
    case 6:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setUp(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConstantSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Rotate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Rotate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Rotate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Rotate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getSpeed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getRelativeHeading();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getForward();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getRight();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getUp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getConstantSpeed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional float heading = 1;
 * @return {number}
 */
proto.common.Rotate.prototype.getHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Rotate} returns this
 */
proto.common.Rotate.prototype.setHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional Movement speed = 2;
 * @return {?proto.common.Movement}
 */
proto.common.Rotate.prototype.getSpeed = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 2));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.Rotate} returns this
*/
proto.common.Rotate.prototype.setSpeed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Rotate} returns this
 */
proto.common.Rotate.prototype.clearSpeed = function() {
  return this.setSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Rotate.prototype.hasSpeed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool relative_heading = 3;
 * @return {boolean}
 */
proto.common.Rotate.prototype.getRelativeHeading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Rotate} returns this
 */
proto.common.Rotate.prototype.setRelativeHeading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional Movement forward = 4;
 * @return {?proto.common.Movement}
 */
proto.common.Rotate.prototype.getForward = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 4));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.Rotate} returns this
*/
proto.common.Rotate.prototype.setForward = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Rotate} returns this
 */
proto.common.Rotate.prototype.clearForward = function() {
  return this.setForward(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Rotate.prototype.hasForward = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Movement right = 5;
 * @return {?proto.common.Movement}
 */
proto.common.Rotate.prototype.getRight = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 5));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.Rotate} returns this
*/
proto.common.Rotate.prototype.setRight = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Rotate} returns this
 */
proto.common.Rotate.prototype.clearRight = function() {
  return this.setRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Rotate.prototype.hasRight = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Movement up = 6;
 * @return {?proto.common.Movement}
 */
proto.common.Rotate.prototype.getUp = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 6));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.Rotate} returns this
*/
proto.common.Rotate.prototype.setUp = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Rotate} returns this
 */
proto.common.Rotate.prototype.clearUp = function() {
  return this.setUp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Rotate.prototype.hasUp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool constant_speed = 7;
 * @return {boolean}
 */
proto.common.Rotate.prototype.getConstantSpeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Rotate} returns this
 */
proto.common.Rotate.prototype.setConstantSpeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MoveAltitude.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MoveAltitude.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MoveAltitude} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MoveAltitude.toObject = function(includeInstance, msg) {
  var f, obj = {
    altitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    speed: (f = msg.getSpeed()) && proto.common.Movement.toObject(includeInstance, f),
    constantSpeed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MoveAltitude}
 */
proto.common.MoveAltitude.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MoveAltitude;
  return proto.common.MoveAltitude.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MoveAltitude} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MoveAltitude}
 */
proto.common.MoveAltitude.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAltitude(value);
      break;
    case 2:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setSpeed(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConstantSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MoveAltitude.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MoveAltitude.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MoveAltitude} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MoveAltitude.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAltitude();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getSpeed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getConstantSpeed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional float altitude = 1;
 * @return {number}
 */
proto.common.MoveAltitude.prototype.getAltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.MoveAltitude} returns this
 */
proto.common.MoveAltitude.prototype.setAltitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional Movement speed = 2;
 * @return {?proto.common.Movement}
 */
proto.common.MoveAltitude.prototype.getSpeed = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 2));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.MoveAltitude} returns this
*/
proto.common.MoveAltitude.prototype.setSpeed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MoveAltitude} returns this
 */
proto.common.MoveAltitude.prototype.clearSpeed = function() {
  return this.setSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MoveAltitude.prototype.hasSpeed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool constant_speed = 3;
 * @return {boolean}
 */
proto.common.MoveAltitude.prototype.getConstantSpeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.MoveAltitude} returns this
 */
proto.common.MoveAltitude.prototype.setConstantSpeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Stop.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Stop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Stop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Stop.toObject = function(includeInstance, msg) {
  var f, obj = {
    goHome: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Stop}
 */
proto.common.Stop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Stop;
  return proto.common.Stop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Stop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Stop}
 */
proto.common.Stop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoHome(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Stop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Stop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Stop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Stop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoHome();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool go_home = 1;
 * @return {boolean}
 */
proto.common.Stop.prototype.getGoHome = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Stop} returns this
 */
proto.common.Stop.prototype.setGoHome = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StartStream.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StartStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StartStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StartStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraSettings: (f = msg.getCameraSettings()) && proto.common.CameraSettings.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StartStream}
 */
proto.common.StartStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StartStream;
  return proto.common.StartStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StartStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StartStream}
 */
proto.common.StartStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.CameraSettings;
      reader.readMessage(value,proto.common.CameraSettings.deserializeBinaryFromReader);
      msg.setCameraSettings(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StartStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StartStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StartStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StartStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.CameraSettings.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional CameraSettings camera_settings = 1;
 * @return {?proto.common.CameraSettings}
 */
proto.common.StartStream.prototype.getCameraSettings = function() {
  return /** @type{?proto.common.CameraSettings} */ (
    jspb.Message.getWrapperField(this, proto.common.CameraSettings, 1));
};


/**
 * @param {?proto.common.CameraSettings|undefined} value
 * @return {!proto.common.StartStream} returns this
*/
proto.common.StartStream.prototype.setCameraSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.StartStream} returns this
 */
proto.common.StartStream.prototype.clearCameraSettings = function() {
  return this.setCameraSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.StartStream.prototype.hasCameraSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.common.StartStream.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.StartStream} returns this
 */
proto.common.StartStream.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StopStream.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StopStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StopStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StopStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraSettings: (f = msg.getCameraSettings()) && proto.common.CameraSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StopStream}
 */
proto.common.StopStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StopStream;
  return proto.common.StopStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StopStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StopStream}
 */
proto.common.StopStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.CameraSettings;
      reader.readMessage(value,proto.common.CameraSettings.deserializeBinaryFromReader);
      msg.setCameraSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StopStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StopStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StopStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StopStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.CameraSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional CameraSettings camera_settings = 1;
 * @return {?proto.common.CameraSettings}
 */
proto.common.StopStream.prototype.getCameraSettings = function() {
  return /** @type{?proto.common.CameraSettings} */ (
    jspb.Message.getWrapperField(this, proto.common.CameraSettings, 1));
};


/**
 * @param {?proto.common.CameraSettings|undefined} value
 * @return {!proto.common.StopStream} returns this
*/
proto.common.StopStream.prototype.setCameraSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.StopStream} returns this
 */
proto.common.StopStream.prototype.clearCameraSettings = function() {
  return this.setCameraSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.StopStream.prototype.hasCameraSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.TakePhoto.prototype.toObject = function(opt_includeInstance) {
  return proto.common.TakePhoto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.TakePhoto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TakePhoto.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraSettings: (f = msg.getCameraSettings()) && proto.common.CameraSettings.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    interval: jspb.Message.getFieldWithDefault(msg, 3, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.TakePhoto}
 */
proto.common.TakePhoto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.TakePhoto;
  return proto.common.TakePhoto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.TakePhoto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.TakePhoto}
 */
proto.common.TakePhoto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.CameraSettings;
      reader.readMessage(value,proto.common.CameraSettings.deserializeBinaryFromReader);
      msg.setCameraSettings(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInterval(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.TakePhoto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.TakePhoto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.TakePhoto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TakePhoto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.CameraSettings.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional CameraSettings camera_settings = 1;
 * @return {?proto.common.CameraSettings}
 */
proto.common.TakePhoto.prototype.getCameraSettings = function() {
  return /** @type{?proto.common.CameraSettings} */ (
    jspb.Message.getWrapperField(this, proto.common.CameraSettings, 1));
};


/**
 * @param {?proto.common.CameraSettings|undefined} value
 * @return {!proto.common.TakePhoto} returns this
*/
proto.common.TakePhoto.prototype.setCameraSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.TakePhoto} returns this
 */
proto.common.TakePhoto.prototype.clearCameraSettings = function() {
  return this.setCameraSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.TakePhoto.prototype.hasCameraSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 count = 2;
 * @return {number}
 */
proto.common.TakePhoto.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.TakePhoto} returns this
 */
proto.common.TakePhoto.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 interval = 3;
 * @return {number}
 */
proto.common.TakePhoto.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.TakePhoto} returns this
 */
proto.common.TakePhoto.prototype.setInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 duration = 4;
 * @return {number}
 */
proto.common.TakePhoto.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.TakePhoto} returns this
 */
proto.common.TakePhoto.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StartRecording.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StartRecording.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StartRecording} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StartRecording.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraSettings: (f = msg.getCameraSettings()) && proto.common.CameraSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StartRecording}
 */
proto.common.StartRecording.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StartRecording;
  return proto.common.StartRecording.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StartRecording} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StartRecording}
 */
proto.common.StartRecording.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.CameraSettings;
      reader.readMessage(value,proto.common.CameraSettings.deserializeBinaryFromReader);
      msg.setCameraSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StartRecording.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StartRecording.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StartRecording} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StartRecording.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.CameraSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional CameraSettings camera_settings = 1;
 * @return {?proto.common.CameraSettings}
 */
proto.common.StartRecording.prototype.getCameraSettings = function() {
  return /** @type{?proto.common.CameraSettings} */ (
    jspb.Message.getWrapperField(this, proto.common.CameraSettings, 1));
};


/**
 * @param {?proto.common.CameraSettings|undefined} value
 * @return {!proto.common.StartRecording} returns this
*/
proto.common.StartRecording.prototype.setCameraSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.StartRecording} returns this
 */
proto.common.StartRecording.prototype.clearCameraSettings = function() {
  return this.setCameraSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.StartRecording.prototype.hasCameraSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StopRecording.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StopRecording.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StopRecording} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StopRecording.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraSettings: (f = msg.getCameraSettings()) && proto.common.CameraSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StopRecording}
 */
proto.common.StopRecording.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StopRecording;
  return proto.common.StopRecording.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StopRecording} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StopRecording}
 */
proto.common.StopRecording.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.CameraSettings;
      reader.readMessage(value,proto.common.CameraSettings.deserializeBinaryFromReader);
      msg.setCameraSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StopRecording.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StopRecording.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StopRecording} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StopRecording.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.CameraSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional CameraSettings camera_settings = 1;
 * @return {?proto.common.CameraSettings}
 */
proto.common.StopRecording.prototype.getCameraSettings = function() {
  return /** @type{?proto.common.CameraSettings} */ (
    jspb.Message.getWrapperField(this, proto.common.CameraSettings, 1));
};


/**
 * @param {?proto.common.CameraSettings|undefined} value
 * @return {!proto.common.StopRecording} returns this
*/
proto.common.StopRecording.prototype.setCameraSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.StopRecording} returns this
 */
proto.common.StopRecording.prototype.clearCameraSettings = function() {
  return this.setCameraSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.StopRecording.prototype.hasCameraSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.TiltCamera.prototype.toObject = function(opt_includeInstance) {
  return proto.common.TiltCamera.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.TiltCamera} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TiltCamera.toObject = function(includeInstance, msg) {
  var f, obj = {
    yaw: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    pitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    roll: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    speed: (f = msg.getSpeed()) && proto.common.Movement.toObject(includeInstance, f),
    cameraSettings: (f = msg.getCameraSettings()) && proto.common.CameraSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.TiltCamera}
 */
proto.common.TiltCamera.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.TiltCamera;
  return proto.common.TiltCamera.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.TiltCamera} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.TiltCamera}
 */
proto.common.TiltCamera.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setYaw(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPitch(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRoll(value);
      break;
    case 4:
      var value = new proto.common.Movement;
      reader.readMessage(value,proto.common.Movement.deserializeBinaryFromReader);
      msg.setSpeed(value);
      break;
    case 5:
      var value = new proto.common.CameraSettings;
      reader.readMessage(value,proto.common.CameraSettings.deserializeBinaryFromReader);
      msg.setCameraSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.TiltCamera.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.TiltCamera.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.TiltCamera} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TiltCamera.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYaw();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getRoll();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getSpeed();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Movement.serializeBinaryToWriter
    );
  }
  f = message.getCameraSettings();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.CameraSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional float yaw = 1;
 * @return {number}
 */
proto.common.TiltCamera.prototype.getYaw = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.TiltCamera} returns this
 */
proto.common.TiltCamera.prototype.setYaw = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float pitch = 2;
 * @return {number}
 */
proto.common.TiltCamera.prototype.getPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.TiltCamera} returns this
 */
proto.common.TiltCamera.prototype.setPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float roll = 3;
 * @return {number}
 */
proto.common.TiltCamera.prototype.getRoll = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.TiltCamera} returns this
 */
proto.common.TiltCamera.prototype.setRoll = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional Movement speed = 4;
 * @return {?proto.common.Movement}
 */
proto.common.TiltCamera.prototype.getSpeed = function() {
  return /** @type{?proto.common.Movement} */ (
    jspb.Message.getWrapperField(this, proto.common.Movement, 4));
};


/**
 * @param {?proto.common.Movement|undefined} value
 * @return {!proto.common.TiltCamera} returns this
*/
proto.common.TiltCamera.prototype.setSpeed = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.TiltCamera} returns this
 */
proto.common.TiltCamera.prototype.clearSpeed = function() {
  return this.setSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.TiltCamera.prototype.hasSpeed = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CameraSettings camera_settings = 5;
 * @return {?proto.common.CameraSettings}
 */
proto.common.TiltCamera.prototype.getCameraSettings = function() {
  return /** @type{?proto.common.CameraSettings} */ (
    jspb.Message.getWrapperField(this, proto.common.CameraSettings, 5));
};


/**
 * @param {?proto.common.CameraSettings|undefined} value
 * @return {!proto.common.TiltCamera} returns this
*/
proto.common.TiltCamera.prototype.setCameraSettings = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.TiltCamera} returns this
 */
proto.common.TiltCamera.prototype.clearCameraSettings = function() {
  return this.setCameraSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.TiltCamera.prototype.hasCameraSettings = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StopCamera.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StopCamera.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StopCamera} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StopCamera.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraSettings: (f = msg.getCameraSettings()) && proto.common.CameraSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StopCamera}
 */
proto.common.StopCamera.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StopCamera;
  return proto.common.StopCamera.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StopCamera} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StopCamera}
 */
proto.common.StopCamera.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.CameraSettings;
      reader.readMessage(value,proto.common.CameraSettings.deserializeBinaryFromReader);
      msg.setCameraSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StopCamera.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StopCamera.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StopCamera} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StopCamera.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.CameraSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional CameraSettings camera_settings = 1;
 * @return {?proto.common.CameraSettings}
 */
proto.common.StopCamera.prototype.getCameraSettings = function() {
  return /** @type{?proto.common.CameraSettings} */ (
    jspb.Message.getWrapperField(this, proto.common.CameraSettings, 1));
};


/**
 * @param {?proto.common.CameraSettings|undefined} value
 * @return {!proto.common.StopCamera} returns this
*/
proto.common.StopCamera.prototype.setCameraSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.StopCamera} returns this
 */
proto.common.StopCamera.prototype.clearCameraSettings = function() {
  return this.setCameraSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.StopCamera.prototype.hasCameraSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ManualControl.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ManualControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ManualControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ManualControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ManualControl}
 */
proto.common.ManualControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ManualControl;
  return proto.common.ManualControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ManualControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ManualControl}
 */
proto.common.ManualControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ManualControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ManualControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ManualControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ManualControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool enable = 1;
 * @return {boolean}
 */
proto.common.ManualControl.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.ManualControl} returns this
 */
proto.common.ManualControl.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.DownloadMedia.prototype.toObject = function(opt_includeInstance) {
  return proto.common.DownloadMedia.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.DownloadMedia} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.DownloadMedia.toObject = function(includeInstance, msg) {
  var f, obj = {
    mediaType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cameraType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    notBefore: jspb.Message.getFieldWithDefault(msg, 3, 0),
    notAfter: jspb.Message.getFieldWithDefault(msg, 4, 0),
    maxDownloads: jspb.Message.getFieldWithDefault(msg, 5, 0),
    uploadToClient: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.DownloadMedia}
 */
proto.common.DownloadMedia.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.DownloadMedia;
  return proto.common.DownloadMedia.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.DownloadMedia} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.DownloadMedia}
 */
proto.common.DownloadMedia.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.DownloadMedia.MediaType} */ (reader.readEnum());
      msg.setMediaType(value);
      break;
    case 2:
      var value = /** @type {!proto.common.DownloadMedia.CameraType} */ (reader.readEnum());
      msg.setCameraType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNotBefore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNotAfter(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxDownloads(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUploadToClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.DownloadMedia.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.DownloadMedia.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.DownloadMedia} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.DownloadMedia.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMediaType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCameraType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNotBefore();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getNotAfter();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getMaxDownloads();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getUploadToClient();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.DownloadMedia.MediaType = {
  MEDIA_TYPE_ALL: 0,
  MEDIA_TYPE_PHOTO: 1,
  MEDIA_TYPE_VIDEO: 2
};

/**
 * @enum {number}
 */
proto.common.DownloadMedia.CameraType = {
  CAMERA_TYPE_ALL: 0,
  CAMERA_TYPE_NORMAL: 1,
  CAMERA_TYPE_THERMAL: 2
};

/**
 * optional MediaType media_type = 1;
 * @return {!proto.common.DownloadMedia.MediaType}
 */
proto.common.DownloadMedia.prototype.getMediaType = function() {
  return /** @type {!proto.common.DownloadMedia.MediaType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.DownloadMedia.MediaType} value
 * @return {!proto.common.DownloadMedia} returns this
 */
proto.common.DownloadMedia.prototype.setMediaType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional CameraType camera_type = 2;
 * @return {!proto.common.DownloadMedia.CameraType}
 */
proto.common.DownloadMedia.prototype.getCameraType = function() {
  return /** @type {!proto.common.DownloadMedia.CameraType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.DownloadMedia.CameraType} value
 * @return {!proto.common.DownloadMedia} returns this
 */
proto.common.DownloadMedia.prototype.setCameraType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint64 not_before = 3;
 * @return {number}
 */
proto.common.DownloadMedia.prototype.getNotBefore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.DownloadMedia} returns this
 */
proto.common.DownloadMedia.prototype.setNotBefore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 not_after = 4;
 * @return {number}
 */
proto.common.DownloadMedia.prototype.getNotAfter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.DownloadMedia} returns this
 */
proto.common.DownloadMedia.prototype.setNotAfter = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 max_downloads = 5;
 * @return {number}
 */
proto.common.DownloadMedia.prototype.getMaxDownloads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.DownloadMedia} returns this
 */
proto.common.DownloadMedia.prototype.setMaxDownloads = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool upload_to_client = 6;
 * @return {boolean}
 */
proto.common.DownloadMedia.prototype.getUploadToClient = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.DownloadMedia} returns this
 */
proto.common.DownloadMedia.prototype.setUploadToClient = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


goog.object.extend(exports, proto.common);
