import { Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Divider, Fab, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Pagination, Stack, Typography } from "@mui/material";
import React from "react";
import { agri, darkGray, gray, midGray, primary } from "../theme";
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import RouteIcon from '@mui/icons-material/Route';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { ApolloCache, DefaultContext, MutationFunctionOptions, OperationVariables, gql, useMutation, useQuery } from "@apollo/client";
import { and, whereNotDeleted, whereOrganization } from "../common/utils/graphql";
import { OrganizationContext } from "../common/contexts/organization";
import Loading from "../common/components/Loading";
import Drone from "../classes/drone";
import { useScreenSize } from "../common/hooks/useScreenSize";
import useApi from "../common/hooks/useApi";
import { DroneStatus } from "../common/grpc/ui_pb";
import LinkFlightplanDialog from "../components/dialogs/LinkFlightplanDialog";
import Rename from "../common/dialogs/Rename";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../common/dialogs/ConfirmDialog";
import DroneStatusChip from "../common/components/DroneStatusChip";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { RTHAction, StartAction, StopAction } from "../components/cardactions/Drone";
import DroneModelDialog from "../components/dialogs/DroneModelDialog";
import { saveAs } from 'file-saver';
import { Buffer } from 'buffer';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import { Command } from "../common/grpc/common_pb";
import useUser from "../common/hooks/useUser";
import { useTranslation } from "react-i18next";

interface FleetPaginationProps {
  currentPage: number;
  pages: number;
  onPageChange: (page: number) => void;
}

const FleetPagination = (props: FleetPaginationProps) => {
  return (
    <Box style={{
      padding: '16px',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Pagination
        count={props.pages}
        page={props.currentPage}
        onChange={(e, page) => {
          props.onPageChange(page);
        }}
        color="primary" />
    </Box>
  );
};

interface DroneCardProps {
  drone: Drone;
  deleteDrone?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  renameDroneMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  linkFlightPlanDroneMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  updateDroneModelMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
}

const DroneCard = (props: DroneCardProps) => {
  const [droneStatus, setDroneStatus] = React.useState<DroneStatus.AsObject | null>(null);
  const [connected, setConnected] = React.useState<boolean>(false);
  const [renameDialogOpen, setRenameDialogOpen] = React.useState<boolean>(false);
  const [linkFlightplanDialogOpen, setLinkFlightplanDialogOpen] = React.useState<boolean>(false);
  const [updateDroneModelDialogOpen, setUpdateDroneModelDialogOpen] = React.useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const { get, post } = useApi();
  const { organizationUUID } = useUser();
  const { t } = useTranslation();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleWatch = () => {
    let url = 'https://controller.dromt.it/drone?drone=' + props.drone.uuid;
    if (organizationUUID) {
      url += '&organization=' + organizationUUID;
    }

    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
    a.remove();
  };

  React.useEffect(() => {
    const int = setInterval(() => {
      get(`/drones/${props.drone.uuid}/status`).then((data) => {
        setDroneStatus(data as DroneStatus.AsObject);
      }).catch((err) => {
        console.error(err);
        setDroneStatus(null);
      });
    }, 1000);
    return () => {
      clearInterval(int);
    };
  }, []);

  React.useEffect(() => {
    if (droneStatus) {
      setConnected(droneStatus.metrics?.state+'' !== 'UNKNOWN');
    } else {
      setConnected(false);
    }
  }, [droneStatus]);

  const menuButton = (
    <IconButton
      id="drone-button"
      aria-controls={menuOpen ? 'drone-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={menuOpen ? 'true' : undefined}
      onClick={handleMenuClick}
      style={{
        position: 'absolute',
        top: '8px',
        right: '8px',
      }}
    >
      <MoreVertOutlinedIcon />
    </IconButton>
  );

  const menu = (
    <Menu
      id="drone-menu"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
      MenuListProps={{
        'aria-labelledby': 'drone-button',
      }}
    >
      <MenuItem onClick={() => {
        get(`/drones/${props.drone.uuid}/certificate`).then((data: any) => {
          let pfx = Buffer.from(data.pfx, 'base64');
          saveAs(new Blob([pfx]), props.drone.name + '.pfx');
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          handleMenuClose();
        });
      }}>
        <ListItemIcon>
          <CloudDownloadOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Download Certificate')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        setLinkFlightplanDialogOpen(true);
        handleMenuClose();
        }}>
        <ListItemIcon>
          <RouteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Link FlightPlan')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => {
        const command = {
          id: Command.CommandCode.DOWNLOAD_MEDIA,
          downloadMedia: {
            mediaType: "MEDIA_TYPE_ALL",
            cameraType: "CAMERA_TYPE_ALL",
            uploadToClient: false,
          },
        };

        post(`/controller/drone/${props.drone.uuid}`, [command]).then((data: any) => {
          if (!data.success) {
            console.error(data.message);
          }
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          handleMenuClose();
        });
      }} disabled={!connected}>
        <ListItemIcon>
          <CloudSyncIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Synchronize Media')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        const command = {
          id: Command.CommandCode.DELETE_ALL_MEDIA,
        };

        post(`/controller/drone/${props.drone.uuid}`, [command]).then((data: any) => {
          if (!data.success) {
            console.error(data.message);
          }
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          handleMenuClose();
        });
      }} disabled={!connected}>
        <ListItemIcon>
          <DeleteSweepOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Delete All Media')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => {
        setUpdateDroneModelDialogOpen(true);
        handleMenuClose();
      }}>
        <ListItemIcon>
          <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Change Model')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        setRenameDialogOpen(true);
        handleMenuClose();
      }}>
        <ListItemIcon>
          <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Rename')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        setDeleteDialogOpen(true);
        handleMenuClose();
      }}>
        <ListItemIcon>
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Delete')}</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <Card style={{
      height: '100%',
      maxHeight: '450px',
      backgroundColor: gray,
      borderRadius: '16px',
      textAlign: 'center',
    }}>
      <Stack style={{
        height: '100%',
      }}>
        <CardContent style={{
          height: 'calc(100% - 60px)',
          position: 'relative',
        }}>
          { menuButton }
          { menu }
          <Typography variant="h5" gutterBottom style={{
            cursor: 'pointer',
          }} onClick={() => setUpdateDroneModelDialogOpen(true)}>
            { props.drone.model?.name ?? t('Unknown Model') }
          </Typography>
          <img src={props.drone.model?.image_url ?? '/mock/imgs/drone.png'} alt={props.drone.name} style={{
            width: '100%',
            maxWidth: '250px',
            height: '150px',
            borderRadius: '8px',
            objectFit: 'contain',
          }} />
          <Typography sx={{ mb: 1.5 }} variant="h5"
          onClick={() => setRenameDialogOpen(true)}
          style={{
            cursor: 'pointer',
          }}>
            { props.drone.name }
          </Typography>
          <table style={{
            width: '100%',
          }}>
            <tbody style={{
              width: '100%',
            }}>
              <tr style={{
                width: '100%',
              }}>
                <td style={{
                  width: '50%',
                  textAlign: 'right',
                  paddingRight: '8px',
                }}>
                  <Typography variant="body2">
                    {t('Flight Plan')}
                  </Typography>
                </td>
                <td style={{
                  width: '50%',
                  textAlign: 'left',
                  paddingLeft: '8px',
                }}>
                  <Chip
                    style={{
                      backgroundColor: props.drone.flightPlan ? undefined : '#e0e0e0',
                      color: props.drone.flightPlan ? undefined : 'black',
                    }}
                    color={props.drone.flightPlan ? 'primary' : undefined}
                    label={props.drone.flightPlan ? props.drone.flightPlan.name : 'N/A' }
                    onClick={() => {
                      setLinkFlightplanDialogOpen(true);
                    }}
                    onDelete={ props.drone.flightPlan ? async () => {
                      if (props.linkFlightPlanDroneMutation) {
                        await props.linkFlightPlanDroneMutation({
                          variables: {
                            drone_id: props.drone.uuid,
                            flightplan_id: undefined,
                          },
                        });
                      }
                    } : undefined }
                  />
                </td>
              </tr>
              <tr style={{
                width: '100%',
              }}>
                <td style={{
                  width: '50%',
                  textAlign: 'right',
                  paddingRight: '8px',
                }}>
                  <Typography variant="body2">
                    {t('Status')}
                  </Typography>
                </td>
                <td style={{
                  width: '50%',
                  textAlign: 'left',
                  paddingLeft: '8px',
                  paddingTop: '4px',
                }}>
                  <DroneStatusChip
                    status={droneStatus?.metrics?.state+''}
                    loading={!droneStatus || !droneStatus.metrics}
                  />
                </td>
              </tr>
              <tr>
                <td style={{
                  width: '50%',
                  textAlign: 'right',
                  paddingRight: '8px',
                }}>
                  <Typography variant="body2">
                    {t('Live Stream')}
                  </Typography>
                </td>
                <td style={{
                  width: '50%',
                  textAlign: 'left',
                  paddingLeft: '8px',
                }}>
                  <Chip
                    style={{
                      backgroundColor: connected ? primary : '#e0e0e0',
                      color: connected ? 'white' : 'black',
                    }}
                    onClick={connected ? handleWatch : undefined}
                    onDelete={connected ? handleWatch : undefined}
                    deleteIcon={connected ? <RemoveRedEyeIcon style={{
                      color: 'white'
                    }} /> : undefined}
                    label={ connected ? t('Watch') : t('Disabled') }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CardContent>
        <CardActions style={{
          backgroundColor: connected ? agri : midGray,
          height: '60px',
          padding: 0,
        }}>
          { !droneStatus ? 
            <React.Fragment>
              <div style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <CircularProgress size={40} style={{
                  color: 'white',
                }} />
              </div>
            </React.Fragment>
            :
            connected ?
            <React.Fragment>
              { (droneStatus.metrics?.state+'' === 'LANDED' || droneStatus.metrics?.state+'' === 'HOVERING') && <StartAction droneUUID={props.drone.uuid} /> }
              { (droneStatus.metrics?.state+'' === 'FLYING' || droneStatus.metrics?.state+'' === 'FLIGHT_PLAN' || droneStatus.metrics?.state+'' === 'DOWNLOADING') && <StopAction droneUUID={props.drone.uuid} /> }
              { (droneStatus.metrics?.state+'' === 'FLYING' || droneStatus.metrics?.state+'' === 'FLIGHT_PLAN' || droneStatus.metrics?.state+'' === 'HOVERING') && <RTHAction droneUUID={props.drone.uuid} /> }
            </React.Fragment>
            :
            <React.Fragment>
              <Stack style={{
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'uppercase',
                color: 'white',
              }}>
                <Stack direction="row" spacing={1}>
                  <WifiOffOutlinedIcon fontSize="large" />
                  <Typography variant="h6">
                    {t('Offline')}
                  </Typography>
                </Stack>
              </Stack>
            </React.Fragment>
          }
        </CardActions>
      </Stack>
      <ConfirmDialog
        open={deleteDialogOpen}
        title={t("Delete Drone")}
        confirmColor="error"
        body={(
          <React.Fragment>
            {t('Are you sure you want to delete the drone')} <strong>{props.drone.name}</strong>?
          </React.Fragment>
        )}
        onClose={async (confirm) => {
          setDeleteDialogOpen(false);
          if (confirm && props.deleteDrone) {
            await props.deleteDrone({
              variables: {
                drone_id: props.drone.uuid,
              },
            });
          }
        }} />
      <Rename
        open={renameDialogOpen}
        title={t("Rename Drone")}
        text={(
          <React.Fragment>
            {t('Rename the drone')} <strong>{props.drone.name}</strong>
          </React.Fragment>
        )}
        onClose={async (name) => {
          setRenameDialogOpen(false);
          if (name && props.renameDroneMutation) {
            await props.renameDroneMutation({
              variables: {
                drone_id: props.drone.uuid,
                name,
              },
            });
          }
        }} />
      <LinkFlightplanDialog
        open={linkFlightplanDialogOpen}
        onClose={async (fp) => {
          setLinkFlightplanDialogOpen(false);
          if (fp && props.linkFlightPlanDroneMutation) {
            await props.linkFlightPlanDroneMutation({
              variables: {
                drone_id: props.drone.uuid,
                flightplan_id: fp.id,
              },
            });
          }
        }}
        drone={props.drone} />
      <DroneModelDialog
        open={updateDroneModelDialogOpen}
        onClose={async (model) => {
          setUpdateDroneModelDialogOpen(false);
          if (model && props.updateDroneModelMutation) {
            await props.updateDroneModelMutation({
              variables: {
                drone_id: props.drone.uuid,
                model_id: model,
              },
            });
          }
        }}
        drone={props.drone} />
    </Card>
  );
};

interface FleetPageProps {
  drones: Drone[];
  deleteDrone?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  renameDroneMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  linkFlightPlanDroneMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  updateDroneModelMutation?: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
}

const FleetPage = (props: FleetPageProps) => {
  return (
    <Grid container spacing={2} style={{
      height: 'calc(100% - 64px)',
      padding: '32px',
      paddingBottom: 0,
    }}>
      { props.drones.map((drone) => (
        <Grid item xs={12} md={6} lg={4} xl={3} key={drone.uuid} style={{height: '100%'}}>
          <DroneCard
            drone={drone}
            deleteDrone={props.deleteDrone}
            renameDroneMutation={props.renameDroneMutation}
            linkFlightPlanDroneMutation={props.linkFlightPlanDroneMutation}
            updateDroneModelMutation={props.updateDroneModelMutation}
            />
        </Grid>
      )) }
    </Grid>
  );
};

const Fleet = () => {
  const [ drones, setDrones ] = React.useState<Drone[]>([]);
  const [ itemsPerPage, setItemsPerPage ] = React.useState(1);
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState(1);
  const [ currentPage, setCurrentPage ] = React.useState<Drone[]>([]);

  const organizationCtx = React.useContext(OrganizationContext);

  const { medium, large, extraLarge } = useScreenSize();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(gql(`
    query Fleet {
      drones(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {created_at: desc}) {
        uuid
        name
        flight_plan {
          uuid
          name
        }
        drone_model {
          name
          image_url
        }
      }
    }
  `));

  const [ deleteDrone ] = useMutation(gql(`
    mutation DeleteDrone($drone_id: uuid!) {
      update_drones(
        where: {uuid: {_eq: $drone_id}},
        _set: {deleted_at: "now()"}
      ) {
        affected_rows
      }
    }
  `), {
    refetchQueries: ['Fleet', 'Flights', 'FlightPlans']
  });

  const [ renameDroneMutation ] = useMutation(gql(`
    mutation RenameDrone($drone_id: uuid!, $name: String!) {
      update_drones(
        where: {uuid: {_eq: $drone_id}},
        _set: {name: $name}
      ) {
        affected_rows
      }
    }
  `), {
    refetchQueries: ['Fleet', 'Flights', 'FlightPlans']
  });

  const [ linkFlightPlanDroneMutation ] = useMutation(gql(`
    mutation UpdateDrone($drone_id: uuid!, $flightplan_id: bigint) {
      update_drones(
        where: {uuid: {_eq: $drone_id}},
        _set: {flight_plan_id: $flightplan_id}
      ) {
        affected_rows
      }
    }
  `), {
    refetchQueries: ['Fleet', 'Flights', 'FlightPlans']
  });

  const [ updateDroneModelMutation ] = useMutation(gql(`
    mutation UpdateDroneModel($drone_id: uuid!, $model_id: bigint) {
      update_drones(
        where: {uuid: {_eq: $drone_id}},
        _set: {drone_model_id: $model_id}
      ) {
        affected_rows
      }
    }
  `), {
    refetchQueries: ['Fleet']
  });

  const newDrone = React.useCallback(() => {
    navigate('new');
  }, []);

  React.useEffect(() => {
    if (data) {
      setDrones(data.drones.map((drone: any) => {
        const d: Drone = {
          uuid: drone.uuid,
          name: drone.name,
          model: drone.drone_model ? {
            name: drone.drone_model.name,
            image_url: drone.drone_model.image_url,
          } : undefined
        };
        if (drone.flight_plan) {
          d.flightPlan = {
            uuid: drone.flight_plan.uuid,
            name: drone.flight_plan.name,
          };
        }
        return d;
      }));
    } else {
      setDrones([]);
    }
  }, [data]);

  React.useEffect(() => {
    if (extraLarge) {
      setItemsPerPage(4);
    } else if (large) {
      setItemsPerPage(3);
    } else if (medium) {
      setItemsPerPage(2);
    } else {
      setItemsPerPage(1);
    }
  }, [medium, large, extraLarge]);

  React.useEffect(() => {
    const start = (currentPageNumber - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setCurrentPage(drones.slice(start, end));
  }, [drones, itemsPerPage, currentPageNumber]);

  if (loading && !data) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      { currentPage.length > 0
      ? 
      <Stack style={{
        height: '100%',
      }}>
        <FleetPage
          drones={currentPage}
          deleteDrone={deleteDrone}
          renameDroneMutation={renameDroneMutation}
          linkFlightPlanDroneMutation={linkFlightPlanDroneMutation}
          updateDroneModelMutation={updateDroneModelMutation} />
        <FleetPagination
          onPageChange={(page) => setCurrentPageNumber(page)}
          currentPage={currentPageNumber}
          pages={Math.ceil(drones.length / itemsPerPage)} />
      </Stack>
      : 
      <React.Fragment>
        <Typography variant="h5" style={{
          textAlign: 'center',
          marginTop: '32px',
          textTransform: 'uppercase',
          color: darkGray,
        }}>
          {t('No drones found')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={newDrone}
          style={{
            margin: '32px auto',
            display: 'block',
          }}>
          {t('Add Drone')}
        </Button>
        <Typography variant="body2" style={{
          textAlign: 'center',
          color: darkGray,
        }}>
          {t('or')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => window.location.reload()}
          style={{
            display: 'block',
            margin: '16px auto',
          }}>
          {t('Reload')}
        </Button>
      </React.Fragment>
      }
      <Fab color="primary"
      onClick={newDrone}
      style={{
        position: 'fixed',
        bottom: '40px',
        right: '32px',
      }}>
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

export default Fleet;
