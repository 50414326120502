import { Autocomplete, Box, Dialog, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { and, whereNotDeleted, whereOrganization } from "../../common/utils/graphql";
import { OrganizationContext } from "../../common/contexts/organization";
import { useTranslation } from "react-i18next";
import FlightPlan from "../../classes/flightPlan";
import Drone from "../../classes/drone";

export interface LinkFlightplanDroneDialogProps {
  fp: FlightPlan;
  open: boolean;
  onClose: (drone?: Drone) => void;
}

const LinkFlightplanDroneDialog = (props: LinkFlightplanDroneDialogProps) => {
  const { open, onClose } = props;

  const [ inputValue, setInputValue ] = React.useState<string>('');
  const [ drones, setDrones ] = React.useState<Drone[]>([]);

  const { t } = useTranslation();

  const organizationCtx = React.useContext(OrganizationContext);

  const { loading, error, data } = useQuery(gql(`
    query DroneNames {
      drones(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {created_at: desc}) {
        id
        uuid
        name
      }
    }
  `));

  const onInputChange = React.useCallback((event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setInputValue((event.target as HTMLInputElement).value);
  }, []);

  const handleSuggestionClick = React.useCallback((drone: Drone) => {
    props.onClose(drone);
  }, []);

  React.useEffect(() => {
    if (data) {
      setDrones(data.drones);
    }
  }, [data]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle>{t('Link FlightPlan to Drone')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Link FlightPlan')} <strong>{props.fp.name}</strong> {t('to Drone')}
        </DialogContentText>
        <Autocomplete
          fullWidth
          options={drones}
          autoHighlight
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => {
            const { key, ...other } = props as any;

            return (
              <Box
                key={option.uuid}
                component="li"
                {...other}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSuggestionClick(option)
                }}
              >
                {option.name}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              required
              margin="dense"
              id="drone"
              name="drone"
              label={t("Drone")}
              type="text"
              fullWidth
              variant="standard"
              value={inputValue}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setInputValue(e.target.value);
              }}
              onInput={onInputChange}
            />
          )}
          />
      </DialogContent>
    </Dialog>
  );
};

export default LinkFlightplanDroneDialog;
