import React from "react";
import { agri, secondary } from "../../theme";
import { red } from "@mui/material/colors";
import useApi from "../../common/hooks/useApi";
import ConfirmDialog from "../../common/dialogs/ConfirmDialog";
import { useTranslation } from "react-i18next";

interface ActionButtonProps {
  backgroundColor: string;
  color: string;
  label: string;
  onClick?: () => void;
}

const ActionButton = (props: ActionButtonProps) => {
  const { t } = useTranslation();

  return (
    <div onClick={props.onClick}
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: props.backgroundColor,
      margin: 0,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: 20,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: props.color,
      cursor: props.onClick ? 'pointer' : 'default'
    }}>
      { t(props.label) }
    </div>
  );
};

export interface StartActionProps {
  droneUUID: string;
}

export const StartAction = (props: StartActionProps) => {
  const [ confirmDialogOpen, setConfirmDialogOpen ] = React.useState(false);

  const { post } = useApi();
  const { t } = useTranslation();

  const onConfirm = async() => {
    try {
      await post(`drones/${props.droneUUID}/flightplan/start`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <React.Fragment>
      <ConfirmDialog
        title={t("Start FlightPlan")}
        body={t("Are you sure you want to start the flight plan?")}
        open={confirmDialogOpen}
        onClose={(confirm) => {
          if (confirm) {
            onConfirm();
          }
          setConfirmDialogOpen(false);
        }}
      />
      <ActionButton
        backgroundColor={agri}
        color="white"
        label="Start"
        onClick={() => setConfirmDialogOpen(true)}
      />
    </React.Fragment>
  );
};

export interface StopActionProps {
  droneUUID: string;
}

export const StopAction = (props: StopActionProps) => {
  const { post } = useApi();

  return (
    <ActionButton
      backgroundColor={red['500']}
      color="white"
      label="Stop"
      onClick={async() => {
        try {
          await post(`drones/${props.droneUUID}/flightplan/stop`);
        } catch (e) {
          console.error(e);
        }
      }}
    />
  );
}

export interface RTHActionProps {
  droneUUID: string;
}

export const RTHAction = (props: RTHActionProps) => {
  const { post } = useApi();

  return (
    <ActionButton
      backgroundColor={secondary}
      color="white"
      label="RTH"
      onClick={async() => {
        try {
          const data = [
            {
              id: 'RETURN_HOME',
              returnHome: {},
            }
          ];
          await post(`controller/drone/${props.droneUUID}`, data);
        } catch (e) {
          console.error(e);
        }
      }}
    />
  );
}
