import { Collapse, List, ListItemButton, ListItemText, ListSubheader, Theme } from "@mui/material";
import React from "react";
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface MenuItem {
  title: string;
  url?: string;
  iframePath?: string;
  active?: boolean;
  default?: boolean;
}

export const itemStyle = {
  marginLeft: 8,
  paddingLeft: 10,
  borderRadius: 12,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderLeft: `1px solid lightgray`,
};

export const selectedStyle = (theme: Theme) => {
  return {
    ...itemStyle,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
  };
};

export interface MenuProps {
  title: string;
  theme: Theme;
  open: boolean;
  items?: MenuItem[];
  url?: string;
};

const Menu = (props: MenuProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <List
      sx={{ width: '100%', maxWidth: 360 }}
      style={{ cursor: 'pointer' }}
      component="div"
      disablePadding
      subheader={
        <ListSubheader component="div" style={{
          margin: 8,
          marginBottom: props.open ? 8 : 0,
          marginTop: props.open ? 8 : 0,
          borderRadius: 12,
          backgroundColor: props.open ? props.theme.palette.primary.main : 'inherit',
          color: props.open ? props.theme.palette.primary.contrastText : 'inherit',
          paddingRight: props.open ? 8 : 0,
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }} onClick={() => {
            if (props.url) {
              navigate(props.url);
            }
          }}>
            {t(props.title)}
            {!props.open && <ExpandMore />}
          </div>
        </ListSubheader>
      }
    >
      <Collapse in={props.open} timeout="auto" unmountOnExit>
        <List component="div" style={{
          paddingLeft: 16,
          paddingRight: 8,
        }} disablePadding dense>
          { props.items?.map((item, index) => {
            if (item.url) {
              return (
                <Link to={item.url} key={index}>
                  <ListItemButton style={item.active ? selectedStyle(props.theme) : itemStyle}>
                    <ListItemText primary={t(item.title)} />
                  </ListItemButton>
                </Link>
              );
            }
            return (
              <ListItemButton key={index} style={itemStyle} disabled>
                <ListItemText primary={t(item.title)} />
              </ListItemButton>
            );
          }) }
        </List>
      </Collapse>
    </List>
  );
};

export default Menu;
