import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useAuth, withAuthenticationRequired } from 'react-oidc-context';
import { OrganizationContext } from './common/contexts/organization';
import Layout from './layouts/Layout';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { PositionContext, defaultPositionManager } from './common/contexts/position';
import { graphqlURL } from './config';
import { initDB } from './common/db/db';
import i18n from "i18next";
import FrameLayout from './layouts/FrameLayout';
import theme, { themeAgri, themePhotogrammetry } from './theme';
import Frame from './components/Frame';
import Plans from './pages/Plans';
import Missions from './pages/Missions';
import Dashboard from './pages/Dashboard';
import Organizations from './pages/Organizations';
import NewAreaFlightPlan from './pages/flightplans/NewAreaFlightPlan';
import Fleet from './pages/Fleet';
import Redirect from './common/components/Redirect';
import NewDrone from './pages/fleet/NewDrone';
import Areas from './pages/Areas';
import NewArea from './pages/areas/NewArea';
import EditArea from './pages/areas/EditArea';
import Reports from './pages/Reports';
import ShowFlightPlan from './pages/flightplans/ShowFlightPlan';
import Download from './pages/Download';
import Learn from './pages/Learn';
import PDFReader from './pages/learn/PDFReader';
import Loading from './common/components/Loading';

function App() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyD68kpit7GVfUExBXN8EwiKmBH9pk-34lA';

  const [ selectedOrganizationID, setSelectedOrganizationID ] = React.useState<string | null>(localStorage.getItem('currentOrganizationId'));
  const organizationCtxValue = { organizationID: selectedOrganizationID, setOrganizationID: setSelectedOrganizationID };

  const [ client, setClient ] = React.useState<ApolloClient<any> | null>(null);

  document.documentElement.lang = i18n.resolvedLanguage ?? 'en';

  initDB('hub');

  const auth = useAuth();

  const positionManager = defaultPositionManager;

  React.useEffect(() => {
    if (!auth.user || auth.user.expired) {
      return;
    }

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = auth.user?.access_token;
      // return the headers to the context so httpLink can read them
      const h = {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        }
      }
      if (selectedOrganizationID) {
        h.headers['current-organization'] = selectedOrganizationID;
      }
      return h;
    });
  
    const httpLink = createHttpLink({
      uri: graphqlURL,
    });

    if (!client) {
      setClient(new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      }));
    } else {
      client.setLink(authLink.concat(httpLink));
    }
  }, [auth.user?.access_token]);

  // import active drone and organization from url
  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const url = new URL(window.location.href);
    const organizationID = url.searchParams.get('organization');

    if (organizationID) {
      setSelectedOrganizationID(organizationID);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [auth.user]);

  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const currentOrganizationId = localStorage.getItem('currentOrganizationId');

    if (selectedOrganizationID) {
      localStorage.setItem('currentOrganizationId', selectedOrganizationID);
    } else {
      localStorage.removeItem('currentOrganizationId');
    }

    if (currentOrganizationId !== selectedOrganizationID) {
      window.location.reload();
    }

    if (auth.user.expired) {
      return;
    }
  }, [selectedOrganizationID, auth.user]);

  if (auth.isLoading || !client) {
    return (
      <Loading open />
    );
  }

  if (auth.error) {
    window.location.href = '/';
    return <div>Oops... {auth.error.message}</div>;
  }

  return (
    <ApolloProvider client={client}>
      <APIProvider apiKey={apiKey}>
        <PositionContext.Provider value={positionManager}>
          <OrganizationContext.Provider value={organizationCtxValue}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Dashboard />} />
                  <Route path="pilot" element={<FrameLayout theme={theme} fullHeight />}>
                    <Route index element={<Redirect to="/pilot/fleet" />} />
                    <Route path="fleet" element={null}>
                      <Route index element={<Fleet />} />
                      <Route path="new" element={null}>
                        <Route index element={<NewDrone />} />
                      </Route>
                    </Route>
                    <Route path="download" element={<Download />} />
                    <Route path="learn" element={null}>
                      <Route index element={<Learn />} />
                      <Route path=":learnableUUID" element={<PDFReader />} />
                    </Route>
                  </Route>
                  <Route path="fly" element={<FrameLayout theme={theme} fullHeight />}>
                    <Route index element={<Redirect to="/fly/plans" />} />
                    <Route path="areas" element={null}>
                      <Route index element={<Areas />} />
                      <Route path="new" element={null}>
                        <Route index element={<NewArea />} />
                        <Route path=":fieldUUID" element={<NewArea />} />
                      </Route>
                      <Route path=":fieldUUID" element={null}>
                        <Route index element={<EditArea />} />
                      </Route>
                    </Route>
                    <Route path="plans" element={null}>
                      <Route index element={<Plans />} />
                      <Route path=':flightPlanUUID' element={null}>
                        <Route index element={<ShowFlightPlan />} />
                      </Route>
                      <Route path="new" element={null}>
                        <Route path="area" element={<NewAreaFlightPlan />}>
                          <Route path=":fieldUUID" element={<NewAreaFlightPlan />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="reports" element={null}>
                      <Route index element={<Reports />} />
                    </Route>
                    <Route path="organizations" element={<Organizations />} />
                  </Route>
                  <Route path="fly/missions" element={<Missions />} />
                  <Route path="agriculture" element={<FrameLayout theme={themeAgri} fullHeight />}>
                    <Route index element={<Frame url='https://agriculture.dromt.it/app' />} />
                    <Route path=":path" element={<Frame url='https://agriculture.dromt.it/app' />} />
                  </Route>
                  <Route path="photogrammetry" element={<FrameLayout theme={themePhotogrammetry} fullHeight />}>
                    <Route index element={<Frame url='https://photogrammetry.dromt.it/app' />} />
                    <Route path=":path" element={<Frame url='https://photogrammetry.dromt.it/app' />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </OrganizationContext.Provider>
        </PositionContext.Provider>
      </APIProvider>
    </ApolloProvider>
  );
}

export default withAuthenticationRequired(App);
