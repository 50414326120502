import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../common/components/Loading";
import { RawFlightPlan } from "../../utils/flightplan";
import MapFull from "../../common/components/MapFull";
import Polyline from "../../common/components/Polyline";
import MapPoint from "../../common/components/MapPoint";
import UserPosition from "../../common/components/UserPosition";
import { computeZoom } from "../../common/utils/position";

class FlightPlan {
  uuid: string;
  name: string;
  rawFlightPlan: RawFlightPlan;

  constructor(uuid: string, name: string, data: any) {
    this.uuid = uuid;
    this.name = name;
    this.rawFlightPlan = RawFlightPlan.fromData(data);
  }
}

const ShowFlightPlan = () => {
  const [flightPlan, setFlightPlan] = React.useState<FlightPlan>();
  const [path, setPath] = React.useState<google.maps.LatLngLiteral[]>([]);
  const [defaultCenter, setDefaultCenter] = React.useState<google.maps.LatLngLiteral>();
  const [defaultZoom, setDefaultZoom] = React.useState<number>();

  const { flightPlanUUID } = useParams()

  const { loading, error, data } = useQuery(gql(`
    query FlightPlan($uuid: uuid!) {
      flight_plans(where: {uuid: {_eq: $uuid}}) {
        uuid
        name
        data
      }
    }
  `), {
    variables: {
      uuid: flightPlanUUID,
    }
  });

  React.useEffect(() => {
    if (data) {
      setFlightPlan(new FlightPlan(data.flight_plans[0].uuid, data.flight_plans[0].name, data.flight_plans[0].data));
    }
  }, [data]);

  React.useEffect(() => {
    if (flightPlan) {
      setPath(flightPlan.rawFlightPlan.path());
    }
  }, [flightPlan]);

  React.useEffect(() => {
    if (!path || path.length === 0) return;

    const b = new google.maps.LatLngBounds();
    path.forEach((latLng) => {
      b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
    });
    setDefaultCenter(b.getCenter().toJSON());
    setDefaultZoom(computeZoom(b, window.innerWidth, window.innerHeight));
  }, [path]);

  if (loading && !data) {
    return <Loading open />;
  }

  if (!path || !defaultCenter || !defaultZoom) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      <MapFull
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}>
        <Polyline path={path} />
        { path.map((point, index) => {
          return <MapPoint
            key={index}
            position={point} />;
        }) }
        <UserPosition />
      </MapFull>
    </React.Fragment>
  );
};

export default ShowFlightPlan;
