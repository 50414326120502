import { MouseEvent, ReactNode } from "react";
import { Row } from "../components/EnhancedTable";
import { Chip, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TableCell, TableRow } from "@mui/material";
import { dateFormat } from "../common/utils/text";
import { NavigateFunction } from "react-router-dom";
import React from "react";
import { gql, useMutation } from "@apollo/client";
import ConfirmDialog from "../common/dialogs/ConfirmDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AnalyzeDialog, { AnalysisType } from "../components/dialogs/AnalyzeDialog";
import useUser from "../common/hooks/useUser";
import FlightPlan from "./flightPlan";
import { useTranslation } from "react-i18next";

interface Drone {
  uuid: string;
  name: string;
}

interface Completion {
  started_at: string;
  ended_at: string;
  completed: boolean;
  current_command_index: number;
}

interface Media {
  count: number;
}

export default class Flight implements Row {
  id: number;
  uuid: string;
  private created_at: string;
  private flight_plan?: FlightPlan;
  private completion?: Completion;
  private media?: Media;
  private drone?: Drone;

  constructor(
    id: number,
    uuid: string,
    created_at: string,
    flight_plan?: FlightPlan,
    completion?: Completion,
    media?: Media,
    drone?: Drone,
  ) {
    this.id = id;
    this.uuid = uuid;
    this.created_at = created_at;
    this.flight_plan = flight_plan;
    this.completion = completion;
    this.media = media;
    this.drone = drone;
  }

  navigate?: NavigateFunction;

  get date(): string {
    if (this.completion?.started_at) {
      return this.completion.started_at;
    }
    return this.created_at;
  }

  get field_id(): number | undefined {
    return this.flight_plan?.field_id;
  }

  get flight_plan_name(): string | undefined {
    return this.flight_plan?.name;
  }

  get drone_name(): string | undefined {
    return this.drone?.name;
  }

  get duration(): string | undefined {
    if (this.completion?.started_at && this.completion?.ended_at) {
      const start = new Date(this.completion.started_at);
      const end = new Date(this.completion.ended_at);
      const diff = end.getTime() - start.getTime();
      const minutes = Math.floor(diff / 60000);
      const seconds = ((diff % 60000) / 1000).toFixed(0);
      return minutes + 'm ' + seconds + 's';
    }
    return undefined;
  }

  get state(): string {
    if (this.completion?.completed) {
      return 'Completed';
    }
    return 'In Progress';
  }

  get media_number(): number {
    return this.media?.count || 0;
  }

  render = (
    labelID: string,
    isItemSelected: boolean,
    handleClick: (event: MouseEvent<unknown>, id: string) => void,
  ): ReactNode => {
    return (
      <TableRow
        hover
        onClick={(event) => handleClick(event, this.uuid)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={this.uuid}
        selected={isItemSelected}
        sx={{ cursor: 'pointer' }}
      >
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelID,
            }}
          />
        </TableCell> */}
        {/* <TableCell
          component="th"
          id={labelID}
          scope="row"
        >
          {this.uuid}
        </TableCell> */}
        <TableCell>{dateFormat(this.date, true)}</TableCell>
        <TableCell>
          { this.flight_plan && <Chip
            label={this.flight_plan_name}
            onClick={(event) => {
              event.stopPropagation();
              if (this.navigate) {
                this.navigate('../plans/' + this.flight_plan?.uuid);
              }
            }}
          /> }
        </TableCell>
        <TableCell>
          { this.drone && <Chip
            label={this.drone_name}
            onClick={(event) => {
              event.stopPropagation();
              if (this.navigate) {
                // TODO: navigate to drone
                this.navigate('../fleet');
              }
            }}
          /> }
        </TableCell>
        <TableCell>{this.duration}</TableCell>
        <TableCell>{this.state}</TableCell>
        <TableCell>{this.media_number}</TableCell>
        <TableCell>
          <FlightRowMenu flight={this} />
        </TableCell>
      </TableRow>
    );
  }
}

interface FlightRowMenuProps {
  flight: Flight;
}

const FlightRowMenu = (props: FlightRowMenuProps) => {
  const [ confirmDialogOpen, setConfirmDialogOpen ] = React.useState(false);
  const [ analyzeDialogOpen, setAnalyzeDialogOpen ] = React.useState(false);

  const { organizationID } = useUser();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const [ insertAnalysisRequest ] = useMutation(gql(`
    mutation InsertAnalysisRequest(
      $organization_id: bigint,
      $field_id: bigint!,
      $flight_id: bigint!,
      $agriculture: Boolean!,
      $dem: Boolean!,
      $model3_d: Boolean!,
      $multispectral: Boolean!,
      $vineyard: Boolean!,
    ) {
      insert_analysis_requests_one(object: {
        organization_id: $organization_id,
        field_id: $field_id,
        flight_id: $flight_id,
        agriculture: $agriculture,
        dem: $dem,
        model3_d: $model3_d,
        multispectral: $multispectral,
        vineyard: $vineyard,
      }) {
        id
      }
    }
  `), {
    refetchQueries: ['Flights'],
  });

  const [ deleteFlight ] = useMutation(gql(`
    mutation DeleteFlight($uuid: uuid!) {
      update_flights(where: {uuid: {_eq: $uuid}}, _set: {deleted_at: "now()"}) {
        returning {
          uuid
        }
      }
    }
  `), {
    refetchQueries: ['Flights'],
  });

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="button"
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem onClick={
          (e) => {
            setAnalyzeDialogOpen(true);
            handleClose(e);
          }}>
          <ListItemIcon>
            <QueryStatsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Analyze')}</ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem onClick={(e) => {
          setConfirmDialogOpen(true);
          handleClose(e);
        }}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Delete')}</ListItemText>
        </MenuItem>
      </Menu>

      <ConfirmDialog
        open={confirmDialogOpen}
        confirmColor="error"
        title={t("Delete Flight")}
        body={
          <React.Fragment>
            {t('Are you sure you want to delete the flight?')}
          </React.Fragment>
        }
        onClose={async (confirm) => {
          if (confirm) {
            await deleteFlight({ variables: { uuid: props.flight.uuid } });
          }
          setConfirmDialogOpen(false);
        }}
      />

      <AnalyzeDialog
        flight={props.flight}
        open={analyzeDialogOpen}
        onClose={async (analysisType?: AnalysisType) => {
          if (analysisType) {
            if (!props.flight.field_id) {
              console.error('FlightPlan does not have a linked field');
              alert(t('FlightPlan does not have a linked field'));
              return;
            }

            let options = {
              organization_id: organizationID,
              field_id: props.flight.field_id,
              flight_id: props.flight.id,
              agriculture: false,
              dem: false,
              model3_d: false,
              multispectral: false,
              vineyard: false,
            };

            switch (analysisType.value) {
              case 'extensive':
                options = { ...options, agriculture: true, dem: false, model3_d: false, multispectral: true, vineyard: false };
                break;
              case 'vineyard':
                options = { ...options, agriculture: true, dem: true, model3_d: true, multispectral: true, vineyard: true };
                break;
              case 'photogrammetry':
                options = { ...options, agriculture: false, dem: true, model3_d: true, multispectral: false, vineyard: false };
                break;
              default:
                console.error('Unknown analysis type');
                alert(t('Unknown analysis type'));
                return;
            }

            await insertAnalysisRequest({
              variables: options,
            });
          }
          setAnalyzeDialogOpen(false);
        }}
      />
    </React.Fragment>
  );
};
