import React from "react";

const useIframe = () => {
  const [ location, setLocation ] = React.useState<string | undefined>();

  React.useEffect(() => {
    window.onmessage = (event) => {
      if (event.data.type === "location") {
        setLocation(event.data.value);
      }
    };
  }, []);

  return { location };
};

export default useIframe;
