import { gql, useQuery } from "@apollo/client";
import React from "react";
import { whereNotDeleted } from "../../common/utils/graphql";
import Loading from "../../common/components/Loading";
import EnhancedTable from "../EnhancedTable";
import Member from "../../classes/member";

const headCells = [
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "role", numeric: false, disablePadding: false, label: "Role", unsortable: true },
];

export interface MembersProps {
  organizationID: string;
};

const Members = (props: MembersProps) => {
  const [ members, setMembers ] = React.useState<Member[]>([]);

  const { loading, error, data } = useQuery(gql(`
    query Members {
      organizations(where: {uuid: {_eq: "`+props.organizationID+`"}}) {
        name
        user {
          username
          email
        }
        members(where: `+whereNotDeleted()+`) {
          user {
            username
            email
          }
        }
      }
    }
  `));

  const addOwner = (members: Member[], email: string): Member[] => {
    const isPresent = members.find((member) => member.email === email);
    if (isPresent) {
      isPresent.isOwner = true;
      return members;
    }
    return [...members, new Member(email, true)];
  };

  React.useEffect(() => {
    if (data) {
      let m = data.organizations[0].members.map((member: any) => new Member(member.user?.email ?? ''));
      m = addOwner(m, data.organizations[0].user.email);
      setMembers(m);
    } else {
      setMembers([]);
    }
  }, [data]);

  if (loading && !data) {
    return <Loading open />;
  }

  return (
    <EnhancedTable
      title="Members"
      headCells={headCells}
      rows={members} />
  );
};

export default Members;
