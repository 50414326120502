import { Marker } from "@vis.gl/react-google-maps";
import React from "react";

export interface MapPointProps {
  position: google.maps.LatLngLiteral;
  color?: string;
  scale?: number;
  borderColor?: string;
  selected?: boolean;
  onDrag?: (e: google.maps.MapMouseEvent | void) => void
};

const MapPoint = (props: MapPointProps) => {
  const icon: google.maps.Icon | google.maps.Symbol = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: props.color ?? '#1771F1',
    fillOpacity: 1,
    scale: props.scale ?? 7,
    rotation: 0,
    strokeColor: 'white',
    strokeWeight: props.selected ? 1 : 0,
  };

  if (props.borderColor) {
    icon.strokeColor = props.borderColor;
    icon.strokeWeight = 2;
  }

  return (
    <Marker
      onDrag={props.onDrag}
      draggable={!!props.onDrag}
      position={props.position}
      icon={icon} />
  );
};

export default MapPoint;
