import { Grid, Stack } from "@mui/material";
import React from "react";
import Frame, { appendCommonArgs } from "../components/Frame";
import { OrganizationContext } from "../common/contexts/organization";

const Missions = () => {
  const organizationCtx = React.useContext(OrganizationContext);

  return (
    <Grid container spacing={2} style={{
      height: 'calc(100vh - 120px)',
    }}>
      <Grid container item xs={4}
      spacing={2}
      style={{
        height: '100%',
      }}>
        <Grid item xs={12}>
          <Frame
            url={appendCommonArgs("https://controller.dromt.it", organizationCtx)}
            dense
            style={{
              borderRadius: 15,
              overflow: 'hidden',
            }}
            />
        </Grid>
        <Grid item xs={12}>
          <Frame
            url={appendCommonArgs("https://controller.dromt.it/drone", organizationCtx)}
            dense
            style={{
              borderRadius: 15,
              overflow: 'hidden',
            }}
            />
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Frame
          url={appendCommonArgs("https://controller.dromt.it/drone", organizationCtx)}
          dense
          pinned
          style={{
            borderRadius: 15,
            overflow: 'hidden',
          }}
          />
      </Grid>
    </Grid>
  );
};

export default Missions;
