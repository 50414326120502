import { Fab, Stack } from "@mui/material";
import React from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useParams } from "react-router-dom";
import { OrganizationContext, OrganizationContextManager } from "../common/contexts/organization";

export const appendCommonArgs = (url: string, organizationCtx: OrganizationContextManager | null) => {
  const separator = url.includes('?') ? '&' : '?';

  url += separator + "embed=true";
  if (organizationCtx?.organizationID) {
    url += "&organization=" + organizationCtx?.organizationID;
  }
  return url;
};

export interface FrameProps {
  url?: string;
  dense?: boolean;
  pinned?: boolean;
  style?: React.CSSProperties;
  hideButtons?: boolean;
}

const Frame = (props: FrameProps) => {
  const { path } = useParams();

  const organizationCtx = React.useContext(OrganizationContext);

  let fullURL = props.url ?? 'https://www.dromt.it';
  if (path) {
    fullURL += '/'+path;
  }

  fullURL = appendCommonArgs(fullURL, organizationCtx);

  return <React.Fragment>
    <div style={{
      position: 'relative',
      height: '100%',
      width: '100%',
      ...props.style,
    }}>
      { !props.hideButtons && <React.Fragment>
        <Stack direction="row" spacing={1} style={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}>
          {/* { props.pinned ?
          <Fab size="small">
            <PushPinIcon />
          </Fab>
          :
          <Fab size="small">
            <PushPinOutlinedIcon />
          </Fab>
          } */}
          <Fab size="small" onClick={() => {
            window.open(fullURL, '_blank');
          }}>
            <OpenInNewIcon />
          </Fab>
        </Stack>
      </React.Fragment> }
      <iframe
        src={ fullURL }
        style={{
          width: '100%',
          height: 'calc(100%)',
          border: 'none',
        }}
        loading="lazy"
        allow="geolocation; microphone; camera; clipboard-write"
      />
    </div>
  </React.Fragment>;
};

export default Frame;
