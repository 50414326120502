export interface Document {
  name: string;
  path: string;
};

export interface Learnable {
  uuid: string;
  name: string;
  updated_at: string;

  documents?: Document[];
};

export const learnables = [
  { uuid: '1', name: 'OPEN A1-A3', updated_at: '2021-01-01', documents: [
    { name: 'Theory Manual', path: '/pdf/Corso_online_pilota_UAS_25022021_web.pdf' },
    { name: 'Exercises', path: '/pdf/Corso_online_pilota_UAS_25022021_web.pdf' },
    { name: 'Bot', path: '/pdf/Corso_online_pilota_UAS_25022021_web.pdf' },
  ]},
  { uuid: '2', name: 'OPEN A2', updated_at: '2021-01-02', documents: [
    { name: 'Theory Manual', path: '/pdf/Corso_online_pilota_UAS_25022021_web.pdf' },
    { name: 'Exercises', path: '/pdf/Corso_online_pilota_UAS_25022021_web.pdf' },
    { name: 'Bot', path: '/pdf/Corso_online_pilota_UAS_25022021_web.pdf' },
  ]},
];
