import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import GroupIcon from '@mui/icons-material/Group';
import { gql, useQuery } from "@apollo/client";
import { whereNotDeleted } from "../common/utils/graphql";
import Organization from "../classes/organization";
import { OrganizationContext } from "../common/contexts/organization";

const OrganizationMenu = () => {
  const [ menuOpen, setMenuOpen ] = React.useState(false);
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
  const [ organizations, setOrganizations ] = React.useState<Organization[]>([]);

  const organizationCtx = React.useContext(OrganizationContext);

  const { loading, error, data } = useQuery(gql(`
    query Organizations {
      organizations(where: `+whereNotDeleted()+`, order_by: {name: asc}) {
        uuid
        name
      }
    }
  `));

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const setOrganization = (uuid?: string) => {
    if (!uuid) {
      organizationCtx?.setOrganizationID(null);
      return;
    }
    organizationCtx?.setOrganizationID(uuid);
  };

  React.useEffect(() => {
    if (data) {
      setOrganizations(data.organizations.map((org: any) => new Organization(org.uuid, org.name)));
    }
  }, [data]);

  return (
    <React.Fragment>
      <IconButton
        size="large"
        aria-controls={menuOpen ? 'menu-org' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenu}
        color="inherit"
      >
        <GroupIcon />
      </IconButton>
      <Menu
        id="menu-org"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: '60vh',
            }
          }
        }}
      >
        <MenuItem
          value={"None"}
          onClick={() => setOrganization(undefined)}
          selected={!organizationCtx?.organizationID}
        >
          <em>None</em>
        </MenuItem>
        {organizations.map((org) => {
          return (
            <MenuItem
              key={org.uuid}
              onClick={() => setOrganization(org.uuid)}
              selected={org.uuid === organizationCtx?.organizationID}
            >
              {org.name}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

export default OrganizationMenu;
