import React from "react";
import Field from "../classes/field";
import { OrganizationContext } from "../common/contexts/organization";
import { gql, useQuery } from "@apollo/client";
import { and, whereNotDeleted, whereOrganization } from "../common/utils/graphql";
import Loading from "../common/components/Loading";
import EnhancedTable from "../components/EnhancedTable";
import { Button, Fab, Typography } from "@mui/material";
import { darkGray } from "../theme";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";

const headCells = [
  { id: 'uuid', numeric: false, disablePadding: false, label: 'UUID' }, 
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Created At' },
  { id: 'updated_at', numeric: false, disablePadding: false, label: 'Updated At' },
  { id: 'menu', numeric: false, disablePadding: false, label: '', unsortable: true },
];

const Areas = () => {
  const [fields, setFields] = React.useState<Field[]>([]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const organizationCtx = React.useContext(OrganizationContext);

  const { loading, error, data } = useQuery(gql(`
    query Fields {
      fields(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {name: asc}) {
        id
        uuid
        name
        created_at
        updated_at
      }
    }
  `));

  React.useEffect(() => {
    if (data) {
      setFields(data.fields.map((f: any) => {
        const field = new Field(f.id, f.uuid, f.name, f.created_at, f.updated_at);
        field.navigate = navigate;
        return field;
      }));
    }
  }, [data]);

  if (loading && !data) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      { fields.length > 0
      ?
      <EnhancedTable
        title="Areas"
        headCells={headCells}
        rows={fields} />
      :
      <React.Fragment>
        <Typography variant="h5" style={{
          textAlign: 'center',
          marginTop: '32px',
          textTransform: 'uppercase',
          color: darkGray,
        }}>
          {t('No area found')}
        </Typography>
        <Link to={"new"}>
          <Button
            variant="contained"
            color="primary"
            style={{
              margin: '32px auto',
              display: 'block',
            }}>
            {t('Add Area')}
          </Button>
        </Link>
        <Typography variant="body2" style={{
          textAlign: 'center',
          color: darkGray,
        }}>
          {t('or')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => window.location.reload()}
          style={{
            display: 'block',
            margin: '16px auto',
          }}>
          {t('Reload')}
        </Button>
      </React.Fragment>
      }

      <Link to={"new"}>
        <Fab
          style={{
            position: 'fixed',
            right: 16,
            bottom: 16,
          }}
          color="primary"
        >
          <AddIcon />
        </Fab>
      </Link>
    </React.Fragment>
  );
};

export default Areas;
