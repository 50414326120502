import React from "react";
import MapFull from "../../common/components/MapFull";
import { PositionContext } from "../../common/contexts/position";
import Loading from "../../common/components/Loading";
import { computeZoom, geolocationToLatLng } from "../../common/utils/position";
import UserPosition from "../../common/components/UserPosition";
import { Card, CardContent, Fab, Stack, Tooltip, Typography } from "@mui/material";
import InfoWindow from "../../common/components/InfoWindow";
import Polyline from "../../common/components/Polyline";
import { Polygon } from "../../classes/geometry";
import { numberFormat } from "../../common/utils/text";
import MapPoint from "../../common/components/MapPoint";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import SearchLocationDialog from "../dialogs/SearchLocationDialog";
import { useTranslation } from "react-i18next";

interface AreaWindowProps {
  area?: number;
}

const AreaWindow = (props: AreaWindowProps) => {
  const { t } = useTranslation();

  return (
    <div style={{
      position: 'absolute',
      top: 16,
      left: 16,
      width: 150,
      zIndex: 1000,
    }}>
      <Card variant="outlined">
        <CardContent>
          <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
            {t('Total Area')}
          </Typography>
          <Typography sx={{ fontSize: 18 }} color="text.secondary">
            { props.area ? numberFormat(props.area, 2) + ' ha' : 'N/A' }
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

interface ButtonsProps {
  onSearchLocation?: () => void;
  onDeleteAll?: () => void;
  onDeleteLast?: () => void;
  onConfirm?: () => void;
}

const Buttons = (props: ButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div style={{
      position: 'absolute',
      bottom: 22,
      right: 16,
      zIndex: 1000,
    }}>
      <Stack direction="row" spacing={1}>
        <Tooltip title={t("Search Location")} placement="top">
          <span><Fab size="small" onClick={() => {
            if (props.onSearchLocation) {
              props.onSearchLocation();
            }
          }}
          disabled={props.onSearchLocation === undefined}>
            <SearchIcon />
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Delete Last")} placement="top">
          <span><Fab size="small" onClick={() => {
            if (props.onDeleteLast) {
              props.onDeleteLast();
            }
          }}
          disabled={props.onDeleteLast === undefined}>
            <ClearIcon />
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Delete All")} placement="top">
          <span><Fab size="small" onClick={() => {
            if (props.onDeleteAll) {
              props.onDeleteAll();
            }
          }}
          disabled={props.onDeleteAll === undefined}>
            <DeleteIcon />
          </Fab></span>
        </Tooltip>
        <Tooltip title={t("Confirm")} placement="top">
          <span><Fab color="primary" variant="extended" size="medium" onClick={() => {
            if (props.onConfirm) {
              props.onConfirm();
            }
          }}
          disabled={props.onConfirm === undefined}>
            <CheckIcon />
            {t('Confirm')}
          </Fab></span>
        </Tooltip>
      </Stack>
    </div>
  );
};

const infoWindowText = 'Draw the perimeter of the area to map by clicking the corners on the map';

export interface DrawAreaProps {
  path?: google.maps.LatLngLiteral[];
  centerPath?: boolean;
  onPathChange?: (path: google.maps.LatLngLiteral[]) => void;
}

const DrawArea = (props: DrawAreaProps) => {
  const [ locationDialogOpen, setLocationDialogOpen ] = React.useState<boolean>(false);

  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>();
  const [ center, setCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ path, setPath ] = React.useState<google.maps.LatLngLiteral[]>(props.path ?? []);
  const [ area, setArea ] = React.useState<number | undefined>();

  const { t } = useTranslation();

  const positionCtx = React.useContext(PositionContext);

  const changePointLocation = (index: number, latLng: google.maps.LatLngLiteral) => {
    const newPath = [...path];
    newPath[index] = latLng;
    setPath(newPath);
  }

  React.useEffect(() => {
    if (props.centerPath) return;

    const posSub = positionCtx?.watchPosition().subscribe((position: GeolocationPosition | null) => {
      if (!defaultCenter && position) {
        setDefaultCenter(geolocationToLatLng(position));
        setDefaultZoom(17);
        if (!center) {
          setCenter(geolocationToLatLng(position));
        }
      }
    });
    return () => {
      posSub?.unsubscribe();
    }
  }, [positionCtx, props.centerPath]);

  React.useEffect(() => {
    if (!props.centerPath) return;

    const b = new google.maps.LatLngBounds();
    path.forEach((latLng) => {
      b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
    });
    setDefaultCenter(b.getCenter().toJSON());
    setDefaultZoom(computeZoom(b, window.innerWidth, window.innerHeight));
  }, [path, props.centerPath]);

  React.useEffect(() => {
    if (path.length < 3) {
      setArea(undefined);
      return;
    }

    if (!google.maps.geometry) {
      return
    }

    const polygon = new Polygon(...path);
    try {
      setArea(polygon.areaMeters() / 10000);
    } catch (e) {
      setArea(undefined);
    }
  }, [path, google.maps.geometry]);

  if (!defaultCenter) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      <MapFull
        center={center}
        onCameraChanged={(e) => {
          setCenter(e.detail.center);
        }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onClick={(e) => {
          if (!e.detail.latLng) {
            return;
          }
          setPath([...path, e.detail.latLng]);
        }}>
        <Polyline path={path} closed />
        { path.map((point, index) => {
          return <MapPoint
            key={index}
            position={point}
            onDrag={(e) => {
              if (e && e.latLng) {
                changePointLocation(index, e.latLng.toJSON());
              }
            }} />;
        }) }
        <UserPosition />
        { path.length >= 3 && <AreaWindow area={area} /> }
        <InfoWindow
          text={t(infoWindowText)}
          width={300}
          open />
        <Buttons
          onSearchLocation={() => {
            setLocationDialogOpen(true);
          }}
          onDeleteAll={() => {
            setPath([]);
          }}
          onDeleteLast={() => {
            setPath(path.slice(0, path.length - 1));
          }}
          onConfirm={path.length >= 3 && props.onPathChange ? () => {
            props.onPathChange!(path);
          } : undefined}
          />
      </MapFull>
      <SearchLocationDialog
        open={locationDialogOpen}
        onClose={(location) => {
          setLocationDialogOpen(false);
          if (location) {
            setCenter(location);
          }
        }} />
    </React.Fragment>
  );
};

export default DrawArea;
