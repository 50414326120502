export interface Downloadable {
  uuid: string;
  name: string;
  created_at: string;
  version: string;
};

export class Application {
  downloadables: Downloadable[];

  constructor(downloadables: Downloadable[]) {
    this.downloadables = sortByLastCreated(downloadables);
  }

  get uuid(): string {
    return this.downloadables[0].uuid;
  }

  get name(): string {
    return this.downloadables[0].name;
  }

  get version(): string {
    return this.downloadables[0].version;
  }

  get created_at(): string {
    return this.downloadables[0].created_at;
  }
};

export const groupByName = (downloadables: Downloadable[]): Application[] => {
  const groups: { [key: string]: { downloadables: Downloadable[] } } = {};
  downloadables.forEach((downloadable) => {
    const name = downloadable.name;
    if (!groups[name]) {
      groups[name] = {
        downloadables: [],
      };
    }
    groups[name].downloadables.push(downloadable);
  });
  return Object.values(groups).map((group) => {
    return new Application(group.downloadables);
  });
};

export const sortByLastCreated = (downloadables: Downloadable[]): Downloadable[] => {
  return downloadables.sort((a, b) => {
    return a.created_at < b.created_at ? 1 : -1;
  });
};

export const sortApplicationsByLastCreated = (applications: Application[]): Application[] => {
  return applications.sort((a, b) => {
    return a.downloadables[0].created_at < b.downloadables[0].created_at ? 1 : -1;
  });
}
