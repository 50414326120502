import React from "react";
import { useLocation } from "react-router-dom";
import theme from "../theme";
import Menu, { MenuItem } from "./Menu";

const items: MenuItem[] = [
  //{ title: 'Missions', url: '/fly/missions' },
  //{ title: 'Areas', url: '/fly/areas' },
  { title: 'Missions', url: '/fly/plans' },
  { title: 'Reports', url: '/fly/reports' },
  { title: 'Organizations', url: '/fly/organizations' },
];

export interface FlyMenuProps {
  open?: boolean;
  url?: string;
};

const FlyMenu = (props: FlyMenuProps) => {
  const location = useLocation();

  React.useEffect(() => {
    items.forEach(item => {
      item.active = !!item.url && location.pathname.startsWith(item.url);
    });
  }, [location]);

  return (
    <Menu
      theme={theme}
      open={props.open ?? false}
      title="DromtFLY"
      items={items}
      url={props.url} />
  );
};

export default FlyMenu;
